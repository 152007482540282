import React, { Component } from 'react';
import PageForm from '../PageForm';
import '../pageform.css';

export class CreatePage extends Component {
    static displayName = CreatePage.name;

    render() {
        return (
            <PageForm pageId='0' formType='Create' />
        );
    }
}
