import React, { Component } from 'react';
//import './homeCTA.css';
//import { Slide } from 'react-slideshow-image';
//import 'react-slideshow-image/dist/styles.css';
import Slideshow from './Slideshow';

export class BannerContainer extends Component {
    static displayName = BannerContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            bannerContainer: null,
            banners: [],
            isInCard: this.props.isInCard != undefined ? this.props.isInCard : false,
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let sliderDuration = this.state.bannerContainer != null && this.state.bannerContainer.sliderDuration != null && this.state.bannerContainer.sliderDuration != undefined ? this.state.bannerContainer.sliderDuration : 5000;

        let banners = [];
        let bannerImages = [];
        let bannerInsides = [];
        let slideTimes = [];
        for (var i = 0; i < this.state.banners.length; i++) {
            let banner = this.state.banners[i];

            let actionText = banner.actionText != null && banner.actionText != "" ? <div className="action-area">{banner.actionText}</div> : null;
            let subActionText = banner.subActionText != null && banner.subActionText != "" ? <div className="sub-action-text">{banner.subActionText}</div> : null;

            let buttonWrapper = null;
            if (banner.buttonText != null && banner.buttonText != "" && banner.buttonLink != null && banner.buttonLink != "") {
                let button = <a href={banner.buttonLink}>
                    <button>
                        {banner.buttonText}
                    </button>
                </a>;

                let buttonClasses = "button-area ";
                if (this.state.isInCard) buttonClasses += "in-card ";
                switch (banner.buttonPosition) {
                    case "UpperLeft":
                        buttonClasses += "button-upper-left";
                        break;
                    case "UpperRight":
                        buttonClasses += "button-upper-right";
                        break;
                    case "BottomLeft":
                        buttonClasses += "button-bottom-left";
                        break;
                    case "BottomRight":
                        buttonClasses += "button-bottom-right";
                        break;
                    case "BottomMiddle":
                        buttonClasses += "button-bottom-middle";
                        break;
                    case "Middle":
                        buttonClasses += "button-middle";
                        break;
                    default:
                        buttonClasses += "button-bottom-middle";
                        break;
                }

                buttonWrapper = <div className={buttonClasses}>
                    {button}
                </div>;
            }

            let bannerInside = (actionText != null || subActionText != null || buttonWrapper != null) ?
                <div className="inside-banner-container">
                    {actionText}
                    {subActionText}

                    {buttonWrapper}
                </div> : null;

            let bannerWrapper = <>
                <div className="banner-bg">
                    <img src={banner.backgroundImage} />
                </div>

                {bannerInside}
            </>;

            banners.push(bannerWrapper);
            bannerImages.push(banner.backgroundImage);
            bannerInsides.push(bannerInside);
            slideTimes.push(banner.sliderDuration);
        }

        //react-slideshow-image/////////////////////////////////////////////////////////////////////////
        //let bannerSet = banners.length > 1 ?
        //    <Slide duration={sliderDuration}>
        //        {banners.map((banner, index) => (
        //            <div className="each-slide" key={index}>
        //                {banner}
        //            </div>
        //        ))}
        //    </Slide> :
        //    banners[0];

        //Custom slideshow fix//////////////////////////////////////////////////////////////////////////////
        let bannerSet = banners.length > 1 ?
            <Slideshow imageArray={bannerImages} insideArray={bannerInsides} slideTimeArray={slideTimes} /*sliderDuration={sliderDuration}*/ /> :
            banners[0];

        let containerClasses = "homeCTA banner-container";
        let bannercContainerHeight = this.state.bannerContainer != null ? this.state.bannerContainer.height : "";
        switch (bannercContainerHeight) {
            case "Small":
                containerClasses += " banner-small";
                break;
            case "Medium":
                containerClasses += " banner-medium";
                break;
            case "Large":
                containerClasses += " banner-large";
                break;
            default:
                containerClasses += " banner-medium";
                break;
        }

        return (
            <div className={containerClasses}>
                {bannerSet}
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetBannerContainer?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            bannerContainer: data.bannerContainer,
            banners: data.banners
        });
    }
}