import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ImageGalleryField from '../../../ImageGalleryField';

export class BannerForm extends Component {
    static displayName = BannerForm.name;

    constructor(props) {
        super(props);
        this.state = {
            banner: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            bannerContainerId: this.props.bannerContainerId,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newBanner = this.state.banner;
        if (e.target.name === "buttonLink") {
            newBanner[e.target.name] = encodeURI(e.target.value);
        }
        else if (e.target.name === "sliderDuration") {
            if (!isNaN(e.target.value)) {
                let milliseconds = parseInt(e.target.value) * 1000;
                newBanner[e.target.name] = milliseconds;
            }
            else {
                newBanner[e.target.name] = 0;
            }
        }
        else if (e.target.name === "order") {
            newBanner[e.target.name] = (e.target.value != null && e.target.value != "" && e.target.value != undefined) ? parseInt(e.target.value) : 0;
        }
        else {
            newBanner[e.target.name] = e.target.value;
        }
        this.setState({ banner: newBanner });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let banner = this.state.banner;
        banner.versionId = this.state.versionId;
        banner.pageId = this.state.pageId;
        banner.bannerContainerId = this.state.bannerContainerId;

        if (banner.sliderDuration == null || banner.sliderDuration == undefined || banner.sliderDuration == 0) banner.sliderDuration = 5000;

        //console.log(banner);

        let url = this.state.formType == "Update" ? "UpdateBanner" : "CreateBanner";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(banner),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        //var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "Banner", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }
        let backgroundImage = null;
        let actionText = null;
        let buttonText = null;
        let buttonLink = null;
        let componentName = null;
        let sliderDuration = null;
        let buttonPosition = null;
        let height = null;
        let subActionText = null;
        let button2Text = null;
        let button2Link = null;
        let order = null;

        if (this.state.banner != null) {
            let banner = this.state.banner;
            backgroundImage = banner.backgroundImage;
            actionText = banner.actionText;
            buttonText = banner.buttonText;
            buttonLink = banner.buttonLink;
            componentName = banner.componentName;
            sliderDuration = banner.sliderDuration == 0 ? 5 : (banner.sliderDuration / 1000);
            buttonPosition = banner.buttonPosition;
            height = banner.height;
            subActionText = banner.subActionText;
            button2Link = banner.button2Link;
            button2Text = banner.button2Text;
            order = banner.order;
        }

        let isButtonRequired = (
            (buttonText != null && buttonText != "") ||
            (buttonLink != null && buttonLink != "")) ? true : false;

        let isButton2Required = (
            (button2Text != null && button2Text != "") ||
            (button2Link != null && button2Link != "")) ? true : false;

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        let isImageGalleryFieldDisabled = this.state.versionStatus == 'draft' ? false : true;
        return (
            <form id='banner-form' onSubmit={this.handleSubmit.bind(this)} className={wrapperClasses}>
                <div className='tri-grid' id='bannerSection'>
                    <div className='tri-grid__1-3 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('Banner')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />
                    <div></div>

                    <label>Background Image: </label>
                    <input name='backgroundImage' type='text' defaultValue={backgroundImage} onChange={this.handleChange} required hidden />
                    <ImageGalleryField name='backgroundImage' currentImageUrl={backgroundImage} handleImageChange={this.handleChange} isDisabled={isImageGalleryFieldDisabled} />
                    <div></div>

                    <label>Slider Duration: </label>
                    <input name='sliderDuration' type='number' defaultValue={sliderDuration} onChange={this.handleChange} min='1' max='10' step='1' required />
                    <FontAwesomeIcon icon={faInfoCircle} className='info-icon' title='Time it takes (seconds) before next slide starts' />

                    <label>Action Text: </label>
                    <input name='actionText' type='text' defaultValue={actionText} onChange={this.handleChange} />
                    <div></div>

                    <label>Sub Action Text: </label>
                    <input name='subActionText' type='text' defaultValue={subActionText} onChange={this.handleChange} />
                    <div></div>

                    <label>Button Text: </label>
                    <input name='buttonText' type='text' defaultValue={buttonText} onChange={this.handleChange} required={isButtonRequired} />
                    <div></div>

                    <label>Button Link: </label>
                    <input name='buttonLink' type='text' defaultValue={buttonLink} onChange={this.handleChange} required={isButtonRequired} />
                    <div></div>

                    {/*
                    <label>Button 2 Text: </label>
                    <input name='button2Text' type='text' defaultValue={button2Text} onChange={this.handleChange} required={isButton2Required} />
                    <div></div>

                    <label>Button 2 Link: </label>
                    <input name='button2Link' type='text' defaultValue={button2Link} onChange={this.handleChange} required={isButton2Required} />
                    <div></div>
                    */}


                    <label>Button Position: </label>
                    <select name='buttonPosition' value={buttonPosition} onChange={this.handleChange} required={isButtonRequired}>
                        <option value="" key="buttonPos">--Select--</option>
                        <option value="UpperLeft" key="UpperLeft">Upper Left</option>
                        <option value="UpperRight" key="UpperRight">Upper Right</option>
                        <option value="BottomLeft" key="BottomLeft">Bottom Left</option>
                        <option value="BottomRight" key="BottomRight">Bottom Right</option>
                        <option value="BottomMiddle" key="BottomMiddle">Bottom Middle</option>
                        <option value="Middle" key="Middle">Middle</option>
                    </select>
                    <div></div>


                    {/*<label>Height: </label>
                    <select name='height' value={height} onChange={this.handleChange} required>
                        <option value="" key="height">--Select--</option>
                        <option value="Small" key="Small">Small</option>
                        <option value="Medium" key="Medium">Medium</option>
                        <option value="Large" key="Large">Large</option>
                    </select>
                    <div></div>*/}

                    <div className='tri-grid__1-3'>
                        <button form='banner-form' type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetBanner?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            banner: data,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteBanner?componentId=' + this.state.banner.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                //window.location = '/UpdatePageComponent/' + this.state.pageId;
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default BannerForm;
