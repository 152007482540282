import React, { Component } from 'react';
import './formbuildersubmissions.css';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import { FormSubmissionsMassRange } from './FormSubmissionsMassRange';

export class FormBuilderSubmissions extends Component {
    static displayName = FormBuilderSubmissions.name;

    constructor(props) {
        super(props);
        this.state = {
            form: null,
            formFields: null,
            formSubmissionViewModels: null
        }
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let formTitle;
        let maxDynamicColumns = 3;

        if (this.state.form != null) {
            let form = this.state.form;
            formTitle = form.name;
        }

        let tableRows = [];
        if (this.state.formSubmissionViewModels != null) {
            for (var i = 0; i < this.state.formSubmissionViewModels.length; i++) {
                let submissionVM = this.state.formSubmissionViewModels[i];
                let entitySet = {};

                entitySet['viewSubmission'] = <a href={'/FormBuilderSingleSubmission/' + submissionVM.formSubmission.id} className='view-btn' title='View Submission'><FontAwesomeIcon icon={faEye} /></a>;

                var timestamp = new Date(submissionVM.formSubmission.timestamp);
                entitySet['timestamp'] = timestamp.toLocaleDateString() + " " + timestamp.toLocaleTimeString();

                for (var j = 0; j < submissionVM.formSubmissionPairs.length; j++) {
                    let submissionPair = submissionVM.formSubmissionPairs[j];

                    let fieldId = submissionPair.fieldId;
                    let fieldName = submissionPair.fieldName;
                    let fieldValue = (submissionPair.fieldValue != null && submissionPair.fieldValue != undefined && submissionPair.fieldValue.length > 30) ? submissionPair.fieldValue.substring(0, 30) : submissionPair.fieldValue;

                    entitySet[fieldId] = fieldValue;
                }

                entitySet['deleteSubmission'] = <span className='delete-btn' onClick={this.deleteSubmission.bind(this, submissionVM.formSubmission.id)} title='Delete'><FontAwesomeIcon icon={faTrashAlt} /></span>;

                tableRows.push(entitySet);
            }
        }

        let tableColumns = [];
        if (this.state.formFields != null) {
            let formFields = this.state.formFields;

            tableColumns.push({ label: '', field: 'viewSubmission' });
            tableColumns.push({ label: 'Timestamp', field: 'timestamp', sort: 'asc' });
            for (var i = 0; i < formFields.length; i++) {
                if (i > maxDynamicColumns - 1) break;//Limit columns

                let field = formFields[i];

                let fieldName = field.name;
                let fieldId = field.id;

                tableColumns.push({ label: fieldName, field: fieldId, sort: 'asc' });
            }
            tableColumns.push({ label: '', field: 'deleteSubmission' });
        }

        let table = {
            columns: tableColumns,
            rows: tableRows
        };

        let formSubmissionsMassRange = this.state.form != null ?
            <FormSubmissionsMassRange formId={this.state.form.id} /> : "";

        return (
            <div className='form-builder-submissions-container'>
                <div className='page-title'>
                    <h2>{formTitle} Submissions</h2>
                </div>

                <div className='mass-date-range-container'>
                    {formSubmissionsMassRange}
                </div>

                <div className='table-container'>
                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        let formId = this.props.match.params.formId;
        const formResponse = await fetch('AdminForms/GetFormSubmissionData?formId=' + formId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const formData = await formResponse.json();

        this.setState({
            form: formData.form,
            formFields: formData.formFields,
            formSubmissionViewModels: formData.formSubmissionViewModels
        });
    }

    async deleteSubmission(submissionId) {
        let confirmDelete = window.confirm("Are you sure you want to delete submission " + submissionId + "?");

        if (confirmDelete) {
            let id = parseInt(submissionId);
            const response = await fetch('AdminForms/DeleteFormSubmission?submissionId=' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.getComponentData();
            }
            else {
                alert(data);
            }
        }
    }
}
