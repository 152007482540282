import React, { Component } from 'react';
//import './navbar.css';
import { ContactInfo } from './ContactInfo/ContactInfo'
import { MenuItems } from '../MenuItems/MenuItems';
import { SideMenu } from '../sideMenu/sideMenu';
import Backdrop from '../Backdrop/Backdrop';
import MobileToggleButton from '../NavBar/mobileToggleButton';
import { SocialIcons } from '../SocialIcons/SocialIcons';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import SubNavBar from './SubNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import GoogleTranslate from '../GoogleTranslate';
import NavbarTabs from './NavbarTabs';

export class NavBar extends Component {
    static displayName = NavBar.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            logo: null,
            companyName: null,
            phoneNumber: null,
            showContactInfo: null,
            menuItems: null,
            showMobileNav: false,
            facebookLink: null,
            instagramLink: null,
            twitterLink: null,
            socialIconId: null,
            showLoginLink: false,
            showSiteName: false,
            siteName: null,
            urlPathName: null,
            payBillLink: "/"
            //showSocialIcons: false
        };
    }

    showHide = () => {
        this.setState((prevState) => {
            return { showMobileNav: !prevState.showMobileNav };
        });
    };

    componentDidMount() {
        //Google search script
        const googleSearchScript = document.createElement("script");
        googleSearchScript.src = "https://cse.google.com/cse.js?cx=009047190498737394420:zleveeuqgm8";
        googleSearchScript.async = true;
        document.body.appendChild(googleSearchScript);

        this.getComponentData();
    }

    render() {
        let contactInfo;
        if (this.state.showContactInfo != null) {
            contactInfo = <ContactInfo phoneNumber={this.state.phoneNumber} />
        }
        let sideMenu;
        let backdrop;

        if (this.state.showMobileNav) {
            sideMenu = <SideMenu />;
            backdrop = <Backdrop backdropClickHandler={this.showHide} />
        }

        let mobileClasses = 'mobile-nav';
        if (this.state.showMobileNav) {
            mobileClasses = 'open mobile-nav';
        }

        let socialIcons = /*this.state.showSocialIcons &&*/ this.state.socialIconId != null && this.state.socialIconId != 0 ?
            <SocialIcons componentId={this.state.socialIconId} isInNavbar={true} /> : null;

        let siteName = this.state.showSiteName && this.state.siteName != null ? this.state.siteName : null;

        let loginLink = this.state.showLoginLink ?
            <a href='/login' className='login-link' ><FontAwesomeIcon icon={faUserCircle} /></a> : null;

        let logoClasses = this.state.urlPathName == "/" ? "main-logo main-logo__home" : "main-logo";

        let navbarTabs = this.state.urlPathName != "/" ? <NavbarTabs /> : null;

        return (
            <div className='full-width-wrapper'>
                <div className="main-nav-wrap__super">
                    <div className="main-nav-wrap">
                        <div className="main-logo-wrapper">
                            <a href="/" alt="home link"><img src={this.state.logo} alt="company logo" className={logoClasses} /></a>
                        </div>

                        <MobileToggleButton hamburgerClickHandler={this.showHide} />

                        <div className='nav-content-container'>
                            <div className="nav-extras">
                                <div className='nav-extras-main'>
                                    <div className='google-search-bar-container'>
                                        <div className="gcse-searchbox"></div>
                                    </div>

                                    <a className="pay-bill-button" href={this.state.payBillLink}>MY ACCOUNT</a>
                                </div>

                                <div className='nav-extras-sub'>
                                    {/*<span className='phone'>{contactInfo}</span>*/}

                                    <GoogleTranslate />

                                    {socialIcons}
                                </div>
                            </div>

                            <div className="main-nav">
                                <div className="nav-items">
                                    <MenuItems />
                                </div>
                            </div>

                    
                            <div className='sub-nav' style={{ textAlign: 'right' }}>
                                <SubNavBar pageId={this.props.pageId}/>
                            </div>
                        </div>

                        {backdrop}
                        <div className={mobileClasses}>
                            <SideMenu />
                        </div>
                    </div>

                    <div className='mobile-sub-nav-container'>
                        <div className='pay-bill-button--mobile-wrapper'>
                            <a className="pay-bill-button--mobile" href={this.state.payBillLink}>MY ACCOUNT</a>
                        </div>
                    </div>

                    {navbarTabs}

                    <div className='sub-nav-wrap'>
                    
                    </div>
                    <Breadcrumb />

                    <div id='googleSearchResultsContainer'>
                        <div className="gcse-searchresults"></div>
                    </div>
                </div>
            </div>
        );
    }
    async getComponentData() {
        const response = await fetch('components/Navbar?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        //console.log(data);

        let urlPathName = window.location.pathname;

        let payBillLink = data.payBillLink != null && data.payBillLink != "" && data.payBillLink != undefined ? data.payBillLink : "/";

        this.setState({
            logo: data.logoUrl,
            companyName: data.companyName,
            phoneNumber: data.telephone,
            showContactInfo: data.showContactInfo,
            socialIconId: data.socialId,
            showLoginLink: data.showLoginLink,
            showSiteName: data.showSiteName,
            siteName: data.siteName,
            urlPathName: urlPathName,
            payBillLink: payBillLink
            //showSocialIcons: data.showSocialIcons
        });
    }
}

export default NavBar;