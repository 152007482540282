import React, { Component } from 'react';

export class ColumnsTabContainer extends Component {
    static displayName = ColumnsTabContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            columnsTabContainer: null,
            columnsTabs: [],
            currentTab: 0, //This is the id of the current columnsTab component, 0 defaults to the first columnsTab component
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    changeCurrentTab = (columnsTabId) => {
        this.setState({
            currentTab: columnsTabId
        });
    }

    getColumnsTabDisplay = (columnsTab) => {
        let columns;
        switch (columnsTab.format) {
            case "1-1-1-1":
                columns = <div className='columns-container columns__1-1-1-1'>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column1 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column2 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column3 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column4 }}></div>
                </div>;
                break;
            case "2-1-1":
                columns = <div className='columns-container columns__2-1-1'>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column1 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column3 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column4 }}></div>
                </div>;
                break;
            case "1-1-2":
                columns = <div className='columns-container columns__1-1-2'>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column1 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column2 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column3 }}></div>
                </div>;
                break;
            case "2-2":
                columns = <div className='columns-container columns__2-2'>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column1 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column3 }}></div>
                </div>;
                break;
            case "4":
                columns = <div className='columns-container columns__4'>
                    <div dangerouslySetInnerHTML={{ __html: columnsTab.column1 }}></div>
                </div>;
                break;
            default:
                break;
        }

        let footerContent = columnsTab.footer != null && columnsTab.footer != "" ?
            <div className='footer-container'>
                <div dangerouslySetInnerHTML={{ __html: columnsTab.footer }}></div>
            </div> : null;

        let tabContents = <div className='tab-contents'>
            <div className='columns-wrapper'>
                {columns}
            </div>
            {footerContent}
        </div>;

        return tabContents;
    }

    render() {
        let currentTab = this.state.currentTab;
        let currentTabDisplay;
        let tabs = [];

        if (this.state.columnsTabs.length > 0) {
            let columnsTabs = this.state.columnsTabs;

            // Get current tab content
            if (currentTab == 0) {
                currentTabDisplay = this.getColumnsTabDisplay(columnsTabs[0]);
            }
            else {
                for (var i = 0; i < columnsTabs.length; i++) {
                    let columnsTab = columnsTabs[i];
                    if (currentTab == columnsTab.id) {
                        currentTabDisplay = this.getColumnsTabDisplay(columnsTab);
                        break;
                    }
                }
            }

            // Get tabs
            for (var i = 0; i < columnsTabs.length; i++) {
                let columnsTab = columnsTabs[i];

                let tabClasses = "tab";
                if (currentTab == 0 && i == 0) {
                    tabClasses += " current-tab";
                }
                else if (currentTab == columnsTab.id) {
                    tabClasses += " current-tab";
                }

                tabs.push(<div className={tabClasses} title={columnsTab.title} onClick={this.changeCurrentTab.bind(this, columnsTab.id)}>{columnsTab.title}</div>);
            }
        }

        let tabRowClasses = "tab-row";
        let currentTabClasses = "current-tab-contents";
        if (this.state.columnsTabContainer != null && this.state.columnsTabContainer.isAccordion) {
            tabRowClasses += " accordion";
            currentTabClasses += " accordion";
        }

        if (this.state.columnsTabContainer != null && this.state.columnsTabContainer.showBorder) {
            currentTabClasses += " show-border";
        }
        
        return (
            <div className='columns-tab-container-container'>
                <div className={tabRowClasses}>
                    {tabs}
                </div>

                <div className={currentTabClasses}>
                    {currentTabDisplay}
                </div>
            </div>  
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetColumnsTabContainer?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            columnsTabContainer: data.columnsTabContainer,
            columnsTabs: data.columnsTabs
        });
    }
}