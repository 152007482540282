import React, { Component } from 'react';
import EventForm from './EventForm';

export class UpdateEvent extends Component {
    static displayName = UpdateEvent.name;

    constructor(props) {
        super(props);
        this.state = {
            eventId: this.props.match.params.eventId
        };
    }

    render() {
        return (
            <EventForm eventId={this.state.eventId} formType='Update' />  
        );
    }
}