import React, { Component } from 'react';
import './settings.css';
import adminSettingsService from '../AdminSettingsService';
import { getLoggedInUser } from "../../Authentication";

// Modules
import FormSettings from './Modules/FormSettings';
import DocumentSettings from './Modules/DocumentSettings';
import EventSettings from './Modules/EventSettings';
import BlogSettings from './Modules/BlogSettings';
import NewsSettings from './Modules/NewsSettings';
import BirthdaySettings from './Modules/BirthdaySettings';

export class Settings extends Component {
    static displayName = Settings.name;

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            currentDisplay: null,
            isInitialized: false,
            initializationComponentArray: [],
            loggedInUser: null,
            isFormInitialized: false,
            isDocumentInitialized: false,
            isEventInitialized: false,
            isBlogInitialized: false,
            isNewsInitialized: false,
            isBirthdayInitialized: false
        };
    }

    settingsModules = [
        { key: 'FormSettings', name: 'Form', module: <FormSettings onComponentSaved={this.onComponentSaved.bind(this)} /> },
        { key: 'DocumentSettings', name: 'Document', module: <DocumentSettings onComponentSaved={this.onComponentSaved.bind(this)} /> },
        { key: 'EventSettings', name: 'Event', module: <EventSettings onComponentSaved={this.onComponentSaved.bind(this)} /> },
        { key: 'BlogSettings', name: 'Blog', module: <BlogSettings onComponentSaved={this.onComponentSaved.bind(this)} /> },
        { key: 'NewsSettings', name: 'Newsletter', module: <NewsSettings onComponentSaved={this.onComponentSaved.bind(this)} /> },
        { key: 'BirthdaySettings', name: 'Birthday', module: <BirthdaySettings onComponentSaved={this.onComponentSaved.bind(this)} /> }
    ];

    allowModuleDisplay(modKey) {
        let allowDisplay = false;
        switch (modKey) {
            case 'FormSettings':
                allowDisplay = ((!this.state.isFormInitialized && this.state.loggedInUser.company != "TracSoft") ? false : true);
                break;
            case 'DocumentSettings':
                allowDisplay = ((!this.state.isDocumentInitialized && this.state.loggedInUser.company != "TracSoft") ? false : true);
                break;
            case 'EventSettings':
                allowDisplay = ((!this.state.isEventInitialized && this.state.loggedInUser.company != "TracSoft") ? false : true);
                break;
            case 'BlogSettings':
                allowDisplay = ((!this.state.isBlogInitialized && this.state.loggedInUser.company != "TracSoft") ? false : true);
                break;
            case 'NewsSettings':
                allowDisplay = ((!this.state.isNewsInitialized && this.state.loggedInUser.company != "TracSoft") ? false : true);
                break;
            case 'BirthdaySettings':
                allowDisplay = ((!this.state.isBirthdayInitialized && this.state.loggedInUser.company != "TracSoft") ? false : true);
                break;
            default:
                allowDisplay = false;
                break;
        }
        return allowDisplay;
    }

    componentDidMount() {
        this.getComponentData();
    }

    setCurrentDisplay(componentToDisplay) {
        this.setState({
            currentDisplay: componentToDisplay
        });
    }

    submitInitialization() {
        const response = fetch('AdminSettings/InitializeSite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        //const data = response.json();

        let selectedModules = [];
        let initializationModules = document.getElementsByClassName('initialization-module');
        for (var i = 0; i < initializationModules.length; i++) {
            if (initializationModules[i].checked) selectedModules.push(initializationModules[i].value);
        }

        this.setState({
            isInitialized: true,
            initializationComponentArray: selectedModules
        });
    }

    onComponentSaved() {
        let newInitializationComponentArray = this.state.initializationComponentArray;
        newInitializationComponentArray.shift();
        
        if (newInitializationComponentArray.length > 0) {
            this.setState({ initializationComponentArray: newInitializationComponentArray });
        }
        else {
            //this.setCurrentDisplay(this.baseSettingDisplay());
            window.location = '/Settings';
        }
    }

    /* Normal setting screen */
    baseSettingDisplay() {
        let modules = [];
        this.settingsModules.forEach((element, i) => {
            if (this.allowModuleDisplay(element.key)) {
                let key = 'module' + i;
                modules.push(
                    <div className='module-wrapper' key={key}>
                        <button className='form-button' onClick={this.setCurrentDisplay.bind(this, element.module)}>{element.name}</button>
                    </div>
                );
            }
        });

        let display = <div>
            <h4>Select a module</h4>
            {modules}
        </div>;
        return display;
    }

    /* Initialization setting screen */
    initializationSettingDisplay() {
        let modules = [];
        this.settingsModules.forEach((element, i) => {
            if (this.allowModuleDisplay(element.key)) {
                let key = 'module' + i;
                modules.push(
                    <div key={key}>
                        <label className='checkbox-container'>
                            <input type='checkbox' className='initialization-module' value={element.key} />
                            {element.name}
                        </label>
                    </div>
                );
            }
        });

        let display = <div>
            <h4>Select all modules you want to initialize</h4>
            {modules}
            <button className='form-button' onClick={this.submitInitialization.bind(this)}>Initialize</button>
        </div>;
        return display;
    }

    render() {
        let currentDisplay = this.state.currentDisplay;

        if (currentDisplay == null && this.state.isReady) {
            if (this.state.isInitialized) {
                if (this.state.initializationComponentArray.length == 0) {
                    currentDisplay = this.baseSettingDisplay();
                }
                else {
                    let componentKey = this.state.initializationComponentArray[0];
                    this.settingsModules.forEach((element, i) => {
                        if (this.allowModuleDisplay(element.key)) {
                            if (element.key == componentKey) {
                                currentDisplay = element.module;
                                return false;
                            }
                        }
                    });
                }
            }
            else {
                currentDisplay = this.initializationSettingDisplay();
            }
        }

        return (
            <div className='admin-settings'>
                <h3>Settings</h3>
                {currentDisplay}
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('AdminSettings/IsSiteInitialized', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        let isFormInitialized = await adminSettingsService.isFormInitialized();
        let isDocumentInitialized = await adminSettingsService.isDocumentInitialized();
        let isEventInitialized = await adminSettingsService.isEventInitialized();
        let isBlogInitialized = await adminSettingsService.isBlogInitialized();
        let isNewsInitialized = await adminSettingsService.isNewsInitialized();
        let isBirthdayInitialized = await adminSettingsService.isBirthdayInitialized();

        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        this.setState({
            isReady: true,
            isInitialized: data,
            loggedInUser: loggedInUser,
            isFormInitialized: isFormInitialized,
            isDocumentInitialized: isDocumentInitialized,
            isEventInitialized: isEventInitialized,
            isBlogInitialized: isBlogInitialized,
            isNewsInitialized: isNewsInitialized,
            isBirthdayInitialized: isBirthdayInitialized
        });
    }
}
