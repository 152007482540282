import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export class DnDCurrentComponents extends Component {
    static displayName = DnDCurrentComponents.name;

    constructor(props) {
        super(props);
        this.state = {
            columns: []
        };
    }

    componentDidMount() {
        this.setState({
            columns: this.props.columns
        });
    }

    onDragEnd = (result, columns) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {//Move to different column
            //const sourceColumn = columns[source.droppableId];
            //const destColumn = columns[destination.droppableId];
            //const sourceItems = [...sourceColumn.items];
            //const destItems = [...destColumn.items];
            //const [removed] = sourceItems.splice(source.index, 1);
            //destItems.splice(destination.index, 0, removed);
        }
        else {//Move within column
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);

            let newColumns = this.state.columns;
            newColumns[0] = copiedItems;
            newColumns[source.droppableId].items = copiedItems;

            this.props.componentOrderUpdate(copiedItems);
        }
    }

    render() {
        let columns = this.state.columns;

        return (
            <div>
                <DragDropContext onDragEnd={result => this.onDragEnd(result, columns)}>
                    {Object.entries(columns).map(([id, column]) => {
                        return (
                            <div key={id}>
                                <div>
                                    <Droppable droppableId={id} key={id}>
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        background: snapshot.isDraggingOver ? 'lightblue' : '#efefef',
                                                        padding: 4
                                                    }}
                                                >
                                                    {column.items.map((item, index) => {
                                                        return (
                                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                {(provided, snapshot) => {
                                                                    return (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={{
                                                                                userSelect: 'none',
                                                                                margin: '0 0 8px 0',
                                                                                backgroundColor: snapshot.isDragging ? '#263b4a' : '#456c86',
                                                                                color: 'white',
                                                                                ...provided.draggableProps.style
                                                                            }}
                                                                        >
                                                                            {item.content}
                                                                        </div>
                                                                    )
                                                                }}
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )
                                        }}
                                    </Droppable>
                                </div>
                            </div>
                        )
                    })}
                </DragDropContext>
            </div>
        );
    }
}

export default DnDCurrentComponents;
