import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
//import fileIcon from '../images/file.svg';
import fileIcon from '../images/file_blue.png';

export class DocumentTable extends Component {
    static displayName = DocumentTable.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            documentTable: null,
            documents: [],
            filteredDocuments: [],
            typeFilter: ""
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    searchDocuments = (e) => {
        let searchText = e.target.value;

        if (searchText == null || searchText == "" || searchText == undefined) {
            this.setState({ filteredDocuments: this.state.documents });
        }
        else {
            let isOrSearch = false;// Determine if the rows should contain all terms or atleast one term
            let terms = searchText.split(' ');
            let filteredDocs = [];
            for (var i = 0; i < this.state.documents.length; i++) {
                let document = this.state.documents[i];

                let name = document.name != null ? document.name : "";
                let type = document.type != null ? document.type : "";
                let description = document.description != null ? document.description : "";
                let timestamp = this.getTimestampFormat(document.timestamp);


                for (var j = 0; j < terms.length; j++) {
                    let term = terms[j].toLowerCase();

                    // Search each column
                    if (isOrSearch) { // Row contains atleast one term
                        // Skip if term is empty
                        if (term == "") {
                            continue;
                        }

                        if (name.toLowerCase().includes(term) ||
                            type.toLowerCase().includes(term) ||
                            description.toLowerCase().includes(term) ||
                            timestamp.includes(term)) {

                            filteredDocs.push(document);
                            break;
                        }
                    }
                    else { // Row must contain all terms
                        if (name.toLowerCase().includes(term) ||
                            type.toLowerCase().includes(term) ||
                            description.toLowerCase().includes(term) ||
                            timestamp.includes(term)) {

                            if ((j + 1) == terms.length) {
                                filteredDocs.push(document);
                                break;
                            }
                        }
                        else {
                            break;
                        }
                    }
                }
            }
            this.setState({ filteredDocuments: filteredDocs });
        }
    }

    getTimestampFormat(datetime) {
        let timestamp = new Date(datetime.toString());
        let timestampFormat = (timestamp.getMonth() + 1) + "/" + timestamp.getDate() + "/" + timestamp.getFullYear();
        return timestampFormat;
    }

    addTypeFilter = (e) => {
        this.setState({
            typeFilter: e.target.value
        });
    }

    render() {
        let documentTable = this.state.documentTable;
        let documents = this.state.filteredDocuments;

        let tableName;

        if (documentTable != null) {
            tableName = documentTable.componentName;
        }

        let typeFilterOpts = [];
        let tableRows = [];
        if (documents != null && documents.length > 0 && documentTable != null) {
            let maxEntries = documentTable.maxEntries > 0 ? documentTable.maxEntries : documents.length;
            for (var i = 0; i < documents.length; i++) {
                let document = documents[i];

                //Only display the max allowed rows
                if (tableRows.length < maxEntries) {
                    let timestampFormat = this.getTimestampFormat(document.timestamp);
                    let documentTypesArray = (document.type != null && document.type != "" && document.type != undefined) ? document.type.split(",") : [];

                    // Skip if not part of current type filter
                    if (this.state.typeFilter != "") {
                        if (!documentTypesArray.includes(this.state.typeFilter)) {
                            continue;
                        }
                    }

                    let row;
                    if (documentTable.isCompact) {
                        row = <div className='compact-row'>
                            <a href={document.location} target='_blank' title='View'>
                                <img src={fileIcon} style={{width: '30px'}} />
                                {document.name}
                            </a>
                        </div>;
                    }
                    else {
                        row = {
                            viewDocument: <a href={document.location} target='_blank' title='View'><FontAwesomeIcon icon={faEye} /></a>,
                            name: document.name,
                            type: document.type,
                            description: document.description,
                            //timestamp: timestampFormat
                        };
                    }
                    
                    tableRows.push(row);
                }
            }

            // Get the unique types in the table for filters
            typeFilterOpts = (documentTable.types != null && documentTable.types != "" && documentTable.types != undefined) ? documentTable.types.split(",") : [];
        }

        let table;
        let customSearch;
        let typeFilterSelect;
        if (documentTable != null) {
            if (documentTable.isCompact) {
                tableName = null;

                table = <div className='compact-table'>
                    {tableRows}
                </div>;
            }
            else {
                let tableData = {
                    columns: [
                        { label: '', field: 'viewDocument' },
                        { label: 'Name', field: 'name', sort: 'asc' },
                        { label: 'Type', field: 'type', sort: 'asc' },
                        { label: 'Description', field: 'description', sort: 'asc' },
                        //{ label: 'Timestamp', field: 'timestamp', sort: 'asc' }
                    ],
                    rows: tableRows
                };
                table = <MDBDataTable entries={100} data={tableData} searching={false} displayEntries={false} />;

                customSearch = <div className='custom-search-bar-wrapper'>
                    <input type='text' placeholder='Search' className='custom-search-bar' onKeyUp={this.searchDocuments.bind(this)} />
                </div>;

                let typeOpts = [];
                for (var i = 0; i < typeFilterOpts.length; i++) {
                    let typeKey = "typeFilter" + i;
                    typeOpts.push(<option key={typeKey} value={typeFilterOpts[i]}>{typeFilterOpts[i]}</option>);
                }

                typeFilterSelect = <select className='document-type-filter' onChange={this.addTypeFilter} >
                    <option key='typeFilterAll' value=''>All</option>
                    {typeOpts}
                </select>;
            }
        }
        
        return (
            <div className='document-table-container'>
                <div className='table-container'>
                    <div className='table-name'>
                        {/*{tableName}*/}
                    </div>

                    {typeFilterSelect}
                    {customSearch}
                    {table}
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetDocumentTable?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            documentTable: data.documentTable,
            documents: data.documents,
            filteredDocuments: data.documents
        });
    }
}
