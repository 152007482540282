import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import HtmlEditorField from '../../../HtmlEditorField';
import ColumnsIcon from '../../../ColumnsIcon';

export class AccordionTabForm extends Component {
    static displayName = AccordionTabForm.name;

    constructor(props) {
        super(props);
        this.state = {
            accordionTab: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            accordionId: this.props.accordionId,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newAccordionTab = this.state.accordionTab;
        newAccordionTab[e.target.name] = e.target.value;
        this.setState({ accordionTab: newAccordionTab });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let accordionTab = this.state.accordionTab;
        accordionTab.versionId = this.state.versionId;
        accordionTab.pageId = this.state.pageId;
        accordionTab.accordionId = this.state.accordionId;

        let url = this.state.formType == "Update" ? "UpdateAccordionTab" : "CreateAccordionTab";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(accordionTab),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        //var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        //var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "AccordionTab", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let title;
        let content;
        let componentName;
        if (this.state.accordionTab != null) {
            let accordionTab = this.state.accordionTab;

            title = accordionTab.title;
            content = accordionTab.content;
            componentName = accordionTab.componentName;
        }

        // Display according to the format
        let quadEditorWrapperClasses = this.state.versionStatus == 'draft' ? 'editor-wrapper grid-span-4' : 'editor-wrapper disable-wrapper grid-span-4';
        let htmlEditorColumnsFields = <>
            <div className={quadEditorWrapperClasses}>
                <HtmlEditorField content={content} handleEditorChange={this.handleChange} name='content' returnAsEvent={true} />
            </div>
        </>;

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form id='accordionTabForm' onSubmit={this.handleSubmit.bind(this)} className={wrapperClasses}>
                <div className='tri-grid'>
                    <div className='tri-grid__1-3 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('AccordionTab')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />
                    <div></div>

                    <label>Title:</label>
                    <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required />
                    <div></div>

                    <div className='tri-grid__1-3'>
                        <div className='inner-quad-grid'>
                            {htmlEditorColumnsFields}
                        </div>
                    </div>

                    <div className='tri-grid__1-3'>
                        <button form='accordionTabForm' type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetAccordionTab?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            accordionTab: data,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteAccordionTab?componentId=' + this.state.accordionTab.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default AccordionTabForm;