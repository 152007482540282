import React, { Component } from 'react';
import PageForm from './PageForm';
import './pageform.css';

export class CreateExternalPage extends Component {
    static displayName = CreateExternalPage.name;

    render() {
        return (
            <PageForm pageId='0' formType='Create' isExternal={true} />
        );
    }
}
