import React, { Component } from 'react';

export class FakeDnDPageOrder extends Component {
    static displayName = FakeDnDPageOrder.name;

    constructor(props) {
        super(props);
        this.state = {
            pages: [],
            parentPageName: null,
            currentPageId: null
        };
    }

    componentDidMount() {
        this.setState({
            pages: this.props.pages,
            parentPageName: this.props.parentPageName,
            currentPageId: this.props.currentPageId
        });
    }

    render() {
        let columns = this.state.pages;
        let parentPageName = this.state.parentPageName;
        let currentPageId = this.state.currentPageId;

        return (
            <div style={{ display: 'flex', justifyContext: 'center', height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'rgb(239, 239, 239)' }}>
                    <label style={{ fontWeight: 'bold', marginBottom: '0', wordBreak: 'break-word' }}>{parentPageName}</label>
                    <label style={{ fontWeight: 'bold', marginBottom: '0.4rem' }}>Menu Order</label>
                    <div style={{ background: '#efefef', padding: 4, width: 250, minHeight: 450 }}>
                        <div style={{ background: '#efefef', padding: '4px' }}>
                            {columns.map((item, index) => {
                                return (
                                    <div key={index} style={{
                                        userSelect: 'none',
                                        margin: '0 0 8px 0',
                                        backgroundColor: '#456c86',
                                        color: item.id == currentPageId ? 'yellow' : 'white',
                                        padding: '10px 0',
                                    }}>
                                        {item.content}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>  
            </div>
        );
    }
}

export default FakeDnDPageOrder;
