import React, { Component } from 'react';

export class FakeDnDCurrentComponent extends Component {
    static displayName = FakeDnDCurrentComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            columns: []
        };
    }

    componentDidMount() {
        this.setState({
            columns: this.props.columns
        });
    }

    render() {
        let columns = this.state.columns;

        return (
            <div>
                {Object.entries(columns).map(([id, column]) => {
                    return (
                        <div key={id} style={{ background: '#efefef', padding: '4px' }}>
                            {column.items.map((item, index) => {
                                return (
                                    <div key={index} style={{ userSelect: 'none', margin: '0 0 8px 0', backgroundColor: '#456c86', color: 'white' }}>
                                        {item.content}
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>  
        );
    }
}

export default FakeDnDCurrentComponent;
