import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import './sidenavmanagement.css';

export class SideNavManagement extends Component {
    static displayName = SideNavManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            sideNavs: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let sideNavs = this.state.sideNavs;

        let tableRows = [];
        if (sideNavs.length > 0) {
            for (var i = 0; i < sideNavs.length; i++) {
                let sideNav = sideNavs[i];

                let row = {
                    editSideNav: <a href={'UpdateSideNav/' + sideNav.id} className='update-btn' title='Edit'><FontAwesomeIcon icon={faEdit} /></a>,
                    componentName: sideNav.componentName,
                    deleteSideNav: <p className='delete-btn' title='Delete' onClick={this.deleteSideNav.bind(this, sideNav.id)}><FontAwesomeIcon icon={faTrashAlt} /></p>
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: '', field: 'editSideNav' },
                { label: 'Name', field: 'componentName', sort: 'asc' },
                { label: '', field: 'deleteSideNav' }
            ],
            rows: tableRows
        };

        return (
            <div className='side-nav-management'>
                <div className='page-title'>
                    <h1>Side Nav Management</h1>
                </div>

                <div className='table-container'>
                    <a href='/CreateSideNav' title='Create Side Nav'><button className='form-management-button'>Create Side Nav</button></a>
                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('AdminSideNav/GetSideNavs', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            sideNavs: data
        });
    }

    async deleteSideNav(sideNavId) {
        let confirmDelete = window.confirm("Are you sure you want to delete this side nav?");

        if (confirmDelete) {
            let id = parseInt(sideNavId);
            const response = await fetch('AdminSideNav/DeleteSideNav?id=' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                window.location = '/SideNavManagement';
            }
            else {
                alert(data);
            }
        }
    }
}