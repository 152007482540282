import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import './blogmanagement.css';

export class BlogManagement extends Component {
    static displayName = BlogManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            blogs: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    formatDateTime(dateTime, includeTime, isTextFormat) {
        let timestamp = new Date(dateTime.toString());

        let year = timestamp.getFullYear();
        let month = timestamp.getMonth().toString().length === 1 ? '0' + (timestamp.getMonth() + 1).toString() : timestamp.getMonth() + 1;
        let date = timestamp.getDate().toString().length === 1 ? '0' + (timestamp.getDate()).toString() : timestamp.getDate();
        let day = timestamp.getDay();
        let hours = timestamp.getHours().toString().length === 1 ? '0' + timestamp.getHours().toString() : timestamp.getHours();
        let midday = hours >= 12 ? "PM" : "AM";
        if (hours == 0) {
            hours = 12;
        }
        if (hours >= 13) {
            hours = hours - 12;
            if (hours.toString().length === 1) hours = '0' + hours;
        }
        let minutes = timestamp.getMinutes().toString().length === 1 ? '0' + timestamp.getMinutes().toString() : timestamp.getMinutes();

        var abvMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var abvWeekDays = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

        let timestampFormat;
        if (isTextFormat) {
            timestampFormat = includeTime ?
                (abvWeekDays[day] + ", " + abvMonths[month - 1] + " " + date + ", " + year + " " + hours + ":" + minutes + " " + midday) :
                (abvWeekDays[day] + ", " + abvMonths[month - 1] + " " + date + ", " + year);
        }
        else {
            timestampFormat = includeTime ?
                (month + "/" + date + "/" + year + " " + hours + ":" + minutes + " " + midday) :
                (month + "/" + date + "/" + year);
        }

        return timestampFormat;
    }

    render() {
        let blogs = this.state.blogs;

        let tableRows = [];
        if (blogs.length > 0) {
            for (var i = 0; i < blogs.length; i++) {
                let blog = blogs[i];

                let timestampFormat = this.formatDateTime(blog.timestamp, false, true);

                let blogUrl = '/blog/' + blog.friendlyUrl;

                let editIcon = blog.status == "Live" ?
                    <a href={'UpdateBlog/' + blog.id} className='view-btn' title='View'><FontAwesomeIcon icon={faEye} /></a> :
                    <a href={'UpdateBlog/' + blog.id} className='update-btn' title='Edit'><FontAwesomeIcon icon={faEdit} /></a>;

                let row = {
                    editBlog: editIcon,
                    title: blog.title,
                    //teaser: blog.teaser,
                    url: blogUrl,
                    types: blog.types,
                    //uploader: blog.uploaderEmail,
                    status: blog.status,
                    timestamp: timestampFormat
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: '', field: 'editBlog' },
                { label: 'Title', field: 'title', sort: 'asc' },
                //{ label: 'Teaser', field: 'teaser', sort: 'asc' },
                { label: 'Url', field: 'url', sort: 'asc' },
                { label: 'Types', field: 'types', sort: 'asc' },
                //{ label: 'Uploader', field: 'uploader', sort: 'asc' },
                { label: 'Status', field: 'status', sort: 'asc' },
                { label: 'Timestamp', field: 'timestamp', sort: 'asc' }
            ],
            rows: tableRows
        };

        return (
            <div className='blog-management'>
                <div className='page-title'>
                    <h1>Blog Management</h1>
                </div>

                <div className='table-container'>
                    <a href='/CreateBlog' title='Create a new blog'><button className='form-management-button'>Create Blog</button></a>
                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('AdminBlog/GetBlogs', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            blogs: data
        });
    }
}