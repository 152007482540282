import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEdit, faTrashAlt, faEllipsisV, faEye } from '@fortawesome/free-solid-svg-icons';
import BannerForm from './BannerForm';
import ComponentFormPopup from '../ComponentFormPopup';
import DnDBanner from './DnDBanner';
import FakeDnDBanner from './FakeDnDBanner';

export class BannerContainerForm extends Component {
    static displayName = BannerContainerForm.name;

    constructor(props) {
        super(props);
        this.state = {
            bannerContainer: null,
            banners: [],
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false,
            popup: null,
            reset: false
        };
        this.displayPopup = this.displayPopup.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.bannerOrderUpdate = this.bannerOrderUpdate.bind(this);
        this.deleteBanner = this.deleteBanner.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newBannerContainer = this.state.bannerContainer;

        if (e.target.name === "sliderDuration") {
            if (!isNaN(e.target.value)) {
                let milliseconds = parseInt(e.target.value) * 1000;
                newBannerContainer[e.target.name] = milliseconds;
            }
            else {
                newBannerContainer[e.target.name] = 0;
            }
        }
        else {
            newBannerContainer[e.target.name] = e.target.value;
        }
        this.setState({ bannerContainer: newBannerContainer });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let bannerContainer = this.state.bannerContainer;
        bannerContainer.versionId = this.state.versionId;
        bannerContainer.pageId = this.state.pageId;

        if (bannerContainer.sliderDuration == null || bannerContainer.sliderDuration == undefined || bannerContainer.sliderDuration == 0) bannerContainer.sliderDuration = 5000;

        let url = this.state.formType == "Update" ? "UpdateBannerContainer" : "CreateBannerContainer";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(bannerContainer),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "BannerContainer", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    displayPopup = (bannerId, formType) => {
        let popupForm = <BannerForm
            componentId={bannerId}
            formType={formType}
            pageId={this.state.pageId}
            pageComponentId={this.props.pageComponentId}
            versionStatus={this.state.versionStatus}
            versionId={this.state.versionId}
            onComponentSaved={this.onPopupComponentSaved.bind(this)}
            isEditOnly={true}
            isInPopup={true}
            bannerContainerId={this.state.bannerContainer.id}
        />;

        let popup = <ComponentFormPopup
            popupForm={popupForm}
            cancelClicked={this.cancelClicked.bind(this)}
        />;

        this.setState({
            popup: popup
        });
    }

    onPopupComponentSaved() {
        alert("Saved");

        this.setState({
            reset: true,
            popup: null
        });
        this.getComponentData();
    }

    cancelClicked() {
        this.setState({
            popup: null
        });
    }

    async bannerOrderUpdate(componentArray) {
        let componentIdsOrdered = [];
        for (var i = 0; i < componentArray.length; i++) {
            componentIdsOrdered.push(parseInt(componentArray[i].id));
        }

        const response = await fetch('Admin/UpdateBannerComponentOrder', {
            method: 'POST',
            body: JSON.stringify({ "bannerIds": componentIdsOrdered }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != "Saved") {
            alert(data);
        }
    }

    async deleteBanner(bannerId) {
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteBanner?componentId=' + bannerId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.setState({
                    reset: true
                });
                this.getComponentData();
            }
            else {
                alert(data);
            }
        }
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let componentName = null;
        let sliderDuration = null;
        let height = null;

        if (this.state.bannerContainer != null) {
            let bannerContainer = this.state.bannerContainer;

            componentName = bannerContainer.componentName;
            sliderDuration = bannerContainer.sliderDuration == 0 ? 5 : (bannerContainer.sliderDuration / 1000);
            height = bannerContainer.height;
        }

        let mainFormClasses = "tri-grid";
        let sideMenu;
        let addNewBannerButton = null;
        let bannerButtons = [];
        if (this.state.formType == "Update") {
            addNewBannerButton = this.state.versionStatus == 'draft' ?
                <div onClick={this.displayPopup.bind(this, 0, 'Create')} className='side-menu-button'>Add Slide</div> : "";

            for (var i = 0; i < this.state.banners.length; i++) {
                let banner = this.state.banners[i];
                console.log(banner);

                let bannerKey = "banner" + i;

                let currentComponentManagementBtns = this.state.versionStatus == 'draft' ?
                    <>
                        <span className='current-component__edit' onClick={this.displayPopup.bind(this, banner.id, 'Update')}><FontAwesomeIcon icon={faEdit} /></span>
                        <span className='current-component__delete' onClick={this.deleteBanner.bind(this, banner.id)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                    </> :
                    <>
                        <span className='current-component__view' onClick={this.displayPopup.bind(this, banner.id, 'Update')}><FontAwesomeIcon icon={faEye} /></span>
                    </>;

                let bannerBtnClasses = this.state.versionStatus == 'draft' ? 'sub-component-button' : 'sub-component-button disable-dnd';

                let bannerBtn =
                    <div key={bannerKey} className={bannerBtnClasses}>

                        {banner.componentName}

                        <div className='current-component__manage'>
                            {currentComponentManagementBtns}
                        </div>
                    </div>;

                bannerButtons.push({ id: banner.id.toString(), content: bannerBtn });
            }

            let bannerDnD = (this.state.versionStatus == 'draft') ?
                <DnDBanner items={bannerButtons} itemOrderUpdate={this.bannerOrderUpdate.bind(this)} /> :
                <FakeDnDBanner items={bannerButtons} />;

            if (this.state.reset) {
                bannerDnD = "";
            }

            sideMenu = <div className='side-menu'>
                {addNewBannerButton}
                {bannerDnD}
            </div>;
        }
        else {
            mainFormClasses += " dual-grid__1-2";
        }

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <div className={wrapperClasses}>
                {this.state.popup}

                <form onSubmit={this.handleSubmit}>
                    <div className='dual-grid'>
                        {sideMenu}

                        <div className={mainFormClasses}>
                            <div className='tri-grid__1-3 form-title'>
                                <h3>{componentName} {componentMap.componentTypeDisplay('BannerContainer')}</h3>
                            </div>

                            <label>Component Name:</label>
                            <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />
                            <div></div>

                            {/*
                            <label>Slider Duration: </label>
                            <input name='sliderDuration' type='number' defaultValue={sliderDuration} onChange={this.handleChange} min='1' max='10' step='1' required />
                            <FontAwesomeIcon icon={faInfoCircle} className='info-icon' title='Time it takes (seconds) before next slide starts' />
                            */}

                            {/*
                            <label>Height: </label>
                            <select name='height' value={height} onChange={this.handleChange} required>
                                <option value="" key="height">--Select--</option>
                                <option value="Small" key="Small">Small</option>
                                <option value="Medium" key="Medium">Medium</option>
                                <option value="Large" key="Large">Large</option>
                            </select>
                            <div></div>
                            */}

                            <div className='tri-grid__1-3'>
                                <button type='submit'>Save</button>
                                {deleteButton}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetBannerContainer?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            bannerContainer: data.bannerContainer,
            banners: data.banners,
            formType: formType,
            reset: false,
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteBannerContainer?componentId=' + this.state.bannerContainer.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default BannerContainerForm;
