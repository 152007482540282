export class AdminSettingsService {
    /* Check if form settings are initialized */
    async isFormInitialized() {
        const response = await fetch('AdminSettings/HasFormSettingsData', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }

    /* Return the version limit value */
    async getVersionLimit() {
        const response = await fetch('AdminSettings/GetVersionLimit', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }

    /* Check if document settings are initialized */
    async isDocumentInitialized() {
        const response = await fetch('AdminSettings/HasDocumentSettings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }

    /* Check if event settings are initialized */
    async isEventInitialized() {
        const response = await fetch('AdminSettings/HasEventSettings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }

    /* Check if blog settings are initialized */
    async isBlogInitialized() {
        const response = await fetch('AdminSettings/HasBlogSettings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }

    /* Check if news settings are initialized */
    async isNewsInitialized() {
        const response = await fetch('AdminSettings/HasNewsSettings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }

    /* Check if birthday settings are initialized */
    async isBirthdayInitialized() {
        const response = await fetch('AdminSettings/HasBirthdaySettings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        return data;
    }
}

const adminSettingsService = new AdminSettingsService();
export default adminSettingsService;