import React, { Component } from 'react';

export class ActionButton extends Component {
    static displayName = ActionButton.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            actionButton: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let text;
        let link;
        if (this.state.actionButton != null) {
            text = this.state.actionButton.text;
            link = this.state.actionButton.link;
        }

        return (
            <div className="grid-full">
                <a href={link} className="greenbutton call center-button text-center">{text}</a>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('Components/GetActionButton?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                actionButton: data
            });
        }
    }
}
