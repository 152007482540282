import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import ImageGalleryField from '../../../ImageGalleryField';

export class Grid5050Form extends Component {
    static displayName = Grid5050Form.name;

    constructor(props) {
        super(props);
        this.state = {
            grid5050: null,
            htmlEditors: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newGrid5050 = this.state.grid5050;
        if (e.target.name == "htmlEditorId") {
            newGrid5050[e.target.name] = parseInt(e.target.value, 10);
        }
        else {
            newGrid5050[e.target.name] = e.target.value;
        }
        this.setState({ grid5050: newGrid5050 });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let grid5050 = this.state.grid5050;
        grid5050.versionId = this.state.versionId;
        grid5050.pageId = this.state.pageId;
        console.log(grid5050);
        let url = this.state.formType == "Update" ? "UpdateGrid5050" : "CreateGrid5050";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(grid5050),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                    var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "Grid5050", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    
                    //window.location = '/UpdatePageComponent/' + this.state.pageId;
                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        let htmlEditorOptions = [];
        let selectedId = null;
        let imageLink = null;
        let componentName = null;

        htmlEditorOptions.push(<option value="" key="htmlEditor0">--Select--</option>);
        if (this.state.grid5050 != null && this.state.htmlEditors != null) {
            let grid5050 = this.state.grid5050;
            selectedId = grid5050.htmlEditorId;
            imageLink = grid5050.imageLink;
            componentName = grid5050.componentName;
            for (var i = 0; i < this.state.htmlEditors.length; i++) {
                let htmlEditor = this.state.htmlEditors[i];
                if (htmlEditor.pageId == this.state.pageId) {
                    let htmlEditorKey = "htmlEditor" + (i + 1);

                    let ddlDisplay = htmlEditor.componentName != null ? htmlEditor.componentName + "-V" + htmlEditor.versionId : htmlEditor.content.substring(0, 100) + "-V" + htmlEditor.versionId;
                    htmlEditorOptions.push(<option value={htmlEditor.id} key={htmlEditorKey}>{ddlDisplay}</option>);
                }
            }
        }

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        let isImageGalleryFieldDisabled = this.state.versionStatus == 'draft' ? false : true;
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('Grid5050')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />
                    
                    <label>Html Editor: </label>
                    <select name='htmlEditorId' value={selectedId} onChange={this.handleChange} required>
                        {htmlEditorOptions}
                    </select>
                    
                    <label>Image Link: </label>
                    <input name='imageLink' type='text' defaultValue={imageLink} onChange={this.handleChange} required hidden />
                    <ImageGalleryField name='imageLink' currentImageUrl={imageLink} handleImageChange={this.handleChange} isDisabled={isImageGalleryFieldDisabled} />
                    
                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetGrid5050?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            grid5050: data.grid5050,
            htmlEditors: data.htmlEditors,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteGrid5050?componentId=' + this.state.grid5050.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                //window.location = '/UpdatePageComponent/' + this.state.pageId;
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default Grid5050Form;