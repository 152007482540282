import React, { Component } from 'react';

export class FakeDnDBanner extends Component {
    static displayName = FakeDnDBanner.name;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.setState({
            items: this.props.items,
        });
    }

    render() {
        let columns = this.state.items;

        return (
            <div style={{ display: 'flex', justifyContext: 'center', height: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'rgb(239, 239, 239)' }}>
                    <label style={{ fontWeight: 'bold', marginBottom: '0.4rem' }}>Banner Order</label>
                    <div style={{ background: '#efefef', padding: 4, width: '100%' }}>
                        <div style={{ background: '#efefef', padding: '4px' }}>
                            {columns.map((item, index) => {
                                return (
                                    <div key={index} style={{
                                        userSelect: 'none',
                                        margin: '0 0 8px 0',
                                        backgroundColor: '#456c86',
                                        color: 'white',
                                    }}>
                                        {item.content}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FakeDnDBanner;
