import React, { Component } from 'react';
import NewsletterIcon from '../images/ClientStatic/NewsletterIcon.png';
import Mailchimp from '../Mailchimp';

export class SuperFooter extends Component {
    static displayName = SuperFooter.name;

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className='super-footer-container'>
                <div className='super-footer-header-wrapper'>
                    <div className='super-footer-header'>
                        <img alt="newsletter icon" src={NewsletterIcon} />
                        <span className='header-text-container'>
                            <span className='super-text'>Let's Stay</span>
                            <span className='sub-text'>in touch.</span>
                        </span>
                    </div>
                </div>

                <div className='super-footer-content'>
                    <div className='content-description-container'>
                        <span className='content-description'>Sign up to receive our newsletter</span>
                        <span className='content-description'>The Spout to stay in the know.</span>
                    </div>

                    <Mailchimp/>
                </div>
            </div>  
        );
    }
}

export default SuperFooter;
