import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import '../../Themes/theme.css';
import SideNav from '../../SideNav/SideNav';

// Components
import { NavBar } from '../../NavBar/NavBar';
import { BannerContainer } from '../../BannerContainer/BannerContainer';
import { IconLinkContainer } from '../../IconLinkContainer/IconLinkContainer';
import { ColumnsTabContainer } from '../../ColumnsTabContainer/ColumnsTabContainer';
import { Accordion } from '../../Accordion/Accordion';
import { Footer } from '../../Footer/Footer';
import { Announcement } from '../../Announcement/Announcement';
import { HtmlEditor } from '../../HtmlEditor/HtmlEditor';
import { SocialIcons } from '../../SocialIcons/SocialIcons';
import { BigText5050 } from '../../BigText5050/BigText5050';
import { Grid5050 } from '../../Grid5050/Grid5050';
import { Contact5050 } from '../../Contact5050/Contact5050';
import { ActionButton } from '../../ActionButton/ActionButton';
import { HRSeparator } from '../../HRSeparator/HRSeparator';
import { SingleFeatures1 } from '../../Features1/SingleFeatures1';
import { Features1 } from '../../Features1/Features1';
import { Card } from '../../Card/Card';
import { CardContainer } from '../../CardContainer/CardContainer';
import { Calendar } from '../../Calendar/Calendar';
import { DocumentTable } from '../../DocumentTable/DocumentTable';
import { EventTable } from '../../EventTable/EventTable';
import { BlogTable } from '../../BlogTable/BlogTable';
import { BlogPreview } from '../../BlogPreview/BlogPreview';
import { NewsTable } from '../../NewsTable/NewsTable';
import { BirthdayTable } from '../../BirthdayTable/BirthdayTable';
import { SideContainer } from '../../SideContainer/SideContainer';

const components = {
    Navbar: NavBar,
    BannerContainer: BannerContainer,
    IconLinkContainer: IconLinkContainer,
    ColumnsTabContainer: ColumnsTabContainer,
    Accordion: Accordion,
    Footer: Footer,
    Announcement: Announcement,
    HtmlEditor: HtmlEditor,
    BigText5050: BigText5050,
    Grid5050: Grid5050,
    Contact5050: Contact5050,
    ActionButton: ActionButton,
    HRSeparator: HRSeparator,
    SingleFeatures1: SingleFeatures1,
    Features1: Features1,
    SocialIcons: SocialIcons,
    Card: Card,
    CardContainer: CardContainer,
    Calendar: Calendar,
    DocumentTable: DocumentTable,
    EventTable: EventTable,
    BlogTable: BlogTable,
    BlogPreview: BlogPreview,
    NewsTable: NewsTable,
    BirthdayTable: BirthdayTable,
    SideContainer: SideContainer
};

export class PagePreview extends Component {
    static displayName = PagePreview.name;

    constructor(props) {
        super(props);
        this.state = {
            pageContent: null,
            page: null
        };

        this.pageId = props.match.params.pageId;
        this.versionId = props.match.params.versionId;
    }

    async componentDidMount() {
        this.getPageData();
    }

    generateComponents(data) {
        if (data != null) {
            var elements = [];
            var lastElementType = "";
            data.forEach((element, i) => {
                const newComponent = components[element.type];
                const newC = React.createElement(newComponent, { componentId: element.componentId, pageId: this.state.page.id, key: i });

                // Add component and side nav if applicable
                if (lastElementType == "Navbar" && this.state.page != null && this.state.page.sideNavId > 0) {
                    let sideNav = <SideNav componentId={this.state.page.sideNavId} />;
                    let columnElement = <div className='dynamic-pages-content-grid'>
                        {newC}

                        {sideNav}
                    </div>;
                    elements.push(columnElement);
                }
                else {
                    elements.push(newC);
                }

                // Add page header to each page that is not the home page
                if (element.type == "Navbar" && this.state.page.friendlyUrl != "/") {
                    elements.push(<h1 className='page-name-header'>{this.state.page.name}</h1>);
                }

                lastElementType = element.type;
            });
            //console.log(elements);
            return elements;
        }
    }

    render() {
        let pageContentData = this.state.pageContent;
        let page = this.state.page;

        let metaData;
        if (page != null) {
            metaData = <MetaTags>
                <title>{page.scoTitle}</title>
                <meta name="description" content={page.scoMetaDescription} />
                <meta name="keywords" content={page.scoMetaKeywords} />
            </MetaTags>;
        }

        return (
            <div className='dynamic-pages-container'>
                {metaData}

                {this.generateComponents(pageContentData)}
            </div>
        );
    }

    async getPageData() {
        let routeData = {
            PageId: parseInt(this.pageId),
            VersionId: parseInt(this.versionId)
        };
        console.log(routeData);
        const response = await fetch('Admin/GetPagePreview', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(routeData)
        });

        const data = await response.json();
        //console.log(data);
        await this.setState({
            pageContent: data.pageComponents,
            page: data.page
        });
    }
}

