import React, { Component } from 'react';
import './pagecomponentmanagement.css';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { removeLoginCookie, getLoggedInUser } from "../../Authentication";
import { getRolesByUserId } from "../RoleManagement/RoleManagement";

export class PageComponentManagement extends Component {
    static displayName = PageComponentManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            allPageComponentDatas: null,
            filteredPageComponentDatas: null,
            includeDeletedPages: false,
            loggedInUser: null,
            loggedInUserCMSRole: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    filterDeletedPages = (e) => {
        let includeDeletedPages = e.target.checked ? true : false;
        
        this.setState({
            includeDeletedPages: includeDeletedPages
        });
    }

    render() {
        let filteredPageComponents = this.state.filteredPageComponentDatas;

        let tableRows = [];
        if (filteredPageComponents != null) {
            for (var i = 0; i < filteredPageComponents.length; i++) {
                let pageComponent = filteredPageComponents[i];

                if (!this.state.includeDeletedPages && pageComponent.versionStatus == "Deleted") {
                    continue;
                }

                let editComponent = pageComponent.isExternal ? 
                    <a href={'/UpdateExternalPage/' + pageComponent.pageId} className='update-btn' title='Edit'><FontAwesomeIcon icon={faEdit} /></a> :
                    <a href={'/UpdatePageComponent/' + pageComponent.pageId} className='update-btn' title='Edit'><FontAwesomeIcon icon={faEdit} /></a>

                let row = {
                    editComponent: editComponent,
                    pageName: pageComponent.pageName,
                    //pageOrder: pageComponent.pageOrder,
                    pageMenu: pageComponent.pageMenuUrl == null ? "" : pageComponent.pageMenuUrl,
                    childMenu: pageComponent.pageChildMenu == null ? "" : pageComponent.pageChildMenu,
                    subMenu: pageComponent.pageSubMenu == null ? "" : pageComponent.pageSubMenu,
                    pageUrl: pageComponent.pageUrl,
                    versionStatus: pageComponent.versionStatus,
                    allowNavigation: pageComponent.pageAllowNavigation == true ? <FontAwesomeIcon icon={faCheck} className='check-icon' title='True' /> : <FontAwesomeIcon icon={faTimes} className='times-icon' title='False' />,
                    showOnMenu: pageComponent.pageShowOnMenu == true ? <FontAwesomeIcon icon={faCheck} className='check-icon' title='True' /> : <FontAwesomeIcon icon={faTimes} className='times-icon' title='False' />,
                    //componentCount: pageComponent.componentCount
                    isExternal: pageComponent.isExternal ? <FontAwesomeIcon icon={faCheck} className='check-icon' title='True' /> : <FontAwesomeIcon icon={faTimes} className='times-icon' title='False' />
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: '', field: 'editComponent' },
                { label: <span title="The name of the page">Page Name</span>, field: 'pageName', sort: 'asc' },
                //{ label: <span title="The order the pages will display on the menu">Order</span>, field: 'pageOrder', sort: 'asc' },
                { label: <span>Parent Menu</span>, field: 'pageMenu', sort: 'asc' },
                { label: <span>Child Menu</span>, field: 'childMenu', sort: 'asc' },
                { label: <span>Sub Menu</span>, field: 'subMenu', sort: 'asc' },
                { label: <span>Friendly URL</span>, field: 'pageUrl', sort: 'asc' },
                { label: <span>Version Status</span>, field: 'versionStatus', sort: 'asc' },
                { label: <span title="Is the user able to navigate to the page">Navigable</span>, field: 'allowNavigation', sort: 'asc' },
                { label: <span title="Will the component display on the menu">On Menu</span>, field: 'showOnMenu', sort: 'asc' },
                //{ label: <span title="The count of components on the page">Components</span>, field: 'componentCount', sort: 'asc' }
                { label: <span title="Is this page link internal or external">Is External</span>, field: 'isExternal', sort: 'asc' }
            ],
            rows: tableRows
        };

        let pageCreationBtns;
        if (this.state.loggedInUserCMSRole.includes("Admin")) {
            pageCreationBtns = <>
                <a href='/CreatePage' title="Create a new internal web page"><button className='form-management-button'>Create Internal Page</button></a>
                <a href='/CreateExternalPage' title="Create a new external web page"><button className='form-management-button'>Create External Page</button></a>
            </>;
        }

        return (
            <div className='page-component-management-container'>
                <div className='pages-container'>
                    <h1 className='page-title'>Page Component Management</h1>
                    
                    <div className='table-container'>
                        {pageCreationBtns}
                        <button onClick={this.getComponentData.bind(this)} className='form-management-button' title="Get the original page sort">Reset Sort</button>

                        
                        <div className='checkbox-wrapper table-opts-right'>
                            <span className='checkbox-title'>Include Deleted: </span>
                            <input className='checkbox-filter' name='filterDeletedPages' type='checkbox' checked={this.state.includeDeletedPages} onChange={this.filterDeletedPages} />
                        </div>

                        <MDBDataTable entries={50} data={table} />
                    </div>
                </div>
            </div>
        );
    }

    async getComponentData() {
        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);
        const response = await fetch('Admin/GetPagesAndComponentData?userId=' + loggedInUser.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        const loggedInUserCMSRole = await getRolesByUserId(loggedInUser.id);

        this.setState({
            allPageComponentDatas: data,
            filteredPageComponentDatas: data,
            loggedInUser: loggedInUser,
            loggedInUserCMSRole: loggedInUserCMSRole
        });
    }
}