import React from 'react';

function Mailchimp() {
    return (
        <div id="mc_embed_signup">
            <form action="https://cwwga.us18.list-manage.com/subscribe/post?u=ddd5950bb17aef66d773d0429&amp;id=5b27e33b1e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
                    <input type="email" name="EMAIL" alt="email input" className="email" id="mce-EMAIL" placeholder="email@email.com" required />
                    <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_ddd5950bb17aef66d773d0429_5b27e33b1e" tabindex="-1" value=""/></div>
                    <div className="clear submit-btn-wrapper"><input type="submit" value="Sign Up" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                </div>
            </form>
        </div>
    );       
};

export default Mailchimp;
