import React, { Component } from 'react';
import '../settings.css';
import TagsByInput from '../../TagsAndChips/TagsByInput';
import ImageGalleryField from '../../../ImageGalleryField';

export class EventSettings extends Component {
    static displayName = EventSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            eventConfiguration: null,
            removedTags: []
        };
        this.onTypeTagRemoval = this.onTypeTagRemoval.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newEventConfiguration = this.state.eventConfiguration;
        newEventConfiguration[e.target.name] = e.target.value;
        this.setState({ eventConfiguration: newEventConfiguration });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let eventConfiguration = this.state.eventConfiguration;

        fetch('AdminSettings/UpdateEventSettingsData', {
            method: 'POST',
            body: JSON.stringify(eventConfiguration),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (data === "Saved") {
                    if (this.state.removedTags.length > 0) {
                        let removedTags = this.state.removedTags;
                        for (var i = 0; i < removedTags.length; i++) {
                            let tag = removedTags[i];
                            fetch('AdminEvent/RemoveEventTypeReference?typeToRemove=' + tag, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                }
                            });
                        }
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        )
    }

    async onTypeTagRemoval(tag) {
        let removedTags = this.state.removedTags;
        removedTags.push(tag);
        this.setState({ removedTags: removedTags });
    }

    render() {
        let types;
        let defaultImage;

        let isEventConfigurationSet = false;
        if (this.state.eventConfiguration != null) {
            let eventConfiguration = this.state.eventConfiguration;
            types = eventConfiguration.types;
            defaultImage = eventConfiguration.defaultImage;

            isEventConfigurationSet = true;
        }

        let typesInput = isEventConfigurationSet ?
            <TagsByInput
                tags={types}
                tagUpdate={this.handleChange}
                name='types'
                isRequired={true}
                allowDuplicates={false}
                inputType='text'
                onTagRemoval={this.onTypeTagRemoval}
            /> : "";

        return (
            <div className='admin-settings--form'>
                <form onSubmit={this.handleSubmit}>
                    <div className='grid-wrapper'>
                        <div className='dual-grid--offset'>
                            <div className='dual-grid__1-2 form-title'>
                                <h3>Event</h3>
                            </div>

                            <label>Default Image: </label>
                            <input name='defaultImage' type='text' defaultValue={defaultImage} onChange={this.handleChange} required hidden />
                            <ImageGalleryField name='defaultImage' currentImageUrl={defaultImage} handleImageChange={this.handleChange} />

                            <label>Types: </label>
                            {typesInput}

                            <div className='dual-grid__1-2'>
                                <button type='submit' className='form-button'>Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('AdminSettings/GetEventSettingsData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            eventConfiguration: data
        });
    }
}

export default EventSettings;
