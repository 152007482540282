import React, { Component } from 'react';
import adminSettingsService from '../Admin/AdminSettingsService';
import './menuitems.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

export class MenuItems extends Component {
    static displayName = MenuItems.name;

    constructor(props) {
        super(props);
        this.state = {
            menuItems: null,
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let menuItems;

        if (this.state.menuItems != null) {
            var menuItemArray = [];
            this.state.menuItems.forEach((e, i) => {
                let page = e.page;
                let subMenuPages = e.subMenuPages;

                if (page.status == "Deleted") {
                    return;
                }

                let subPages = [];
                subMenuPages.forEach((subPage, j) => {
                    if (subPage.status == "Deleted") {
                        return;
                    }
                    if (subPage.isExternal) {
                        let openType = subPage.openType == "NewTab" ? "_blank" : "_self";
                        subPages.push(<a key={j} target={openType} href={subPage.friendlyUrl}>{subPage.name}</a>);
                    }
                    else {
                        subPages.push(<a key={j} href={page.friendlyUrl + "/" + subPage.friendlyUrl}>{subPage.name}</a>);
                    }
                })

                let menuItem;
                if (subPages.length > 0) {
                    if (page.allowNavigation) {
                        menuItem = <div className='dropdown' key={i}>
                            <button className='dropdown-btn direct-dropdown-link'>
                                <a href={page.friendlyUrl}>{page.name}</a>
                                <FontAwesomeIcon icon={faCaretRight} className='right-caret-icon' />
                            </button>
                            <div className='dropdown-content'>
                                {subPages}
                            </div>
                        </div>;
                    }
                    else {
                        menuItem = <div className='dropdown' key={i}>
                            <button className='dropdown-btn'>
                                {page.name}
                                <FontAwesomeIcon icon={faCaretRight} className='right-caret-icon' />
                            </button>
                            <div className='dropdown-content'>
                                {subPages}
                            </div>
                        </div>;
                    }
                }
                else {
                    if (page.isExternal) {
                        let openType = page.openType == "NewTab" ? "_blank" : "_self";
                        menuItem = <a className='direct-link' target={openType} key={i} href={page.friendlyUrl}><li key={i}>{page.name}</li></a>;
                    }
                    else {
                        menuItem = <a className='direct-link' key={i} href={page.friendlyUrl}><li key={i}>{page.name}</li></a>;
                    }
                }
                menuItemArray.push(menuItem);

                //Add bar
                if (this.state.menuItems.length > (i + 1)) {
                    menuItemArray.push(<span className='nav-divider'>|</span>);
                }
            })

            menuItems = menuItemArray;
        }

        return (
            <ul className='menu-items'>
                {menuItems}
            </ul>
        );
    }

    async getComponentData() {
        const menuResponse = await fetch('components/GetMenuItems', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const menuData = await menuResponse.json();
        //console.log(menuData);

        this.setState({
            menuItems: menuData
        });
    }
}