import React, { Component } from 'react';
import { HtmlEditor } from '../HtmlEditor/HtmlEditor';

export class Grid5050 extends Component {
    static displayName = Grid5050.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            grid5050: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let htmlEditor;
        let imageLink;
        if (this.state.grid5050 != null) {
            htmlEditor = <HtmlEditor componentId={this.state.grid5050.htmlEditorId} />;
            imageLink = this.state.grid5050.imageLink;
        }

        return (
            <div className="grid-half features2 grid5050">
                <div className="image-full-bg">
                    <img src={imageLink} className="grid5050-image" />
                </div>
                <div className="featured-wrapper">
                    <div className="flex-single">
                        {htmlEditor}
                    </div>
                </div>
            </div>

            //<div className="grid-half features2">
            //    <div className="image-full-bg">
            //    </div>
            //    <div className="featured-wrapper">
            //        <h1 className="lightweight text-right tsdark">{title}<hr /></h1>
            //        <div className="flex-single">
            //            {itemList}
            //        </div>
            //    </div>
            //</div>
        );
    }

    async getComponentData() {
        const response = await fetch('Components/GetGrid5050?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                grid5050: data
            });
        }
    }
}