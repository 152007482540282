import React, { Component } from 'react';
import './slideshow.css';

export class Slideshow extends Component {
    static displayName = Slideshow.name;

    constructor(props) {
        super(props);
        this.state = {
            currentSlideIndex: 0,
            imageArray: (this.props.imageArray != undefined && this.props.imageArray != null) ? this.props.imageArray : [],
            insideArray: (this.props.insideArray != undefined && this.props.insideArray != null) ? this.props.insideArray : [],
            //sliderDuration: (this.props.sliderDuration != undefined && this.props.sliderDuration != null && !isNaN(this.props.sliderDuration)) ? this.props.sliderDuration : 5000,
            currentInside: null,
            slideTimeArray: (this.props.slideTimeArray != undefined && this.props.slideTimeArray != null) ? this.props.slideTimeArray : []
        };
    }

    handleImageLoad() {
        let insideArray = this.state.insideArray;
        let currentDuration = this.state.slideTimeArray[this.state.currentSlideIndex];
        if (insideArray.length >= this.state.currentSlideIndex) {
            this.updateCurrentSlide(currentDuration);
            this.setState({
                currentInside: insideArray[this.state.currentSlideIndex]
            });
        }
    }

    updateCurrentSlide(slideDuration) {
        let currentSlideIndex = (this.state.imageArray.length == (this.state.currentSlideIndex + 1)) ? 0 : (this.state.currentSlideIndex + 1);
        //let slideDuration = this.state.sliderDuration;
        //let slideDuration = this.state.slideTimeArray[this.state.currentSlideIndex];
        //console.log(slideDuration);
        setTimeout(
            function () {
                this.setState({
                    currentSlideIndex: currentSlideIndex
                });
            }.bind(this),
            slideDuration
        );
    }

    render() {
        let image = this.state.imageArray[this.state.currentSlideIndex];
        
        let currentInside = this.state.currentInside;
        //this.updateCurrentSlide(currentDuration);
        return (
            <>
                <div className="banner-bg">
                    <img src={image} onLoad={this.handleImageLoad.bind(this)} />
                </div>

                {currentInside}
            </>
        );
    }
}

export default Slideshow;
