import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export class HtmlEditorField extends Component {
    static displayName = HtmlEditorField.name;

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            images: null,
            buttonModal: false,
            button: {
                buttonText: null,
                buttonLink: null
            }
        };
        this.toggle = this.toggle.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.loadImages = this.loadImages.bind(this);
        this.chooseImage = this.chooseImage.bind(this);

        this.toggleButtonForm = this.toggleButtonForm.bind(this);
        this.createButton = this.createButton.bind(this);
        this.handleButtonChange = this.handleButtonChange.bind(this);
    }

    componentDidMount() {
        this.loadImages();
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleButtonForm() {
        let newButton = this.state.button;
        newButton["buttonText"] = null;
        newButton["buttonLink"] = null;

        this.setState({
            button: newButton,
            buttonModal: !this.state.buttonModal
        });
    }

    handleButtonChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        let newButton = this.state.button;
        newButton[name] = value;
        this.setState({ button: newButton });
    }

    createButton(e) {
        let button = this.state.button;

        if (button.buttonText != null && button.buttonText != "" && button.buttonLink != null && button.buttonLink != "") {
            window.tinymce.activeEditor.execCommand('mceInsertContent', false, "<a href='" + button.buttonLink + "'><button style='padding:10px 20px'>" + button.buttonText + "</button></a>");
        }
        else {
            alert("All fields must be filled out to create a button");
        }

        this.toggleButtonForm();
    }

    async handleSearchChange(e) {
        await this.setState({
            search: e.target.value
        });

        if (this.state.search != null) {
            if (this.state.search.length > 2) {
                this.setState({
                    images: this.state.images.filter(u => u.imageDescription.toLowerCase().includes(this.state.search.toLowerCase()))
                });
            }
            else if (this.state.search == 0) {
                this.loadImages();
            }
        }
    }

    async loadImages() {
        var response = await fetch('ImageUpload/RetrieveAll', {
            method: 'POST'
        });

        var images = await response.json();

        this.setState({
            images: images
        });
    }

    chooseImage(e) {
        var protocol = window.location.protocol;
        var hostname = window.location.hostname;
        var port = window.location.port !== '' ? ":" + window.location.port : ''
        var baseURL = protocol + "//" + hostname + port + "/";
        var image = e.target;
        var altText = e.target.getAttribute('data-description');
        var url = image.getAttribute('src');
        window.tinymce.activeEditor.execCommand('mceInsertContent', false, "<img style='margin:10px; border: 2px solid white;' alt='" + altText + "' src='" + baseURL + url + "'/>");
        this.toggle();
    }

    handleChange = (content, editor) => {
        let responseContent = content;

        if (this.props.name != undefined && this.props.returnAsEvent) { //Create fake event
            let nameValPair = { name: this.props.name, value: content };
            responseContent = { target: nameValPair };
        }

        this.props.handleEditorChange(responseContent);
    }

    render() {
        let content = this.props.content;

        let imageGallery;
        let search;
        if (this.state.images != null) {
            search = <Input type="text" value={this.state.search} onChange={this.handleSearchChange} Placeholder="Enter 3 or more characters to search images.." />;
            imageGallery = this.state.images.map(image => {
                var imgSrc = "images/" + image.imageUrl;
                return <div id="imageContainer" ><div id="imageContainerTop"><img style={{ maxWidth: "100%", maxHeight: "150px" }} data-description={image.imageDescription} src={imgSrc} onClick={this.chooseImage} /></div><div id="imageContainerDescription">{image.imageDescription}</div></div>;
            });
        }

        const modalState = this.state.modal;
        const toggle = () => { this.toggle() }
        function toggleGallery() {
            { toggle() }
        }

        let buttonText = this.state.button.buttonText;
        let buttonLink = this.state.button.buttonLink;

        const buttonModal = this.state.buttonModal;
        const toggleButtonForm = () => { this.toggleButtonForm() }
        function toggleButton() {
            { toggleButtonForm() }
        }

        return (
            <div>
                <Editor
                    apiKey='8xl6y8osi00yu2trkucfo3itp7jixxiimvk7f8quz69h8etg'
                    //initialValue={content}
                    value={content}
                    init={{
                        height: 500,
                        menubar: false,
                        fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt",
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'media',
                            'code',
                            'hr'
                        ],
                        toolbar:
                            'undo redo | \
                            fontselect | \
                            fontsizeselect | \
                            formatselect | \
                            bold italic underline strikethrough | \
                            subscript superscript | \
                            forecolor backcolor | \
                            alignleft aligncenter alignright alignjustify lineHeight | \
                            bullist media hr | \
                            code | \
                            removeformat | \
                            customImageGallery | \
                            customButton',
                        setup: function (editor) {
                            //editor.on('init', function (e) {
                            //    editor.setContent(content);
                            //});

                            editor.ui.registry.addButton('customImageGallery', {
                                icon: 'gallery',
                                onAction: () => { toggleGallery() }
                            });

                            editor.ui.registry.addButton('customButton', {
                                icon: 'template',
                                onAction: () => { toggleButton() }
                            });
                        },
                    }}
                    onEditorChange={this.handleChange}
                />

                <Modal style={{ maxWidth: "900px" }} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Image Gallery</ModalHeader>
                    <ModalBody>
                        {search}
                        {imageGallery}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal style={{ maxWidth: "900px" }} isOpen={this.state.buttonModal} toggle={this.toggleButtonForm} className={this.props.className}>
                    <ModalHeader toggle={this.toggleButtonForm}>Button</ModalHeader>
                    <ModalBody>
                        <input name='buttonText' value={buttonText} type='text' style={{ marginBottom: "5px" }} placeholder='Text' onChange={ this.handleButtonChange } />
                        <input name='buttonLink' value={buttonLink} type='text' style={{ marginBottom: "5px" }} placeholder='Link' onChange={this.handleButtonChange} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.createButton}>Create</Button>
                        <Button color='secondary' onClick={this.toggleButtonForm}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default HtmlEditorField;
