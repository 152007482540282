import React, { Component } from 'react';
import './formfieldform.css';
import TagsByInput from '../TagsAndChips/TagsByInput';

export class FormFieldForm extends Component {
    static displayName = FormFieldForm.name;

    constructor(props) {
        super(props);
        this.state = {
            formField: null,
            form: null,
            formType: null
        }
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newFormField = this.state.formField;
        let name = e.target.name;
        let value = e.target.value;

        //Update current field
        if (name == 'isRequired') {
            newFormField[name] = e.target.checked ? true : false;
        }
        else if (name == 'minLength' || name == 'maxLength') {
            newFormField[name] = (value != null && value != undefined && value != "") ? parseInt(value) : 0;
        }
        else {
            newFormField[name] = value;
        }

        //Update dependant fields
        if (name == 'fieldType') {
            if (value != 'input') {
                newFormField.inputType = null;
            }

            if (value != 'selectList') {
                newFormField.options = null;
            }

            if (value != 'input' && value != 'selectList' && value != 'email' && value != 'state') {
                newFormField.placeholder = null;
            }

            if (value != 'input' && value != 'selectList' && value != 'email' && value != 'phone' && value != 'state' && value != 'textarea' && value != 'fileUpload' && value != 'futureDatePicker') {
                newFormField.isRequired = false;
            }

            if (value != 'input') {
                newFormField.minLength = 0;
                newFormField.maxLength = 0;
            }
        }
       
        this.setState({ formField: newFormField });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let formField = this.state.formField;
        if (this.state.form != null) {
            //Update values for db
            formField.formId = this.state.form.id;

            let url = this.state.formType == "Update" ? "UpdateFormField" : "CreateFormField";
            fetch('AdminForms/' + url, {
                method: 'POST',
                body: JSON.stringify(formField),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (!isNaN(data)) {
                        this.props.onFormFieldSaved();
                    }
                    else {
                        alert(data);
                    }
                }
            );
        }
        else {
            alert("Can not save without form.");
            window.location = '/FormBuilderManagement';
        }
    }

    render() {
        let fieldType;
        let inputType;
        let order;
        let name;
        let placeholder;
        let isRequired;
        let minLength;
        let maxLength;
        let options;

        let isFormFieldSet = false;
        if (this.state.formField != null) {
            let formField = this.state.formField;

            fieldType = formField.fieldType;
            inputType = formField.inputType;
            order = formField.order;
            name = formField.name;
            placeholder = formField.placeholder;
            isRequired = formField.isRequired;
            minLength = formField.minLength;
            maxLength = formField.maxLength;
            options = formField.options;

            isFormFieldSet = true;
        }

        let fieldTypeField = <>
            <label>Field Type: </label>
            <select name='fieldType' value={fieldType} onChange={this.handleChange} required >
                <option value=''>--Select--</option>
                <option value='input'>Input</option>
                <option value='textarea'>Text Area</option>
                <option value='selectList'>Select List</option>
                <option value='checkbox'>Checkbox</option>
                <option value='phone'>Phone</option>
                <option value='email'>Email</option>
                <option value='state'>State</option>
                <option value='fileUpload'>File Upload</option>
                <option value='futureDatePicker'>Future Date Picker</option>
            </select>
        </>;

        let inputTypeField = (fieldType == 'input') ?
            <>
                <label>Input Type: </label>
                <select name='inputType' value={inputType} onChange={this.handleChange} required >
                    <option value=''>--Select--</option>
                    <option value='text'>Text</option>
                    <option value='number'>Number</option>
                </select>
            </> : "";

        let nameField = <>
            <label>Name: </label>
            <input name='name' type='text' defaultValue={name} onChange={this.handleChange} required />
        </>;

        let placeholderField = (fieldType == 'input' || fieldType == 'selectList' || fieldType == 'email' || fieldType == 'state') ?
            <>
                <label>Placeholder: </label>
                <input name='placeholder' type='text' defaultValue={placeholder} onChange={this.handleChange} />
            </> : "";

        let isRequiredField = (fieldType == 'input' || fieldType == 'selectList' || fieldType == 'phone' || fieldType == 'email' || fieldType == 'state' || fieldType == 'textarea' || fieldType == 'fileUpload' || fieldType == 'futureDatePicker') ?
            <>
                <label>Is Required: </label>
                <div className='checkbox-wrapper'>
                    <input name='isRequired' type='checkbox' checked={isRequired} onChange={this.handleChange} />
                </div>
            </> : false;

        let minLengthField = (fieldType == 'input') ?
            <>
                <label>Min {inputType == 'number' ? 'Value' : 'Length'}: </label>
                <input name='minLength' type='number' defaultValue={minLength} onChange={this.handleChange} min='0' max={inputType == 'number' ? '99999999' : '1000'} step='1'/>
            </> : "";

        let maxLengthField = (fieldType == 'input') ?
            <>
                <label>Max {inputType == 'number' ? 'Value' : 'Length'}: </label>
                <input name='maxLength' type='number' defaultValue={maxLength} onChange={this.handleChange} min='0' max={inputType == 'number' ? '99999999' : '1000'} step='1'/>
            </> : "";

        let optionsField = (isFormFieldSet && fieldType == 'selectList') ?
            <>
                <label>Options: </label>
                {/*<input name='options' type='text' defaultValue={options} onChange={this.handleChange} required />*/}
                <TagsByInput
                    tags={options}
                    tagUpdate={this.handleChange}
                    name='options'
                    isRequired={true}
                    allowDuplicates={false}
                    inputType='text'
                />
            </> : "";

        return (
            <div className='form-field-form-container'>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='dual-grid-offset'>
                        <div className='dual-grid__1-2 form-title'>
                            <h3>{name} Field</h3>
                        </div>

                        {nameField}

                        {fieldTypeField}

                        {inputTypeField}

                        {placeholderField}

                        {isRequiredField}

                        {minLengthField}

                        {maxLengthField}

                        {optionsField}

                        <div className='dual-grid__1-2 form-submit'>
                            <button type='submit' className='form-button'>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let formId = this.props.formId != undefined ? this.props.formId : 0;
        let formFieldId = this.props.formFieldId != undefined ? this.props.formFieldId : 0;
        let formType = this.props.formType != undefined ? this.props.formType : 'Create';

        //Get form field data
        const formFieldResponse = await fetch('AdminForms/GetFormField?formFieldId=' + formFieldId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const formFieldData = await formFieldResponse.json();

        //Get form data
        const formResponse = await fetch('AdminForms/GetForm?formId=' + formId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const formData = await formResponse.json();

        this.setState({
            formField: formFieldData,
            form: formData,
            formType: formType
        });
    }
}

export default FormFieldForm;
