import React, { Component } from 'react';
import '../settings.css';
import TagsByInput from '../../TagsAndChips/TagsByInput';

export class DocumentSettings extends Component {
    static displayName = DocumentSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            documentConfigurations: null,
            removedTags: []
        };
        this.onTypeTagRemoval = this.onTypeTagRemoval.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newDocumentConfigurations = this.state.documentConfigurations;
        newDocumentConfigurations[e.target.name] = e.target.value;
        this.setState({ documentConfigurations: newDocumentConfigurations });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let documentConfigurations = this.state.documentConfigurations;

        fetch('AdminSettings/UpdateDocumentSettingsData', {
            method: 'POST',
            body: JSON.stringify(documentConfigurations),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (data === "Saved") {
                    if (this.state.removedTags.length > 0) {
                        let removedTags = this.state.removedTags;
                        for (var i = 0; i < removedTags.length; i++) {
                            let tag = removedTags[i];
                            fetch('AdminDocument/RemoveDocumentTypeReference?typeToRemove=' + tag, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                }
                            });
                        }
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        )
    }

    async onTypeTagRemoval(tag) {
        let removedTags = this.state.removedTags;
        removedTags.push(tag);
        this.setState({ removedTags: removedTags });
    }

    render() {
        let types;

        let isDocumentConfigurationsSet = false;
        if (this.state.documentConfigurations != null) {
            let documentConfigurations = this.state.documentConfigurations;
            types = documentConfigurations.types;

            isDocumentConfigurationsSet = true;
        }

        let typesInput = isDocumentConfigurationsSet ?
            <TagsByInput
                tags={types}
                tagUpdate={this.handleChange}
                name='types'
                isRequired={true}
                allowDuplicates={false}
                inputType='text'
                onTagRemoval={this.onTypeTagRemoval}
            /> : "";

        return (
            <div className='admin-settings--form'>
                <form onSubmit={this.handleSubmit}>
                    <div className='grid-wrapper'>
                        <div className='dual-grid--offset'>
                            <div className='dual-grid__1-2 form-title'>
                                <h3>Document</h3>
                            </div>

                            <label>Types: </label>
                            {typesInput}

                            <div className='dual-grid__1-2'>
                                <button type='submit' className='form-button'>Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>  
        );
    }

    async getComponentData() {
        const response = await fetch('AdminSettings/GetDocumentSettingsData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            documentConfigurations: data
        });
    }
}

export default DocumentSettings;
