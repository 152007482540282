import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

export class SubNavBar extends Component {
    static displayName = SubNavBar.name;

    constructor(props) {
        super(props);
        this.state = {
            subMenuItems: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let menuItems;
        
        if (this.state.subMenuItems != null) {
            var menuItemArray = [];
            this.state.subMenuItems.forEach((e, i) => {
                let page = e.page;
                let subMenuPages = e.subMenuPages;

                //console.log(page);
                if (page.page.status == "Deleted") {
                    return;
                }

                let subPages = [];
                subMenuPages.forEach((subPage, j) => {
                    if (subPage.page.status == "Deleted") {
                        return;
                    }

                    if (subPage.page.isExternal) {
                        let openType = subPage.page.openType == "NewTab" ? "_blank" : "_self";
                        subPages.push(<a key={j} target={openType} href={subPage.page.friendlyUrl}>{subPage.page.name}</a>);
                    }
                    else {
                        subPages.push(<a key={j} href={subPage.fullUrl}>{subPage.page.name}</a>);
                    }
                })

                let menuItem;
                if (subPages.length > 0) {
                    if (page.page.allowNavigation) {
                        menuItem = <div className='dropdown' key={i}>
                            <button className='dropdown-btn direct-dropdown-link'>
                                <a href={page.fullUrl}>{page.page.name}</a>
                                <FontAwesomeIcon icon={faCaretRight} className='right-caret-icon' />
                            </button>
                            <div className='dropdown-content'>
                                {subPages}
                            </div>
                        </div>;
                    }
                    else {
                        menuItem = <div className='dropdown' key={i}>
                            <button className='dropdown-btn'>
                                {page.page.name}
                                <FontAwesomeIcon icon={faCaretRight} className='right-caret-icon' />
                            </button>
                            <div className='dropdown-content'>
                                {subPages}
                            </div>
                        </div>;
                    }
                }
                else {
                    if (page.page.isExternal) {
                        let openType = page.page.openType == "NewTab" ? "_blank" : "_self";
                        menuItem = <a className='direct-link' target={openType} key={i} href={page.page.friendlyUrl}><li key={i}>{page.page.name}</li></a>;
                    }
                    else {
                        menuItem = <a className='direct-link' key={i} href={page.fullUrl}><li key={i}>{page.page.name}</li></a>;
                    }
                }
                menuItemArray.push(menuItem);

                //Add bar
                if (this.state.subMenuItems.length > (i + 1)) {
                    menuItemArray.push(<span className='sub-nav-divider'>|</span>);
                }
            })

            menuItems = menuItemArray;
        }

        return (
            <ul className='menu-items'>
                {menuItems}
            </ul>
        );
    }

    async getComponentData() {
        let pageId = this.props.pageId;

        if (pageId != undefined) {
            const response = await fetch('components/GetSubNavbar?pageId=' + pageId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            this.setState({
                subMenuItems: data
            });
        }
    }
}

export default SubNavBar;
