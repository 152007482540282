import React, { Component } from 'react';
import './newsform.css';
import { getLoggedInUser } from "../../Authentication";
import TagsBySelection from '../TagsAndChips/TagsBySelection';
import TagsByInput from '../TagsAndChips/TagsByInput';
import { post } from 'axios';
import ImageGalleryField from '../../ImageGalleryField';

export class NewsForm extends Component {
    static displayName = NewsForm.name;

    constructor(props) {
        super(props);
        this.state = {
            news: null,
            newsConfiguration: null,
            file: '',
            loggedInUser: null,
            formType: this.props.formType != undefined ? this.props.formType : 'Create'
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    setFile(e) {
        this.setState({ file: e.target.files[0] });
    }

    handleChange = (e) => {
        let newNews = this.state.news;
        newNews[e.target.name] = e.target.value;
        this.setState({ news: newNews });
    }

    async handleSubmit(e) {
        e.preventDefault();

        let news = this.state.news;

        if (this.state.formType == 'Create') {
            //Save file to public folder
            const url = `UploadFiles/UploadFile`;
            const formData = new FormData();
            formData.append('file', this.state.file);
            formData.append('folderName', "News");
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let response = await post(url, formData, config);

            let docLocation = response.data;//Get save location of doc
            news.location = docLocation;

            news.uploader = this.state.loggedInUser.email;
            news.timestamp = new Date();
        }

        let url = this.state.formType == "Update" ? "UpdateNews" : "CreateNews";
        fetch('AdminNews/' + url, {
            method: 'POST',
            body: JSON.stringify(news),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (this.state.formType == "Update") {
                        alert("News Updated");
                    }
                    else {
                        window.location = '/NewsManagement';
                    }
                }
                else {
                    alert(data);
                }
            }
        )
    }

    render() {
        let typeField;
        if (this.state.newsConfiguration != null) {
            let newsTypes = this.state.news != null ? this.state.news.types : "";
            let newsConfiguration = this.state.newsConfiguration;

            let types = newsTypes != "" && newsTypes != null ? newsTypes.split(',') : [];
            let typeSelections = newsConfiguration.types.split(',');

            let typesKeyVal = [];
            for (var i = 0; i < types.length; i++) {
                typesKeyVal.push({ key: types[i], value: types[i] });
            }

            let typeSelectionsKeyVal = [];
            for (var i = 0; i < typeSelections.length; i++) {
                typeSelectionsKeyVal.push({ key: typeSelections[i], value: typeSelections[i] });
            }

            typeField = <TagsBySelection
                tags={typesKeyVal}
                tagSelections={typeSelectionsKeyVal}
                name='types'
                isRequired={true}
                allowDuplicates={false}
                allowDelete={true}
                showInput={true}
                tagUpdate={this.handleChange}
            />;
        }

        let title;
        let descriptionField;
        let thumbnailImage;
        let teaser;
        if (this.state.news != null) {
            let news = this.state.news;
            title = news.title;
            thumbnailImage = news.thumbnailImage;
            teaser = news.teaser;

            descriptionField = <TagsByInput
                tags={news.description}
                tagUpdate={this.handleChange}
                name='description'
                isRequired={false}
                allowDuplicates={false}
                inputType='text'
            />;
        }

        let documentField;
        if (this.state.formType == 'Create') {
            documentField = <>
                <label>Document: </label>
                <input type="file" onChange={e => this.setFile(e)} className='input-file-field' required />
                <div></div>
            </>;
        }

        let viewDocButton;
        if (this.state.formType == 'Update') {
            if (this.state.news != null) {
                viewDocButton = <a href={this.state.news.location} target="_blank" className='view-link tri-grid__1-3'>View Document</a>
            }
        }

        return (
            <div className='news-form-container'>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='form-title'>
                        <h3>{this.state.formType} News</h3>
                    </div>

                    <div className='form-fields'>
                        <div className='tri-grid-offset'>
                            {documentField}
                            {viewDocButton}

                            <label>Title: </label>
                            <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required />
                            <div></div>

                            <label>Thumbnail Image: </label>
                            <input name='thumbnailImage' type='text' defaultValue={thumbnailImage} onChange={this.handleChange} hidden />
                            <ImageGalleryField name='thumbnailImage' currentImageUrl={thumbnailImage} handleImageChange={this.handleChange} />
                            <div></div>

                            <label>Teaser</label>
                            <input name='teaser' type='text' defaultValue={teaser} onChange={this.handleChange} required maxLength='90' />
                            <div></div>

                            <label>Types: </label>
                            {typeField}
                            <div></div>

                            <label>Description: </label>
                            {descriptionField}
                            <div></div>
                        </div>
                    </div>

                    <div className='form-submit'>
                        <button type='submit' className='form-button'>Save</button>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let newsId = this.props.newsId !== undefined ? this.props.newsId : 0;
        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        const response = await fetch('AdminNews/GetNewsData?newsId=' + newsId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            news: data.news,
            newsConfiguration: data.newsConfiguration,
            loggedInUser: loggedInUser
        });
    }
}

export default NewsForm;