import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import service from '../Services';

export class NewsTable extends Component {
    static displayName = NewsTable.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            newsTable: null,
            newsList: [],
            filteredNewsList: [],
            newsConfiguration: null,
            isInCard: this.props.isInCard != undefined ? this.props.isInCard : false,
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let newsTable = this.state.newsTable;
        let newsList = this.state.filteredNewsList;

        let tableName;

        if (newsTable != null && !newsTable.isCompact) {
            tableName = newsTable.componentName;
        }

        let tableRows = [];
        if (newsList != null && newsList.length > 0 && newsTable != null) {
            let maxEntries = newsTable.maxEntries > 0 ? newsTable.maxEntries : newsList.length;
            for (var i = 0; i < newsList.length; i++) {
                let news = newsList[i];

                // Only display the max allowed rows
                if (tableRows.length < maxEntries) {
                    let thumbnailImageSrc = ((news.thumbnailImage == null || news.thumbnailImage == "") && this.state.newsConfiguration != null) ? this.state.newsConfiguration.defaultImage : news.thumbnailImage;
                    let timestampFormat = service.formatDateTime(news.timestamp);

                    let row;
                    if (newsTable.isCompact) {
                        row = <div className='row--compact'>
                            <a href={news.location} target='_blank' style={{ textDecoration: 'none', cursor: 'pointer', color: 'black' }}>
                                <div><img src={thumbnailImageSrc} /></div>
                                <div style={{ fontWeight: 'bold', marginTop: '10px', marginBottom: '15px' }}>{news.title}</div>
                                <div>{news.teaser}</div>
                            </a>
                        </div>;
                    }
                    else {
                        row = {
                            viewNews: <a href={news.location} target='_blank' title='View'><FontAwesomeIcon icon={faEye} /></a>,
                            thumbnailImage: <img src={thumbnailImageSrc} style={{ width: '110px', height: '65px' }} />,
                            title: news.title,
                            type: news.types,
                            description: news.description
                        };
                    }
                    
                    tableRows.push(row);
                }
            }
        }

        let table;
        if (newsTable != null) {
            if (newsTable.isCompact) {
                table = <div>
                    {tableRows}
                </div>;
            }
            else {
                let tableData = {
                    columns: [
                        { label: '', field: 'viewNews' },
                        { label: '', field: 'thumbnailImage' },
                        { label: 'Title', field: 'title', sort: 'asc' },
                        { label: 'Types', field: 'type', sort: 'asc' },
                        { label: 'Description', field: 'description', sort: 'asc' }
                    ],
                    rows: tableRows
                };
                table = <div className='full-table'>
                    <MDBDataTable entries={100} data={tableData} />
                </div>;
            }
        }
        
        return (
            <div className='news-table-container'>
                <div className='table-container'>
                    <div className='table-name'>
                        {/*{tableName}*/}
                    </div>

                    {table}
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetNewsTable?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            newsTable: data.newsTable,
            newsList: data.newsList,
            filteredNewsList: data.newsList,
            newsConfiguration: data.newsConfiguration
        });
    }
}
