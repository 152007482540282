import React, { Component } from 'react';
import './formform.css';

export class FormForm extends Component {
    static displayName = FormForm.name;

    constructor(props) {
        super(props);
        this.state = {
            form: null,
            formType: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    /* Manage form object changes */
    handleChange = (e) => {
        let newForm = this.state.form;
        if (e.target.name == 'columns') {
            newForm[e.target.name] = parseInt(e.target.value);
        }
        else if (e.target.name == 'includeConfirmationEmail') {
            newForm[e.target.name] = e.target.checked ? true : false;
        }
        else {
            newForm[e.target.name] = e.target.value;
        }
        this.setState({ form: newForm });
    }

    /* Manage the submit of the form object */
    handleSubmit = (e) => {
        e.preventDefault();

        let form = this.state.form;

        if (this.state.formType == "Create") {
            form.isActive = true;
        }

        let url = this.state.formType == "Update" ? "UpdateForm" : "CreateForm";
        fetch('AdminForms/' + url, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (this.state.formType == "Update") {
                        alert("Form Updated");
                        this.props.onFormSaved();
                    }
                    else {
                        window.location = '/FormBuilderManagement';
                    }
                }
                else {
                    alert(data);
                }
            }
        )
    }

    render() {
        let name;
        let email;
        let columns;
        let successMessage;
        let includeConfirmationEmail = false;

        if (this.state.form != null) {
            let form = this.state.form;

            name = form.name;
            email = form.email;
            columns = form.columns;
            successMessage = form.successMessage;
            includeConfirmationEmail = form.includeConfirmationEmail;
        }

        let nameField = <input name='name' type='text' defaultValue={name} onChange={this.handleChange} required />;
        let emailField = <input name='email' type='email' defaultValue={email} onChange={this.handleChange} required />;

        //let columnsField = <input name='columns' type='number' defaultValue={columns} onChange={this.handleChange} min='1' required />;

        let formTypeClass = this.state.formType == 'Update' ? 'form-form-container form-form--update' : 'form-form-container';
        return (
            <div className={formTypeClass}>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div>
                        <div className='form-title'>
                            <h3>Form Details</h3>
                        </div>

                        <div className='form-fields'>
                            <div className='dual-grid-offset'>
                                <label>Form Name: </label>
                                {nameField}

                                <label>Send Email To: </label>
                                {emailField}

                                <label>Success Message: </label>
                                <input name='successMessage' type='text' defaultValue={successMessage} onChange={this.handleChange} maxLength='150' />

                                <label>Include Confirmation Email: </label>
                                <div className='checkbox-wrapper'>
                                    <input name='includeConfirmationEmail' type='checkbox' checked={includeConfirmationEmail} onChange={this.handleChange} />
                                </div>

                                {/*
                                    <label>Columns: </label >
                                    { columnsField }
                                */}
                            </div>
                        </div>

                        <div className='form-submit'>
                            <button type='submit' className='form-button'>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let formId = (this.props.formId !== undefined) ? this.props.formId : 0;
        let formType = (this.props.formType != undefined) ? this.props.formType : 'Create';

        const formResponse = await fetch('AdminForms/GetForm?formId=' + formId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const formData = await formResponse.json();

        this.setState({
            form: formData,
            formType: formType
        });
    }
}

export default FormForm;