import React, { Component } from 'react';
import { HtmlEditor } from '../HtmlEditor/HtmlEditor';
import { Calendar } from '../Calendar/Calendar';
import { BannerContainer } from '../BannerContainer/BannerContainer';
import { EventTable } from '../EventTable/EventTable';
import { BlogTable } from '../BlogTable/BlogTable';
import { NewsTable } from '../NewsTable/NewsTable';
import { BirthdayTable } from '../BirthdayTable/BirthdayTable';
import { DocumentTable } from '../DocumentTable/DocumentTable';
import covidIcon from '../images/covid-icon.svg';
import waveOverlay from '../images/wave.svg';
import caseCircle from '../images/case_circle.svg';
import fileIcon from '../images/file.svg';
import linksIcon from '../images/links.svg';
import userCircle from '../images/user_circle.svg';

export class Card extends Component {
    static displayName = Card.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            card: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let cardClasses;
        let cardTitle;
        let cardContent;
        let cardIcon;
        let cardOverlay;

        if (this.state.card != null) {
            let card = this.state.card;

            // Get card icon
            //if (card.title == "Covid-19 Information") {
            //    cardIcon = <img src={covidIcon} style={{ width: '50px', alignSelf: 'center' }} />;
            //}
            //else if (card.title == "Job Postings") {
            //    cardIcon = <img src={caseCircle} style={{ width: '50px', alignSelf: 'center' }} />;
            //}
            //else if (card.title == "Directory") {
            //    cardIcon = <img src={userCircle} style={{ width: '50px', alignSelf: 'center' }} />;
            //}

            // Get card overlay
            //if (card.componentName == "Home Announce") {
            //    cardOverlay = <div style={{color:'white'}}>
            //        <img src={waveOverlay} style={{ position: 'absolute', margin: 'auto', width: '90%', top: '55%', left: '5%' }} />
            //        <div style={{position:'absolute', bottom:'50px', left:'65px', fontSize:'x-large', fontWeight:'bold', textAlign:'left'}}>
            //            <p style={{ margin: '0', marginBottom:'-10px', fontFamily: 'open-sans, sans-serif', fontSize: '28px', fontWeight: 'normal', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>2020 EMPLOYEE TRAINING</p>
            //            <p style={{ margin: '0', fontFamily: 'open-sans, sans-serif', fontSize: '28px', fontWeight: 'normal', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>BEGINS OCTOBER 15</p>
            //        </div>
            //        <div style={{ position: 'absolute', bottom: '54px', right: '65px'}}>
            //            <a href='/'>
            //                <button style={{ backgroundColor: '#ed383f', borderRadius: '30px', color: 'white', padding: '15px', fontFamily: 'open-sans, sans-serif'}}>MORE INFO</button>
            //            </a>
            //        </div>
            //    </div>;
            //}

            // Get card style
            if (card.cardStyle == 'Default') {
                cardClasses = "card";
            }
            else if (card.cardStyle == 'Flat') {
                cardClasses = "card flat-card";
            }

            // Get title style
            if (card.titleStyle == 'Standard') {
                cardTitle = <div className='cardTitle'>{card.title}</div>
            }
            else if (card.titleStyle == 'ViewAll') {
                cardTitle = <div className='cardTitle'><div className='leftCardTitle'>{card.title}</div><a href={card.viewMoreLink} className='viewAll'>View All &gt;</a></div>
            }
            else if (card.titleStyle == 'View') {
                cardTitle = <div className='cardTitle'><div className='leftCardTitle'>{card.title}</div><a href={card.viewMoreLink} className='viewAll'>View &gt;</a></div>
            }
            else if (card.titleStyle == 'Hide') {
                cardTitle = "";
            }

            
            //if (card.contentStyle == 'ImageTitleDescription') {
            //    cardContent = card.cardContent.map((u) => <div class='itdContainer'><div className='itdImage'><img style={{ width: '50px', height: '50px' }} src={u.imageUrl} /></div><div className='itdTitle'>{u.title}</div><div className='itdTeaser'>{u.teaser}</div></div>);
            //}
            //else if (card.contentStyle == 'ImageTitleDescriptionVertical') {
            //    cardContent = card.cardContent.map((u) => <div><div><img style={{ width: '50px', height: '50px' }} src={u.imageUrl} /></div><div>{u.title}</div><div>{u.teaser}</div></div>);
            //}
            //else if (card.contentStyle == 'ImageTitleSubtitleText') {
            //    cardContent = card.cardContent.map((u) => <div class='itdContainer'><div className='itdImage'><img style={{ width: '50px', height: '50px' }} src={u.imageUrl} /></div><div className='itdTitle'>{u.title}</div><div className='itdTeaser'>{u.subtitle}</div><div style={{ float: 'right' }}>{u.rightText}</div></div>);
            //}
            //else if (card.contentStyle == 'TitleDescription') {
            //    cardContent = card.cardContent.map((u) => <div><div>{u.title}</div><div>{u.text}</div></div>);
            //}

            // Get content style
            let content = (card.contentType == "HtmlEditor") ? <HtmlEditor componentId={card.contentId} /> :
                (card.contentType == "Calendar") ? <Calendar componentId={card.contentId} /> :
                (card.contentType == "BannerContainer") ? <BannerContainer componentId={card.contentId} isInCard={true} /> :
                (card.contentType == "EventTable") ? <EventTable componentId={card.contentId} isInCard={true} /> :
                (card.contentType == "BlogTable") ? <BlogTable componentId={card.contentId} isInCard={true} /> :
                (card.contentType == "NewsTable") ? <NewsTable componentId={card.contentId} isInCard={true} /> :
                (card.contentType == "BirthdayTable") ? <BirthdayTable componentId={card.contentId} isInCard={true} /> :
                (card.contentType == "DocumentTable") ? <DocumentTable componentId={card.contentId} isInCard={true} /> : null;

            //if (card.contentStyle == 'ImageTitleDescription') {
            //    cardContent = <div class='itdContainer'>
            //        {content}
            //    </div>
            //}
            //else if (card.contentStyle == 'ImageTitleDescriptionVertical') {
            //    cardContent = <div>
            //        {content}
            //    </div>;
            //}
            //else if (card.contentStyle == 'ImageTitleSubtitleText') {
            //    cardContent = <div class='itdContainer'>
            //        {content}
            //    </div>;
            //}
            //else if (card.contentStyle == 'TitleDescription') {
            //    cardContent = <div>
            //        {content}
            //    </div>;
            //}
            if (card.contentStyle == "Warning") {
                cardContent = <div className='warning-card'>
                    {content}
                </div>;
            }
            else if (card.contentStyle == "Update") {
                cardContent = <div className='update-card'>
                    {content}
                </div>;
            }
            else {
                cardContent = <div class='itdContainer'>
                    {content}
                </div>;
            }
        }
        
        return (
            <div className={cardClasses} style={this.props.style}>
                {cardIcon}
                {cardTitle}
                {cardContent}
                {cardOverlay}
            </div>
        )
    }

    async getComponentData() {
        const response = await fetch('Components/GetCard?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                card: data
            });
        }
    }
}

export default Card;
