import React, { Component } from 'react';
import PageForm from './PageForm';
import './pageform.css';

export class UpdateExternalPage extends Component {
    static displayName = UpdateExternalPage.name;

    constructor(props) {
        super(props);
        this.state = {
            pageId: this.props.match.params.pageId
        };
    }

    render() {
        return (
            <PageForm pageId={this.state.pageId} formType='Update' isExternal={true} />
        );
    }
}
