import React, { Component } from 'react';
import DocumentUpload from './DocumentUpload';

export class UpdateDocument extends Component {
    static displayName = UpdateDocument.name;

    constructor(props) {
        super(props);
        this.state = {
            documentId: this.props.match.params.documentId
        };
    }

    render() {
        return (
            <DocumentUpload documentId={this.state.documentId} formType='Update' />  
        );
    }
}