import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import HtmlEditorField from '../../../HtmlEditorField';
import ColumnsIcon from '../../../ColumnsIcon';

export class ColumnsTabForm extends Component {
    static displayName = ColumnsTabForm.name;

    constructor(props) {
        super(props);
        this.state = {
            columnsTab: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            columnsTabContainerId: this.props.columnsTabContainerId,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newColumnsTab = this.state.columnsTab;
        newColumnsTab[e.target.name] = e.target.value;
        this.setState({ columnsTab: newColumnsTab });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let columnsTab = this.state.columnsTab;
        columnsTab.versionId = this.state.versionId;
        columnsTab.pageId = this.state.pageId;
        columnsTab.columnsTabContainerId = this.state.columnsTabContainerId;

        let url = this.state.formType == "Update" ? "UpdateColumnsTab" : "CreateColumnsTab";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(columnsTab),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        //var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        //var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "ColumnsTab", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let title;
        let format;
        let column1;
        let column2;
        let column3;
        let column4;
        let footer;
        let componentName;
        if (this.state.columnsTab != null) {
            let columnsTab = this.state.columnsTab;

            title = columnsTab.title;
            format = columnsTab.format;
            column1 = columnsTab.column1;
            column2 = columnsTab.column2;
            column3 = columnsTab.column3;
            column4 = columnsTab.column4;
            footer = columnsTab.footer;
            componentName = columnsTab.componentName;
        }

        // Display according to the format
        let singleEditorWrapperClasses = this.state.versionStatus == 'draft' ? 'editor-wrapper' : 'editor-wrapper disable-wrapper';
        let doubleEditorWrapperClasses = this.state.versionStatus == 'draft' ? 'editor-wrapper grid-span-2' : 'editor-wrapper disable-wrapper grid-span-2';
        let quadEditorWrapperClasses = this.state.versionStatus == 'draft' ? 'editor-wrapper grid-span-4' : 'editor-wrapper disable-wrapper grid-span-4';
        let htmlEditorColumnsFields;
        switch (format) {
            case "1-1-1-1":
                htmlEditorColumnsFields = <>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' returnAsEvent={true} />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column2} handleEditorChange={this.handleChange} name='column2' returnAsEvent={true} />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column3} handleEditorChange={this.handleChange} name='column3' returnAsEvent={true} />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column4} handleEditorChange={this.handleChange} name='column4' returnAsEvent={true} />
                    </div>
                </>;
                break;
            case "2-1-1":
                htmlEditorColumnsFields = <>
                    <div className={doubleEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' returnAsEvent={true} />
                    </div>
                    
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column3} handleEditorChange={this.handleChange} name='column3' returnAsEvent={true} />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column4} handleEditorChange={this.handleChange} name='column4' returnAsEvent={true} />
                    </div>
                </>;
                break;
            case "1-1-2":
                htmlEditorColumnsFields = <>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' returnAsEvent={true} />
                    </div>
                    <div className={singleEditorWrapperClasses}>
                        <HtmlEditorField content={column2} handleEditorChange={this.handleChange} name='column2' returnAsEvent={true} />
                    </div>

                    <div className={doubleEditorWrapperClasses}>
                        <HtmlEditorField content={column3} handleEditorChange={this.handleChange} name='column3' returnAsEvent={true} />
                    </div>
                </>;
                break;
            case "2-2":
                htmlEditorColumnsFields = <>
                    <div className={doubleEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' returnAsEvent={true} />
                    </div>
                    
                    <div className={doubleEditorWrapperClasses}>
                        <HtmlEditorField content={column3} handleEditorChange={this.handleChange} name='column3' returnAsEvent={true} />
                    </div>
                </>;
                break;
            case "4":
                htmlEditorColumnsFields = <>
                    <div className={quadEditorWrapperClasses}>
                        <HtmlEditorField content={column1} handleEditorChange={this.handleChange} name='column1' returnAsEvent={true} />
                    </div>
                </>;
                break;
            default:
                break;
        }

        let editorComponentWrapperClasses = this.state.versionStatus == 'draft' ? 'dual-grid__1-2 editor-wrapper' : 'dual-grid__1-2 editor-wrapper disable-wrapper';

        let htmlEditorFooterField = (format != null && format != "") ?
            <div className={editorComponentWrapperClasses}>
                <HtmlEditorField content={footer} handleEditorChange={this.handleChange} name='footer' returnAsEvent={true} />
            </div> : null;

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form id='columnsTabForm' onSubmit={this.handleSubmit.bind(this)} className={wrapperClasses}>
                <div className='tri-grid'>
                    <div className='tri-grid__1-3 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('ColumnsTab')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />
                    <div></div>

                    <label>Title:</label>
                    <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required maxLength='20' />
                    <div></div>

                    <label>Format: </label>
                    <div>
                        <div className='inner-penta-grid'>
                            <div>
                                <input type='radio' id='1-1-1-1' name='format' value='1-1-1-1' checked={format == '1-1-1-1'} onChange={this.handleChange} required />
                                <label htmlFor='1-1-1-1'><ColumnsIcon format='1-1-1-1'/></label>
                            </div>

                            <div>
                                <input type='radio' id='2-1-1' name='format' value='2-1-1' checked={format == '2-1-1'} onChange={this.handleChange} required />
                                <label htmlFor='2-1-1'><ColumnsIcon format='2-1-1' /></label>
                            </div>

                            <div>
                                <input type='radio' id='1-1-2' name='format' value='1-1-2' checked={format == '1-1-2'} onChange={this.handleChange} required />
                                <label htmlFor='1-1-2'><ColumnsIcon format='1-1-2' /></label>
                            </div>

                            <div>
                                <input type='radio' id='2-2' name='format' value='2-2' checked={format == '2-2'} onChange={this.handleChange} required />
                                <label htmlFor='2-2'><ColumnsIcon format='2-2' /></label>
                            </div>

                            <div>
                                <input type='radio' id='4' name='format' value='4' checked={format == '4'} onChange={this.handleChange} required />
                                <label htmlFor='4'><ColumnsIcon format='4' /></label>
                            </div>
                        </div>
                    </div>
                    <div></div>

                    <div className='tri-grid__1-3'>
                        <div className='inner-quad-grid'>
                            {htmlEditorColumnsFields}
                        </div>
                    </div>

                    <div className='tri-grid__1-3'>
                        {htmlEditorFooterField}
                    </div>

                    <div className='tri-grid__1-3'>
                        <button form='columnsTabForm' type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetColumnsTab?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            columnsTab: data,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteColumnsTab?componentId=' + this.state.columnsTab.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default ColumnsTabForm;