import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import TagsBySelection from '../../TagsAndChips/TagsBySelection';
import { BlogTable } from '../../../BlogTable/BlogTable';

export class BlogTableForm extends Component {
    static displayName = BlogTableForm.name;

    constructor(props) {
        super(props);
        this.state = {
            blogTable: null,
            blogConfiguration: null,
            blogs: [],
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false,
            limitEntries: false,
            resetEditorPick: false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newBlogTable = this.state.blogTable;

        // Handle direct change
        if (e.target.name == "isCompact") {
            newBlogTable[e.target.name] = e.target.checked ? true : false;
        }
        else if (e.target.name == "maxEntries") {
            newBlogTable[e.target.name] = parseInt(e.target.value);
        }
        else if (e.target.name == "featuredBlogId") {
            //newBlogTable[e.target.name] = e.target.value.isNaN ? parseInt(e.target.value);
            newBlogTable[e.target.name] = parseInt(e.target.value);
        }
        else {
            newBlogTable[e.target.name] = e.target.value;
        }

        // Handle indirect change
        if (e.target.name == "types") {
            newBlogTable["featuredBlogId"] = '';
            newBlogTable["editorPickBlogId"] = null;
            this.resetEditorPickField();
        }

        this.setState({ blogTable: newBlogTable });
    }

    handleLimitEntriesChange = (e) => {
        let limitEntries = e.target.checked ? true : false;
        if (limitEntries) {
            this.setState({
                limitEntries: limitEntries
            });
        }
        else {
            let newBlogTable = this.state.blogTable;
            newBlogTable['maxEntries'] = 0;

            this.setState({
                limitEntries: limitEntries,
                blogTable: newBlogTable
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let blogTable = this.state.blogTable;
        blogTable.versionId = this.state.versionId;
        blogTable.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateBlogTable" : "CreateBlogTable";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(blogTable),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "BlogTable", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    resetEditorPickField = () => {
        this.setState({
            resetEditorPick: !this.state.resetEditorPick
        });
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let componentName = null;
        let typesField = null;
        let isCompact = false;
        let maxEntries = 0;
        let featuredBlogId;
        let editorPickField = null;

        let limitEntries = this.state.limitEntries;

        let tableTypes = this.state.blogTable != null && this.state.blogTable.types != "" && this.state.blogTable.types != null ? this.state.blogTable.types.split(',') : [];

        if (this.state.blogTable != null && this.state.blogConfiguration != null) {
            let blogTable = this.state.blogTable;
            let blogConfiguration = this.state.blogConfiguration;

            componentName = blogTable.componentName;
            isCompact = blogTable.isCompact;
            maxEntries = blogTable.maxEntries;
            featuredBlogId = blogTable.featuredBlogId;

            // Get blog types field
            let types = blogTable.types != "" && blogTable.types != null ? blogTable.types.split(',') : [];
            let typeSelections = blogConfiguration.types != "" && blogConfiguration.types != null ? blogConfiguration.types.split(',') : [];

            let typesKeyVal = [];
            for (var i = 0; i < types.length; i++) {
                typesKeyVal.push({ key: types[i], value: types[i] });
            }
            let typeSelectionsKeyVal = [];
            for (var i = 0; i < typeSelections.length; i++) {
                typeSelectionsKeyVal.push({ key: typeSelections[i], value: typeSelections[i] });
            }

            let allowDeleteTypes = this.state.versionStatus == 'draft' ? true : false;
            typesField = <TagsBySelection
                tags={typesKeyVal}
                tagSelections={typeSelectionsKeyVal}
                name='types'
                isRequired={true}
                allowDuplicates={false}
                allowDelete={allowDeleteTypes}
                showInput={true}
                tagUpdate={this.handleChange}
            />;

            // Get blog editor's picks field
            let blogCurrents = blogTable.editorPickBlogId != "" && blogTable.editorPickBlogId != null ? blogTable.editorPickBlogId.split(',') : [];
            let blogSelections = this.state.blogs;
            
            let blogCurrentsKeyVal = [];
            for (var i = 0; i < blogCurrents.length; i++) {
                for (var j = 0; j < this.state.blogs.length; j++) {
                    let blogRef = this.state.blogs[j];
                    if (blogCurrents[i] == blogRef.id) {

                        for (var k = 0; k < tableTypes.length; k++) { //Filter out the blogs that are not part of the tables types
                            if (blogRef.types != null && blogRef.types.includes(tableTypes[k])) {
                                blogCurrentsKeyVal.push({ key: blogRef.id, value: blogRef.title });
                                break;
                            }
                        }
                    }
                }
            }
            let blogSelectionsKeyValue = [];
            for (var i = 0; i < blogSelections.length; i++) {
                for (var j = 0; j < tableTypes.length; j++) { //Filter out the blogs that are not part of the tables types
                    if (blogSelections[i].types != null && blogSelections[i].types.includes(tableTypes[j])) {
                        blogSelectionsKeyValue.push({ key: blogSelections[i].id, value: blogSelections[i].title });
                        break;
                    }
                }
            }

            let allowDeleteEditorPicks = this.state.versionStatus == 'draft' ? true : false;
            editorPickField = <TagsBySelection
                tags={blogCurrentsKeyVal}
                tagSelections={blogSelectionsKeyValue}
                name='editorPickBlogId'
                isRequired={true}
                allowDuplicates={false}
                allowDelete={allowDeleteEditorPicks}
                showInput={true}
                tagUpdate={this.handleChange}
            />;

            // Reset to editor picks field is required
            if (this.state.resetEditorPick) {
                editorPickField = "";
                this.resetEditorPickField();
            }
        }

        let maxEntriesField;
        if (this.state.limitEntries) {
            maxEntriesField = <>
                <label>Max Entries: </label>
                <input name='maxEntries' type='number' defaultValue={maxEntries} onChange={this.handleChange} min={1} step={1} />
            </>;
        }

        let featuredBlogOpts = [];
        for (var i = 0; i < this.state.blogs.length; i++) {
            let blog = this.state.blogs[i];
            for (var j = 0; j < tableTypes.length; j++) {
                let tableType = tableTypes[j];
                if (blog.types != null && blog.types.includes(tableType)) {
                    let blogOptKey = "blogOptKey" + i;
                    featuredBlogOpts.push(<option key={blogOptKey} value={blog.id}>{blog.title}</option>);
                    break;
                }
            }
        }
        let featuredBlogField = <select name='featuredBlogId' value={featuredBlogId} onChange={this.handleChange} required>
            <option key='featuredBlogSelect' value=''>--Select--</option>
            <option key='featuredBlogLatest' value='0'>Latest Blog</option>
            {featuredBlogOpts}
        </select>;

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('BlogTable')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />

                    <label>Types: </label>
                    {typesField}

                    <label>Featured Post: </label>
                    {featuredBlogField}

                    <label>Editor's Pick: </label>
                    {editorPickField}

                    {/*
                    <label>Compact View: </label>
                    <div className='checkbox-wrapper'>
                        <input name='isCompact' type='checkbox' checked={isCompact} onChange={this.handleChange} />
                    </div>

                    <label>Limit Entries: </label>
                    <div className='checkbox-wrapper'>
                        <input name='limitEntries' type='checkbox' checked={limitEntries} onChange={this.handleLimitEntriesChange} />
                    </div>

                    {maxEntriesField}
                    */}

                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetBlogTable?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        let limitEntries = data.blogTable.maxEntries > 0 ? true : false;

        let blogTable = data.blogTable;
        if (formType == "Create") {
            blogTable.featuredBlogId = "";
        }

        this.setState({
            blogTable: blogTable,
            blogConfiguration: data.blogConfiguration,
            blogs: data.blogs,
            formType: formType,
            limitEntries: limitEntries
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteBlogTable?componentId=' + this.state.blogTable.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default BlogTableForm;
