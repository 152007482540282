import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import TagsBySelection from '../../TagsAndChips/TagsBySelection';
import ComponentFormPopup from '../ComponentFormPopup';
import CardForm from '../CardForm/CardForm';
import HtmlEditorForm from '../HtmlEditorForm/HtmlEditorForm';
import CalendarForm from '../CalendarForm/CalendarForm';
import BannerContainerForm from '../BannerForm/BannerContainerForm';
import EventTableForm from '../EventTableForm/EventTableForm';
import BlogTableForm from '../BlogTableForm/BlogTableForm';
import NewsTableForm from '../NewsTableForm/NewsTableForm';
import BirthdayTableForm from '../BirthdayTableForm/BirthdayTableForm';
import DocumentTableForm from '../DocumentTableForm/DocumentTableForm';
import { getLoggedInUser } from "../../../Authentication";

export class CardContainerForm extends Component {
    static displayName = CardContainerForm.name;

    constructor(props) {
        super(props);
        this.state = {
            cardContainer: null,
            cards: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            popup: null,
            reset: false,
            loggedInUser: null
        };
        this.displayPopup = this.displayPopup.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newCardContainer = this.state.cardContainer;
        newCardContainer[e.target.name] = e.target.value;

        let shouldReset = false;
        if (e.target.name == 'containerType' || e.target.name == 'cardIds') {
            shouldReset = true;
        }

        this.setState({
            cardContainer: newCardContainer,
            reset: shouldReset
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let cardContainer = this.state.cardContainer;
        cardContainer.versionId = this.state.versionId;
        cardContainer.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateCardContainer" : "CreateCardContainer";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(cardContainer),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                    var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "CardContainer", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    resetInnerComponents() {
        this.setState({ reset: false });
    }

    displayPopup(card, popupFormType) {
        //console.log("displayPopup");
        let popupForm;

        if (popupFormType == 'detail') {
            popupForm = <CardForm
                componentId={card.id}
                formType='Update'
                pageId={this.state.pageId}
                pageComponentId={this.props.pageComponentId}
                versionStatus={this.state.versionStatus}
                versionId={this.state.versionId}
                onComponentSaved={this.onPopupComponentSaved.bind(this)}
                isEditOnly={true}
                isInPopup={true}
            />;
        }
        else { //content
            if (card.contentType == "HtmlEditor") {
                popupForm = <HtmlEditorForm
                    componentId={card.contentId}
                    formType='Update'
                    pageId={this.state.pageId}
                    pageComponentId={this.props.pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onPopupComponentSaved.bind(this)}
                    isEditOnly={true}
                    isInPopup={true}
                />;
            }
            else if (card.contentType == "Calendar") {
                popupForm = <CalendarForm
                    componentId={card.contentId}
                    formType='Update'
                    pageId={this.state.pageId}
                    pageComponentId={this.props.pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onPopupComponentSaved.bind(this)}
                    isEditOnly={true}
                    isInPopup={true}
                />;
            }
            else if (card.contentType == "BannerContainer") {
                popupForm = <BannerContainerForm
                    componentId={card.contentId}
                    formType='Update'
                    pageId={this.state.pageId}
                    pageComponentId={this.props.pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onPopupComponentSaved.bind(this)}
                    isEditOnly={true}
                    isInPopup={true}
                />;
            }
            else if (card.contentType == "EventTable") {
                popupForm = <EventTableForm
                    componentId={card.contentId}
                    formType='Update'
                    pageId={this.state.pageId}
                    pageComponentId={this.props.pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onPopupComponentSaved.bind(this)}
                    isEditOnly={true}
                    isInPopup={true}
                />;
            }
            else if (card.contentType == "BlogTable") {
                popupForm = <BlogTableForm
                    componentId={card.contentId}
                    formType='Update'
                    pageId={this.state.pageId}
                    pageComponentId={this.props.pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onPopupComponentSaved.bind(this)}
                    isEditOnly={true}
                    isInPopup={true}
                />;
            }
            else if (card.contentType == "NewsTable") {
                popupForm = <NewsTableForm
                    componentId={card.contentId}
                    formType='Update'
                    pageId={this.state.pageId}
                    pageComponentId={this.props.pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onPopupComponentSaved.bind(this)}
                    isEditOnly={true}
                    isInPopup={true}
                />;
            }
            else if (card.contentType == "BirthdayTable") {
                popupForm = <BirthdayTableForm
                    componentId={card.contentId}
                    formType='Update'
                    pageId={this.state.pageId}
                    pageComponentId={this.props.pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onPopupComponentSaved.bind(this)}
                    isEditOnly={true}
                    isInPopup={true}
                />;
            }
            else if (card.contentType == "DocumentTable") {
                popupForm = <DocumentTableForm
                    componentId={card.contentId}
                    formType='Update'
                    pageId={this.state.pageId}
                    pageComponentId={this.props.pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onPopupComponentSaved.bind(this)}
                    isEditOnly={true}
                    isInPopup={true}
                />;
            }
        }
        
        let popup = <ComponentFormPopup
            popupForm={popupForm}
            cancelClicked={this.cancelClicked.bind(this)}
        />;

        this.setState({
            popup: popup
        });
    }

    onPopupComponentSaved() { 
        //console.log("onPopupComponentSaved");
        alert("Saved");

        this.setState({
            reset: true
        });
        this.getComponentData();
    }

    cancelClicked() {
        //console.log("cancelClicked");
        this.setState({
            popup: null
        });
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        let cards = null;
        let componentName = null;
        let containerType = null;
        let loggedInUser = this.state.loggedInUser;

        let isCardContainerSet = false;
        if (this.state.cardContainer != null) {
            let cardContainer = this.state.cardContainer;
            cards = cardContainer.cardIds;
            componentName = cardContainer.componentName;
            containerType = cardContainer.containerType;

            isCardContainerSet = true;
        }

        let cardsArray = [];
        let tagSelections = [];
        if (isCardContainerSet && this.state.cards != null) {
            //Get all options
            for (var i = 0; i < this.state.cards.length; i++) {
                let card = this.state.cards[i];
                if (card.pageId == this.state.pageId) {
                    let cardName = (card.componentName != null && card.componentName != "") ? card.componentName + "-V" + card.versionId : card.id.toString() + "-V" + card.versionId;
                    tagSelections.push({ key: card.id.toString(), value: cardName });
                }
            }

            //Get current tags
            if (cards != null && cards != "" && cards != undefined) {
                let cardsIdArray = cards.split(',');
                for (var i = 0; i < cardsIdArray.length; i++) {
                    let cardId = cardsIdArray[i];
                    for (var j = 0; j < this.state.cards.length; j++) {
                        let card = this.state.cards[j];
                        if (card.id.toString() == cardId) {
                            let cardName = (card.componentName != null && card.componentName != "") ? card.componentName : card.id.toString();
                            let editCardDetailBtn = <div className='edit-card-tag-btn' onClick={this.displayPopup.bind(this, card, 'detail')}>Details</div>;
                            let editCardContentBtn = <div className='edit-card-tag-btn' onClick={this.displayPopup.bind(this, card, 'content')}>Content</div>;
                            
                            let cardBox = <div className='grid-tag-content' >
                                <div className='tag-name'>
                                    {cardName}
                                </div>
                                <div className='edit-tag-btns'>
                                    {editCardDetailBtn}
                                    {editCardContentBtn}
                                </div>
                            </div>;

                            cardsArray.push({ key: card.id.toString(), value: cardBox });
                            break;
                        }
                    }
                }
            }
        }

        let containerTypeField = (loggedInUser != null && loggedInUser.role == "Admin" && loggedInUser.company == "TracSoft") ?
            <select name='containerType' value={containerType} onChange={this.handleChange} required>
                <option value="" key="titleKey0">--Select--</option>
                <option value="Type1" key="typeKey1">Type1</option>
                <option value="Type2" key="typeKey2">Type2</option>
            </select> :
            <select name='containerType' value={containerType} onChange={this.handleChange} required disabled>
                <option value="" key="titleKey0">--Select--</option>
                <option value="Type1" key="typeKey1">Type1</option>
                <option value="Type2" key="typeKey2">Type2</option>
            </select>;

        let tagGrid = containerType == "Type1" ?
            [
                ['1', '2', '3', '6'],
                ['2', '5', '1', '6'],
                ['5', '7', '1', '4'],
                ['5', '7', '4', '6'],
                ['1', '4', '6', '8'],
                ['4', '6', '6', '8'],
                ['6', '7', '6', '8'],
                ['1', '2', '8', '10'],
                ['2', '7', '8', '10']
            ] : 
            [ //Type2
                ['1', '2', '1', '6'],
                ['2', '5', '1', '6'],
                ['5', '7', '1', '6'],
                ['1', '3', '6', '8'],
                ['3', '5', '6', '8'],
                ['5', '7', '6', '8'],
                ['1', '3', '8', '10'],
                ['3', '5', '8', '10'],
                ['5', '7', '8', '10'],
            ];
        let allowDelete = this.state.versionStatus == 'draft' ? true : false;
        let showInput = (loggedInUser != null && loggedInUser.role == "Admin" && loggedInUser.company == "TracSoft") ? true : false;
        let cardIdsField = isCardContainerSet ?
            <TagsBySelection
                tags={cardsArray}
                tagSelections={tagSelections}
                tagUpdate={this.handleChange}
                name='cardIds'
                isRequired={true}
                allowDuplicates={true}
                //allowDelete={allowDelete}
                allowDelete={false}
                tagsByGrid={tagGrid}
                showInput={showInput}
            /> : "";

        if (this.state.reset || containerType == null || containerType == "") {
            cardIdsField = "";
        }

        if (this.state.reset) {
            this.resetInnerComponents();
        }

        let wrapperClasses = (this.state.versionStatus == 'draft') ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <div className={wrapperClasses}>
                {this.state.popup}

                <form onSubmit={this.handleSubmit} >
                    <div className='dual-grid'>
                        <div className='dual-grid__1-2 form-title'>
                            <h3>{componentName} {componentMap.componentTypeDisplay('CardContainer')}</h3>
                        </div>

                        <label>Component Name: </label>
                        <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />

                        <label>Container Type: </label>
                        {containerTypeField}

                        <label>{componentMap.componentTypeDisplay('Card')} List: </label>
                        {cardIdsField}

                        <div className='dual-grid__1-2'>
                            <button type='submit'>Save</button>
                            {deleteButton}
                        </div>
                    </div>
                </form>  
            </div>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetCardContainer?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        this.setState({
            cardContainer: data.cardContainer,
            cards: data.cards,
            formType: formType,
            popup: null,
            reset: false,
            loggedInUser: loggedInUser
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteCardContainer?componentId=' + this.state.cardContainer.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default CardContainerForm;