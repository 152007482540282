import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export class ComponentFormPopup extends Component {
    static displayName = ComponentFormPopup.name;

    constructor(props) {
        super(props);
        this.state = {
            popupForm: this.props.popupForm
        };
    }

    cancelClicked() {
        //console.log("cancelClicked(popup)");
        this.props.cancelClicked();
    }

    render() {
        return (
            <div className='component-form-popup-wrapper'>
                <div className='component-form-popup-container'>
                    <div className='popup-dual-grid'>
                        <div className='popup-dual-grid__1-2'>
                            <button className='popup-cancel-btn' onClick={this.cancelClicked.bind(this)}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                        </div>

                        <div className='popup-dual-grid__1-2'>
                            {this.state.popupForm}
                        </div>
                    </div>
                </div>  
            </div>
        );
    }
}

export default ComponentFormPopup;
