import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

class EventCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.sendToEventPage = this.sendToEventPage.bind(this);
    }

    sendToEventPage(event) {
        if (event.eventUrl != null) {
            return window.location = event.eventUrl;
        }
        else {
            this.props.populateCalendarFilter(event.start);
        }
    }

    render() {
        let rawEvents = this.props.events;
        let events = [];
        let dateCounter = 1;
        let prevDateString = "";
        for (var i = 0; i < rawEvents.length; i++) {
            let event = rawEvents[i];

            let eventUrl = '/event/' + event.friendlyUrl;
            let eventTime = new Date(event.startTime.toString());

            let dateString = eventTime.getMonth().toString() + "/" + eventTime.getDate().toString() + "/" + eventTime.getFullYear().toString();
            if (dateString == prevDateString) {
                dateCounter += 1;
            }
            else {
                prevDateString = dateString;
                dateCounter = 1;
            }

            if (dateCounter <= 2) {
                let calEvent = {
                    id: i,
                    title: event.title,
                    start: eventTime,
                    end: eventTime,
                    eventUrl: eventUrl
                };
                events.push(calEvent);
            }
            else if (dateCounter == 3) {
                let viewMore = {
                    id: i,
                    title: "View All...",
                    start: eventTime,
                    end: eventTime,
                    eventUrl: null
                };
                events.push(viewMore);
            }
        }

        return (
            <div className='event-calendar-container'>
                <div style={{ height: '500pt' }}>
                    <Calendar
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        defaultDate={moment().toDate()}
                        localizer={localizer}
                        views={['month']}
                        onSelectEvent={this.sendToEventPage}
                    />
                </div>
            </div>
        );
    }
}

export default EventCalendar;
