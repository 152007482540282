import React, { Component } from 'react';
import './layoutmanagement.css';
import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';
import TagsByInput from '../TagsAndChips/TagsByInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ImageGalleryField from '../../ImageGalleryField';
import HtmlEditorField from '../../HtmlEditorField';
import NavbarTabsManagement from './NavbarTabs/NavbarTabsManagement';

export class LayoutManagement extends Component {
    static displayName = LayoutManagement.name;
    adminVersionUpdateEmails = "";

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            social: null,
            navbar: null,
            footer: null,
            //companyInformation: null
        };
    }

    async componentDidMount() {
        this.getComponentData();
    }

    handleSocialChange = (e) => {
        let newSocial = this.state.social;
        newSocial[e.target.name] = e.target.value;
        this.setState({ social: newSocial });
    }

    //handleCompanyInfoChange = (e) => {
    //    let newCompanyInfo = this.state.companyInformation;
    //    newCompanyInfo[e.target.name] = e.target.value;
    //    this.setState({ companyInformation: newCompanyInfo });
    //}

    handleNavbarChange = (e) => {
        let newNavbar = this.state.navbar;
        if (e.target.name === "showContactInfo" || e.target.name === "showLoginLink" || e.target.name === "showSiteName" || e.target.name == "showSocialIcons") {
            newNavbar[e.target.name] = e.target.checked ? true : false;
        }
        else if (e.target.name == "socialId") {
            newNavbar[e.target.name] = parseInt(e.target.value);
        }
        else {
            newNavbar[e.target.name] = e.target.value;
        }
        this.setState({ navbar: newNavbar });
    }

    handleFooterChange = (e) => {
        let newFooter = this.state.footer;
        if (e.target.name == "socialId") {
            newFooter[e.target.name] = parseInt(e.target.value);
        }
        else if (e.target.name === "showPhone" || e.target.name === "showAddress" || e.target.name == "showLogo") {
            newFooter[e.target.name] = e.target.checked ? true : false;
        }
        else {
            newFooter[e.target.name] = e.target.value;
        }
        this.setState({ footer: newFooter });
    }

    handleConfigurationChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.adminVersionUpdateEmails = value;// Temp fix, state update not working
    }

    handleFooterEditorColumn1Change = (content) => {
        let newFooter = this.state.footer;
        newFooter['contentColumn1'] = content;
        this.setState({ footer: newFooter });
    }

    handleFooterEditorColumn2Change = (content) => {
        let newFooter = this.state.footer;
        newFooter['contentColumn2'] = content;
        this.setState({ footer: newFooter });
    }

    handleFooterEditorColumn3Change = (content) => {
        let newFooter = this.state.footer;
        newFooter['contentColumn3'] = content;
        this.setState({ footer: newFooter });
    }

    handleFooterEditorColumn4Change = (content) => {
        let newFooter = this.state.footer;
        newFooter['contentColumn4'] = content;
        this.setState({ footer: newFooter });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let social = this.state.social;
        let navbar = this.state.navbar;
        let footer = this.state.footer;
        let adminVersionUpdateEmails = this.adminVersionUpdateEmails;
        //let companyInformation = this.state.companyInformation;

        //console.log(adminVersionUpdateEmails);
        fetch('Admin/UpdateLayoutComponents', {
            method: 'POST',
            body: JSON.stringify({ Social: social, Navbar: navbar, Footer: footer, AdminVersionUpdateEmails: adminVersionUpdateEmails/*, CompanyInformation: companyInformation*/ }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (data == "Saved") {
                    window.location = '/PageComponentManagement';
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        //Social
        let facebookLink = null;
        let instagramLink = null;
        let twitterLink = null;
        let youtubeLink = null;
        let linkedinLink = null;
        let pinterestLink = null;
        let tumblrLink = null;
        let blogLink = null;
        if (this.state.social != null) {
            let social = this.state.social;
            facebookLink = social.facebookLink;
            instagramLink = social.instagramLink;
            twitterLink = social.twitterLink;
            youtubeLink = social.youtubeLink;
            linkedinLink = social.linkedinLink;
            pinterestLink = social.pinterestLink;
            tumblrLink = social.tumblrLink;
            blogLink = social.blogLink;
        }

        //CompanyInformation
        //let logoUrl = null;
        //let companyName = null;
        //let telephone = null;
        //let address = null;
        //if (this.state.companyInformation != null) {
        //    let companyInfo = this.state.companyInformation;
        //    logoUrl = companyInfo.logoUrl;
        //    companyName = companyInfo.companyName;
        //    telephone = companyInfo.telephone;
        //    address = companyInfo.address;
        //}

        //Navbar
        let showContactInfo = false;
        let showLoginLink = false;
        let showSiteName = false;
        let siteName = null;
        let showSocialIcons = false;
        let logoUrl = null;
        let companyName = null;
        let telephone = null;
        let payBillLink = null;
        if (this.state.navbar != null) {
            let navbar = this.state.navbar;
            showContactInfo = navbar.showContactInfo;
            showLoginLink = navbar.showLoginLink;
            showSiteName = navbar.showSiteName;
            siteName = navbar.siteName;
            showSocialIcons = navbar.showSocialIcons;
            logoUrl = navbar.logoUrl;
            companyName = navbar.companyName;
            telephone = navbar.telephone;
            payBillLink = navbar.payBillLink;
        }

        //Footer
        let copyrightName = null;
        let showPhone = false;
        let showAddress = false;
        let showLogo = false;
        let contentColumn1 = null;
        let contentColumn2 = null;
        let contentColumn3 = null;
        let contentColumn4 = null;
        let privacyPolicyLink = null;
        if (this.state.footer != null) {
            let footer = this.state.footer;
            copyrightName = footer.copyrightName;
            showPhone = footer.showPhone;
            showAddress = footer.showAddress;
            showLogo = footer.showLogo;
            contentColumn1 = footer.contentColumn1;
            contentColumn2 = footer.contentColumn2;
            contentColumn3 = footer.contentColumn3;
            contentColumn4 = footer.contentColumn4;
            privacyPolicyLink = footer.privacyPolicyLink;
        }

        //Configurations
        let adminVersionUpdateEmails = this.adminVersionUpdateEmails;
        let adminVersionEmailsInput = this.state.isReady ?
            <TagsByInput
                tags={adminVersionUpdateEmails}
                tagUpdate={this.handleConfigurationChange}
                name='adminVersionUpdateEmails'
                isRequired={true}
                allowDuplicates={false}
                inputType='email'
            /> : "";

        return (
            <div>
                <div className='layout-management grid-wrapper'>
                    <div className='dual-grid--offset'>
                        <div className='dual-grid__1-2 form-title'>
                            <h3>Site Information</h3>
                        </div>

                        <label>Navbar Tabs: </label>
                        <NavbarTabsManagement />
                    </div>
                </div>

                <form onSubmit={this.handleSubmit} className='layout-management grid-wrapper'>
                    <div className='dual-grid--offset'>
                        <label>Facebook: </label>
                        <input name='facebookLink' type='text' defaultValue={facebookLink} onChange={this.handleSocialChange} />

                        <label>Instagram: </label>
                        <input name='instagramLink' type='text' defaultValue={instagramLink} onChange={this.handleSocialChange} />

                        <label>Twitter: </label>
                        <input name='twitterLink' type='text' defaultValue={twitterLink} onChange={this.handleSocialChange} />

                        <label>YouTube: </label>
                        <input name='youtubeLink' type='text' defaultValue={youtubeLink} onChange={this.handleSocialChange} />

                        <label>LinkedIn: </label>
                        <input name='linkedinLink' type='text' defaultValue={linkedinLink} onChange={this.handleSocialChange} />

                        <label>Pinterest: </label>
                        <input name='pinterestLink' type='text' defaultValue={pinterestLink} onChange={this.handleSocialChange} />

                        <label>Tumblr: </label>
                        <input name='tumblrLink' type='text' defaultValue={tumblrLink} onChange={this.handleSocialChange} />

                        {/*
                        <label>Blog: </label>
                        <input name='blogLink' type='text' defaultValue={blogLink} onChange={this.handleSocialChange} />
                        */}

                        {/*
                        <label>Show Social in Navbar: </label>
                        <div className='checkbox-wrapper'>
                            <input name='showSocialIcons' type='checkbox' checked={showSocialIcons} onChange={this.handleNavbarChange} />
                        </div>
                        */}

                        <label>Company Name: </label>
                        <input name='companyName' type='text' defaultValue={companyName} onChange={this.handleNavbarChange} required />

                    
                        <label>Copyright Name: </label>
                        <input name='copyrightName' type='text' defaultValue={copyrightName} onChange={this.handleFooterChange} required />
                    

                        {/*
                        <label>Site Name: </label>
                        <input name='siteName' type='text' defaultValue={siteName} onChange={this.handleNavbarChange} required />

                        <label>Show Site Name: </label>
                        <div className='checkbox-wrapper'>
                            <input name='showSiteName' type='checkbox' checked={showSiteName} onChange={this.handleNavbarChange} />
                        </div>
                        */}

                        {/*
                        <label>Show Login Link: </label>
                        <div className='checkbox-wrapper'>
                            <input name='showLoginLink' type='checkbox' checked={showLoginLink} onChange={this.handleNavbarChange} />
                        </div>
                        */}

                        {/*
                        <label>Address: </label>
                        <input name='address' type='text' defaultValue={address} onChange={this.handleCompanyInfoChange} required />
                    

                        <label>Show Address in Footer: </label>
                        <div className='checkbox-wrapper'>
                            <input name='showAddress' type='checkbox' checked={showAddress} onChange={this.handleFooterChange} />
                        </div>
                        */}

                        <label>Telephone: </label>
                        <Cleave name='telephone' options={{ phone: true, phoneRegionCode: 'US' }} value={telephone} onChange={this.handleNavbarChange} required />

                        {/*
                        <label>Show Phone in Navbar: </label>
                        <div className='checkbox-wrapper'>
                            <input name='showContactInfo' type='checkbox' checked={showContactInfo} onChange={this.handleNavbarChange} />
                        </div>
                        */}

                        {/*
                        <label>Show Phone in Footer: </label>
                        <div className='checkbox-wrapper'>
                            <input name='showPhone' type='checkbox' checked={showPhone} onChange={this.handleFooterChange} />
                        </div>
                        */}

                        <label>Logo Url: </label>
                        <input name='logoUrl' type='text' defaultValue={logoUrl} onChange={this.handleNavbarChange} hidden />
                        <ImageGalleryField name='logoUrl' currentImageUrl={logoUrl} handleImageChange={this.handleNavbarChange} />

                        {/*
                        <label>Show Logo in Footer: </label>
                        <div className='checkbox-wrapper'>
                            <input name='showLogo' type='checkbox' checked={showLogo} onChange={this.handleFooterChange} />
                        </div>
                        */}

                        <label>My Account Link: </label>
                        <input name='payBillLink' type='text' defaultValue={payBillLink} onChange={this.handleNavbarChange} required />

                        <label>Privacy Policy Link: </label>
                        <input name='privacyPolicyLink' type='text' defaultValue={privacyPolicyLink} onChange={this.handleFooterChange} />

                        <label>Custom Footer Columns: </label>
                        <div className='footer-link-grid'>
                            <HtmlEditorField content={contentColumn1} handleEditorChange={this.handleFooterEditorColumn1Change} />
                            <HtmlEditorField content={contentColumn2} handleEditorChange={this.handleFooterEditorColumn2Change} />
                            <HtmlEditorField content={contentColumn3} handleEditorChange={this.handleFooterEditorColumn3Change} />
                            {/*<HtmlEditorField content={contentColumn4} handleEditorChange={this.handleFooterEditorColumn4Change} />*/}
                        </div>

                        <label>Admin Versioning Emails: </label>
                        {adminVersionEmailsInput}

                        <div className='dual-grid__1-2'>
                            <button type='submit' className='form-button'>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('Admin/GetLayoutComponents', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.adminVersionUpdateEmails = data.adminVersionUpdateEmails;

        this.setState({
            isReady: true,
            social: data.social,
            navbar: data.navbar,
            footer: data.footer,
            //companyInformation: data.companyInformation
        });
    }
}