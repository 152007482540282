import React, { Component } from 'react';
import { HtmlEditor } from '../HtmlEditor/HtmlEditor';

export class SingleFeatures1 extends Component {
    static displayName = SingleFeatures1.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            singleFeatures1: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let htmlEditor;
        if (this.state.singleFeatures1 != null) {
            htmlEditor = <HtmlEditor componentId={this.state.singleFeatures1.htmlEditorId} />;
        }

        return (
            <div className="feature-element">
                {htmlEditor}
            </div>
        );

        //let title;
        //let itemList = [];
        //if (this.state.singleFeatures1 != null) {
        //    title = this.state.singleFeatures1.title;
        //    let rawItemList = this.state.singleFeatures1.itemList.split('/,');
        //    for (var i = 0; i < rawItemList.length; i++) {
        //        itemList.push(<li>{rawItemList[i]}</li>);
        //    }
        //}

        //return (
        //    <div className="feature-element">
        //        <h2 className="lightweight text-center tsdark feature-header">{title}</h2>
        //        <hr className="feature-hr" />
        //        <ul>
        //            {itemList}
        //        </ul>
        //    </div>
        //);
    }

    async getComponentData() {
        const response = await fetch('Components/GetSingleFeatures1?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                singleFeatures1: data
            });
        }
    }
}
