import React, { Component } from 'react';
//import './htmleditor.css';

export class HtmlEditor extends Component {
    static displayName = HtmlEditor.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            content: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let content;
        if (this.state.content != null) {
            content = this.state.content;
        }

        return (
            <div className='html-editor-wrap general-html-editor-wrapper' dangerouslySetInnerHTML={{ __html: content }}></div>
        );
    }

    async getComponentData() {
        const response = await fetch('Components/GetHtmlEditor?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                content: data.content
            });
        }
    }
}