import React, { Component } from 'react';
import './blogform.css';
import { getLoggedInUser } from '../../Authentication';
import TagsBySelection from '../TagsAndChips/TagsBySelection';
import TagsByInput from '../TagsAndChips/TagsByInput';
import HtmlEditorField from '../../HtmlEditorField';
import ImageGalleryField from '../../ImageGalleryField';

export class BlogForm extends Component {
    static displayName = BlogForm.name;

    constructor(props) {
        super(props);
        this.state = {
            blog: null,
            blogConfiguration: null,
            loggedInUser: null,
            formType: this.props.formType != undefined ? this.props.formType : 'Create'
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newBlog = this.state.blog;

        newBlog[e.target.name] = e.target.value;

        this.setState({blog: newBlog});
    }

    handleEditorChange = (content) => {
        let newBlog = this.state.blog;
        newBlog['content'] = content;
        this.setState({ blog: newBlog });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let blog = this.state.blog;

        // If no timestamp, set to today
        if (blog.timestamp != undefined && blog.timestamp.toString() == "0001-01-01T00:00:00") {
            blog.timestamp = this.getDateFormat(new Date());
        }

        if (this.state.formType == "Create") {
            blog.status = "Pending";
            blog.uploaderEmail = this.state.loggedInUser.email;
            blog.uploaderFirstName = this.state.loggedInUser.firstName;
            blog.uploaderLastName = this.state.loggedInUser.lastName;
            blog.creationTimestamp = new Date();
        }
        else { //Update
            if (this.state.loggedInUser.role == "Admin") {
                if (blog.status == "Live") {
                    blog.status = "Pending";
                }
                else {
                    blog.status = "Live";
                }
            }
        }

        let url = this.state.formType == "Update" ? "UpdateBlog" : "CreateBlog";
        fetch('AdminBlog/' + url, {
            method: 'POST',
            body: JSON.stringify(blog),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (this.state.formType == "Update") {
                        alert("Blog Updated");
                        window.location = '/UpdateBlog/' + blog.id;
                    }
                    else {
                        window.location = '/BlogManagement';
                    }
                }
                else {
                    alert(data);
                }
            }
        )
    }

    /* This should only be called by admin users and update formType */
    handleSubmitWithoutStatus = () => {
        let blog = this.state.blog;

        if (this.state.formType == "Update" && this.state.loggedInUser.role == "Admin") {
            let url = "UpdateBlog";
            fetch('AdminBlog/' + url, {
                method: 'POST',
                body: JSON.stringify(blog),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (!isNaN(data)) {
                        alert("Blog Updated");
                        window.location = '/UpdateBlog/' + blog.id;
                    }
                    else {
                        alert(data);
                    }
                }
            )
        }
    }

    /* Limit the url key input. */
    validateUrlInput = (e) => {
        if ((e.which !== 32) //Space
            && ((e.which >= 37 && e.which <= 40) //Arrow keys
                || (e.key === "Backspace")
                || (e.key === "-")
                || (e.key >= 0 && e.key <= 9)
                || (e.key >= "a" && e.key <= "z"))) {
            //allow input
        }
        else {
            e.preventDefault();
            return false;
        }
    }

    getDateFormat = (rawDate) => {
        let month = rawDate.getMonth().toString().length === 1 ? '0' + (rawDate.getMonth() + 1).toString() : rawDate.getMonth() + 1;
        let day = (rawDate.getDate()).toString().length === 1 ? '0' + (rawDate.getDate()).toString() : rawDate.getDate();
        let year = rawDate.getFullYear();

        let dateFormat = year + "-" + month + "-" + day;
        return dateFormat;
    }

    render() {
        let typesField;
        if (this.state.blogConfiguration != null) {
            let blogType = this.state.blog != null ? this.state.blog.types : "";
            let blogConfiguration = this.state.blogConfiguration;

            let types = blogType != "" && blogType != null ? blogType.split(',') : [];
            let typeSelections = blogConfiguration.types.split(',');

            let typesKeyVal = [];
            for (var i = 0; i < types.length; i++) {
                typesKeyVal.push({ key: types[i], value: types[i] });
            }

            let typeSelectionsKeyVal = [];
            for (var i = 0; i < typeSelections.length; i++) {
                typeSelectionsKeyVal.push({ key: typeSelections[i], value: typeSelections[i] });
            }

            let allowDelete = (this.state.formType == "Update" && this.state.blog != null && this.state.blog.status == "Live") ? false : true;
            if (this.state.loggedInUser != null && this.state.loggedInUser.role == "Admin") allowDelete = true;
            typesField = <TagsBySelection
                tags={typesKeyVal}
                tagSelections={typeSelectionsKeyVal}
                name='types'
                isRequired={true}
                allowDuplicates={false}
                allowDelete={allowDelete}
                showInput={true}
                tagUpdate={this.handleChange}
            />;
        }

        let title;
        let content;
        let thumbnailImage;
        let teaser;
        let friendlyUrl;
        let categoriesField;
        let timestamp;
        if (this.state.blog != null) {
            let blog = this.state.blog;

            title = blog.title;
            content = blog.content;
            thumbnailImage = blog.thumbnailImage;
            teaser = blog.teaser;
            friendlyUrl = blog.friendlyUrl;
            timestamp = blog.timestamp;

            let allowDelete = (this.state.formType == "Update" && blog.status == "Live") ? false : true;
            if (this.state.loggedInUser != null && this.state.loggedInUser.role == "Admin") allowDelete = true;
            categoriesField = <TagsByInput
                tags={blog.categories}
                tagUpdate={this.handleChange}
                name='categories'
                isRequired={false}
                allowDuplicates={false}
                inputType='text'
                allowDelete={allowDelete}
            />;

            // If no timestamp, set as today, else get the format
            if (timestamp != undefined && timestamp.toString() == "0001-01-01T00:00:00") {
                timestamp = this.getDateFormat(new Date());
            }
            else {
                timestamp = this.getDateFormat(new Date(timestamp.toString()));
            }
        }

        let isImageGalleryFieldDisabled = false;
        let submitButtonText;
        let blogContainerClasses = "blog-form-container";
        let editorWrapper;
        if (this.state.loggedInUser != null && this.state.blog != null) {
            if (this.state.formType == 'Update') {
                if (this.state.loggedInUser.role == "Admin") {
                    if (this.state.blog.status == "Live") {
                        submitButtonText = "Suspend";
                        //blogContainerClasses += " disable-form disable-form--admin";
                        //editorWrapper = "disable-editor";
                        isImageGalleryFieldDisabled = false;
                    }
                    else {
                        submitButtonText = "Publish";
                    }
                }
                else {
                    submitButtonText = "Save";
                    if (this.state.blog.status == "Live") {
                        blogContainerClasses += " disable-form";
                        editorWrapper = "disable-editor";
                        isImageGalleryFieldDisabled = true;
                    }
                }
            }
            else { //Create
                submitButtonText = "Save";
            }
        }

        let deleteButton;
        let saveWithoutStatusButton;
        let formBtnBuffer;
        if (this.state.formType == 'Update' && this.state.loggedInUser != null && this.state.loggedInUser.role == 'Admin' && this.state.blog != null) {
            deleteButton = <div onClick={this.deleteBlog.bind(this)} className='cancel-btn'>Delete</div>;
            saveWithoutStatusButton = <div onClick={this.handleSubmitWithoutStatus.bind(this)} className='just-update-btn'>Save</div>;
            formBtnBuffer = <div style={{ padding: '0 5px', display: 'inline-block' }}></div>;
        }

        let blogStatusTitle = (this.state.formType == 'Update' && this.state.blog != null) ? "(" + this.state.blog.status + ")" : "";

        return (
            <div className={blogContainerClasses}>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='form-title'>
                        <h3>{this.state.formType} Blog {blogStatusTitle}</h3>
                    </div>
                    <div style={{ position: 'absolute', top: '70px', right: '20em' }}>
                        <a href={"/PreviewBlog/" + friendlyUrl} target="_blank"><button type="button" className="view-link">Preview</button></a>
                    </div>
                    <div className='form-fields'>
                        <div className='tri-grid-offset'>
                            <label>Title: </label>
                            <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required />
                            <div></div>

                            <label>Friendly Url: </label>
                            <input name='friendlyUrl' type='text' defaultValue={friendlyUrl} onChange={this.handleChange} required maxLength='25' onKeyDown={this.validateUrlInput} />
                            <div></div>

                            <label>Timestamp: </label>
                            <input name='timestamp' type='date' defaultValue={timestamp} onChange={this.handleChange} required />
                            <div></div>

                            <label>Types: </label>
                            {typesField}
                            <div></div>

                            <label>Categories: </label>
                            {categoriesField}
                            <div></div>

                            <label>Thumbnail Image: </label>
                            <input name='thumbnailImage' type='text' defaultValue={thumbnailImage} onChange={this.handleChange} hidden />
                            <ImageGalleryField name='thumbnailImage' currentImageUrl={thumbnailImage} handleImageChange={this.handleChange} isDisabled={isImageGalleryFieldDisabled} />
                            <div></div>

                            <label>Teaser</label>
                            <input name='teaser' type='text' defaultValue={teaser} onChange={this.handleChange} required maxLength='100' />
                            <div></div>

                            <label>Content: </label>
                            <div className={editorWrapper}>
                                <HtmlEditorField content={content} handleEditorChange={this.handleEditorChange} />
                            </div>
                            <div></div>
                        </div>
                    </div>

                    <div className='form-submit'>
                        <button type='submit' className='form-button submit-btn'>{submitButtonText}</button>
                        {formBtnBuffer}
                        {saveWithoutStatusButton}
                        {formBtnBuffer}
                        {deleteButton}
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let blogId = this.props.blogId !== undefined ? this.props.blogId : 0;
        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        const response = await fetch('AdminBlog/GetBlogData?blogId=' + blogId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        let blog = data.blog;

        this.setState({
            blog: blog,
            blogConfiguration: data.blogConfiguration,
            loggedInUser: loggedInUser
        });
    }

    async deleteBlog(e) {
        e.preventDefault();

        let blogId = this.state.blog.id;
        let confirmDelete = window.confirm("Are you sure you want to delete this blog?");

        if (confirmDelete) {
            let id = parseInt(blogId);
            const response = await fetch('AdminBlog/DeleteBlog?blogId=' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                window.location = '/BlogManagement';
            }
            else {
                alert(data);
            }
        }
    }
}

export default BlogForm;

