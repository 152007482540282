import React, { Component } from 'react';
import Card from '../Card/Card';

export class CardContainer extends Component {
    static displayName = CardContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            cardContainerData: {
                cssClass: 'cardContainerFourWide'
            },
            componentId: props.componentId,
            cardContainer: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    //render() {

    //    var inTheNewsData = [
    //        {
    //            imageUrl: 'https://source.unsplash.com/random/50x50', title: 'Title', teaser: 'Perum eos etur sus. Os et pa inulparum quia idel illorerae consequat.'
    //        },
    //        {
    //            imageUrl: 'https://source.unsplash.com/random/50x50', title: 'Title', teaser: 'Perum eos etur sus. Os et pa inulparum quia idel illorerae consequat.'
    //        }
    //    ];

    //    var employeeNewsletterData = [
    //        {
    //            imageUrl: 'https://source.unsplash.com/random/50x50', title: 'Employee Name', teaser: 'Perum eos etur sus. Os et pa inulparum quia idel illorerae consequat.'
    //        }
    //    ];

    //    var birthdayData = [
    //        {
    //            imageUrl: 'https://source.unsplash.com/random/50x50', title: 'Employee Name', subtitle: 'Finance', rightText: 'November 10'
    //        }
    //    ];

    //    var events = [
    //        {
    //            title: 'THURS NOV 12, 2020 8 - 9 AM', text: 'Employee Services: 2020 Discrimination and Harassment Training'
    //        },
    //        {
    //            title: 'THURS NOV 12, 2020 11 AM-4 PM', text: 'Employee Services: OSHA REF-GEN IND 10 hr Course'
    //        }
    //    ];

    //    return (
    //        <div className={this.state.cardContainerData.cssClass}>
    //            <Card title="Announcements" titleStyle="Standard" style={{gridRowStart:'2', gridRowEnd:'5', gridColumnStart: '1', gridColumnEnd: '6'}} />
    //            <Card title="In the News" titleStyle="ViewAll" cardContentStyle="ImageTitleDescription" cardContent={inTheNewsData} style={{gridRowStart:'5',gridRowEnd:'7', gridColumnStart: '1', gridColumnEnd: '4'}}/>
    //            <Card title="Employee Newsletter" titleStyle="Standard" cardContentStyle="ImageTitleDescriptionVertical" cardContent={employeeNewsletterData} style={{ gridRowStart: '5', gridRowEnd: '7', gridColumnStart: '4', gridColumnEnd: '6' }}/>
    //            <Card title="Quick Links" titleStyle="Standard" style={{ gridRowStart: '1', gridRowEnd: '4', gridColumnStart: '6', gridColumnEnd: '8' }}/>
    //            <Card title="Upcoming Birthdays" titleStyle="ViewAll" cardContentStyle="ImageTitleSubtitleText" cardContent={birthdayData} style={{ gridRowStart: '4', gridRowEnd: '6', gridColumnStart: '6', gridColumnEnd: '8' }}/>
    //            <Card title="Covid-19 Information and Updates" titleStyle="View" style={{ gridRowStart: '6', gridRowEnd: '7', gridColumnStart: '6', gridColumnEnd: '8' }}/>
    //            <Card style={{ gridRowStart: '1', gridRowEnd: '5', gridColumnStart: '8', gridColumnEnd: '10' }}/>
    //            <Card title="Upcoming Events" titleStyle="ViewAll" cardContentStyle="TitleDescription" cardContent={events} style={{ gridRowStart: '5', gridRowEnd: '7', gridColumnStart: '8', gridColumnEnd: '10' }}/>
    //        </div>
    //    );
    //}

    render() {
        let cards = [];
        if (this.state.cardContainer != null) {
            let cardIds = this.state.cardContainer.cardIds.split(',');
            let containerType = this.state.cardContainer.containerType;

            for (var i = 0; i < cardIds.length; i++) {
                let rowStart = '';
                let rowEnd = '';
                let columnStart = '';
                let columnEnd = '';

                if (containerType == "Type1") {
                    switch (i) {
                        case 0:
                            rowStart = '1'; rowEnd = '2'; columnStart = '3'; columnEnd = '6';
                            break;
                        case 1:
                            rowStart = '2'; rowEnd = '5'; columnStart = '1'; columnEnd = '6';
                            break;
                        case 2:
                            rowStart = '5'; rowEnd = '7'; columnStart = '1'; columnEnd = '4';
                            break;
                        case 3:
                            rowStart = '5'; rowEnd = '7'; columnStart = '4'; columnEnd = '6';
                            break;
                        case 4:
                            rowStart = '1'; rowEnd = '4'; columnStart = '6'; columnEnd = '8';
                            break;
                        case 5:
                            rowStart = '4'; rowEnd = '6'; columnStart = '6'; columnEnd = '8';
                            break;
                        case 6:
                            rowStart = '6'; rowEnd = '7'; columnStart = '6'; columnEnd = '8';
                            break;
                        case 7:
                            rowStart = '1'; rowEnd = '2'; columnStart = '8'; columnEnd = '10';
                            break;
                        case 8:
                            rowStart = '2'; rowEnd = '7'; columnStart = '8'; columnEnd = '10';
                            break;
                    }
                }
                else { //Type2
                    switch (i) {
                        case 0:
                            rowStart = '1'; rowEnd = '2'; columnStart = '1'; columnEnd = '6';
                            break;
                        case 1:
                            rowStart = '2'; rowEnd = '5'; columnStart = '1'; columnEnd = '6';
                            break;
                        case 2:
                            rowStart = '5'; rowEnd = '7'; columnStart = '1'; columnEnd = '6';
                            break;
                        case 3:
                            rowStart = '1'; rowEnd = '3'; columnStart = '6'; columnEnd = '8';
                            break;
                        case 4:
                            rowStart = '3'; rowEnd = '5'; columnStart = '6'; columnEnd = '8';
                            break;
                        case 5:
                            rowStart = '5'; rowEnd = '7'; columnStart = '6'; columnEnd = '8';
                            break;
                        case 6:
                            rowStart = '1'; rowEnd = '3'; columnStart = '8'; columnEnd = '10';
                            break;
                        case 7:
                            rowStart = '3'; rowEnd = '5'; columnStart = '8'; columnEnd = '10';
                            break;
                        case 8:
                            rowStart = '5'; rowEnd = '7'; columnStart = '8'; columnEnd = '10';
                            break;
                    }
                }

                cards.push(<Card componentId={cardIds[i]} style={{ gridRowStart: rowStart, gridRowEnd: rowEnd, gridColumnStart: columnStart, gridColumnEnd: columnEnd }} />);
            }
        }

        return (
            <div className={this.state.cardContainerData.cssClass}>
                {cards}
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('Components/GetCardContainer?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                cardContainer: data
            });
        }
    }
}

export default CardContainer;
