import React, { Component } from 'react';
import { getCompanyName, getUsersByCompany, deleteUser } from '../../Authentication';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import './rolemanagement.css';

export class RoleManagement extends Component {
    static displayName = RoleManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            companyName: null,
            roles: [],
            newRole: null
        }
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        this.setState({
            newRole: e.target.value
        });
    }

    handleSubmit = (e) => {
        this.addRole();
    }

    render() {
        let companyName = this.state.companyName;
        let roles = this.state.roles;

        let tableRows = [];
        if (roles.length > 0) {
            let rolesArray = roles;
            for (var i = 0; i < rolesArray.length; i++) {
                let role = rolesArray[i];
                let row = {
                    role: role.name
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: 'Role', field: 'role', sort: 'asc' }
            ],
            rows: tableRows
        };

        return (
            <div className='user-management-container'>
                <div className='page-title'>
                    <h1>{companyName} Role Management</h1>
                </div>

                <div className='table-container'>
                    <input name='newRole' placeholder="Insert role name" style={{ width: '25%' }} type='text' onChange={this.handleChange} required /><button onClick={this.handleSubmit} className='form-management-button'>Create Role</button>

                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>
        );
    }

    async addRole() {
        if (this.state.newRole == null || this.state.newRole == "" || this.state.newRole == undefined) {
            alert("Role can not be null.");
            return;
        }

        var roles = this.state.roles;
        for (var i = 0; i < roles.length; i++) {
            if (roles[i].name == this.state.newRole) {
                alert("Role already exists.");
                return;
            }
        }
        var roleData = {
            roleName: this.state.newRole
        };
        const response = await fetch('RoleManagement/AddRole', {
            method: 'POST',
            body: JSON.stringify(roleData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        window.location.reload(false);
    }

    async getRoles() {
        const response = await fetch('RoleManagement/GetRoles', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        console.log(data);

        return data;
    }

    async getComponentData() {
        const companyName = await getCompanyName();
        const roles = await this.getRoles();

        this.setState({
            companyName: companyName,
            roles: roles
        });
    }
}

export async function getRoles() {
    const response = await fetch('RoleManagement/GetRoles', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });

    const data = await response.json();
    console.log(data);

    return data;
}

export async function getRolesByUserId(userId) {
    const response = await fetch('RoleManagement/GetRolesByUserId', {
        method: 'POST',
        body: userId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });

    const data = await response.json();
    //console.log(data);

    return data;
}

export default RoleManagement;