import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';

export class CardForm extends Component {
    static displayName = CardForm.name;

    constructor(props) {
        super(props);
        this.state = {
            card: null,
            htmlEditors: null,
            calendars: null,
            bannerContainers: null,
            eventTables: null,
            blogTables: null,
            newsTables: null,
            birthdayTables: null,
            documentTables: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newCard = this.state.card;

        //Update target
        if (e.target.name == "contentId") {
            newCard[e.target.name] = parseInt(e.target.value, 10);
        }
        else {
            newCard[e.target.name] = e.target.value;
        }

        //Update consequences
        if (e.target.name == "contentType") {
            newCard['contentId'] = null;
        }
        else if (e.target.name == "titleStyle") {
            if (e.target.value != "View" && e.target.value != "ViewAll") {
                newCard['viewMoreLink'] = null;
            }
        }

        this.setState({ card: newCard });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let card = this.state.card;
        card.versionId = this.state.versionId;
        card.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateCard" : "CreateCard";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(card),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "Card", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        let saveButton = <button type='submit'>Save</button>;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let contentOptions = [];
        
        let title = null;
        let titleStyle = null;
        let contentId = null;
        let contentType = null;
        let contentStyle = null;
        let componentName = null;
        let cardStyle = null;
        let viewMoreLink = null;

        //Set card values
        if (this.state.card != null) {
            let card = this.state.card;
            title = card.title;
            titleStyle = card.titleStyle;
            contentId = card.contentId;
            contentType = card.contentType;
            contentStyle = card.contentStyle;
            componentName = card.componentName;
            cardStyle = card.cardStyle;
            viewMoreLink = card.viewMoreLink;
        }

        //Set content options
        contentOptions.push(<option value="" key="contentOpt0">--Select--</option>);
        if (this.state.card != null) {
            if (contentType == "HtmlEditor" && this.state.htmlEditors != null) {
                for (var i = 0; i < this.state.htmlEditors.length; i++) {
                    let contentOpt = this.state.htmlEditors[i];
                    if (contentOpt.pageId == this.state.pageId) {
                        let contentOptKey = "contentOpt" + (i + 1);

                        let ddlDisplay = contentOpt.componentName != null && contentOpt.componentName != "" ? contentOpt.componentName + "-V" + contentOpt.versionId : contentOpt.content.substring(0, 100) + "-V" + contentOpt.versionId;
                        contentOptions.push(<option value={contentOpt.id} key={contentOptKey}>{ddlDisplay}</option>);
                    }
                }
            }
            else if (contentType == "Calendar" && this.state.calendars != null) {
                for (var i = 0; i < this.state.calendars.length; i++) {
                    let contentOpt = this.state.calendars[i];
                    if (contentOpt.pageId == this.state.pageId) {
                        let contentOptKey = "contentOpt" + (i + 1);

                        let ddlDisplay = contentOpt.componentName != null && contentOpt.componentName != "" ? contentOpt.componentName + "-V" + contentOpt.versionId : contentOpt.id + "-V" + contentOpt.versionId;
                        contentOptions.push(<option value={contentOpt.id} key={contentOptKey}>{ddlDisplay}</option>);
                    }
                }
            }
            else if (contentType == "BannerContainer" && this.state.bannerContainers != null) {
                for (var i = 0; i < this.state.bannerContainers.length; i++) {
                    let contentOpt = this.state.bannerContainers[i];
                    if (contentOpt.pageId == this.state.pageId) {
                        let contentOptKey = "contentOpt" + (i + 1);

                        let ddlDisplay = contentOpt.componentName != null && contentOpt.componentName != "" ? contentOpt.componentName + "-V" + contentOpt.versionId : contentOpt.id + "-V" + contentOpt.versionId;
                        contentOptions.push(<option value={contentOpt.id} key={contentOptKey}>{ddlDisplay}</option>);
                    }
                }
            }
            else if (contentType == "EventTable" && this.state.eventTables != null) {
                for (var i = 0; i < this.state.eventTables.length; i++) {
                    let contentOpt = this.state.eventTables[i];
                    if (contentOpt.pageId == this.state.pageId) {
                        let contentOptKey = "contentOpt" + (i + 1);

                        let ddlDisplay = contentOpt.componentName != null && contentOpt.componentName != "" ? contentOpt.componentName + "-V" + contentOpt.versionId : contentOpt.id + "-V" + contentOpt.versionId;
                        contentOptions.push(<option value={contentOpt.id} key={contentOptKey}>{ddlDisplay}</option>);
                    }
                }
            }
            else if (contentType == "BlogTable" && this.state.blogTables != null) {
                for (var i = 0; i < this.state.blogTables.length; i++) {
                    let contentOpt = this.state.blogTables[i];
                    if (contentOpt.pageId == this.state.pageId) {
                        let contentOptKey = "contentOpt" + (i + 1);

                        let ddlDisplay = contentOpt.componentName != null && contentOpt.componentName != "" ? contentOpt.componentName + "-V" + contentOpt.versionId : contentOpt.id + "-V" + contentOpt.versionId;
                        contentOptions.push(<option value={contentOpt.id} key={contentOptKey}>{ddlDisplay}</option>);
                    }
                }
            }
            else if (contentType == "NewsTable" && this.state.newsTables != null) {
                for (var i = 0; i < this.state.newsTables.length; i++) {
                    let contentOpt = this.state.newsTables[i];
                    if (contentOpt.pageId == this.state.pageId) {
                        let contentOptKey = "contentOpt" + (i + 1);

                        let ddlDisplay = contentOpt.componentName != null && contentOpt.componentName != "" ? contentOpt.componentName + "-V" + contentOpt.versionId : contentOpt.id + "-V" + contentOpt.versionId;
                        contentOptions.push(<option value={contentOpt.id} key={contentOptKey}>{ddlDisplay}</option>);
                    }
                }
            }
            else if (contentType == "BirthdayTable" && this.state.birthdayTables != null) {
                for (var i = 0; i < this.state.birthdayTables.length; i++) {
                    let contentOpt = this.state.birthdayTables[i];
                    if (contentOpt.pageId == this.state.pageId) {
                        let contentOptKey = "contentOpt" + (i + 1);

                        let ddlDisplay = contentOpt.componentName != null && contentOpt.componentName != "" ? contentOpt.componentName + "-V" + contentOpt.versionId : contentOpt.id + "-V" + contentOpt.versionId;
                        contentOptions.push(<option value={contentOpt.id} key={contentOptKey}>{ddlDisplay}</option>);
                    }
                }
            }
            else if (contentType == "DocumentTable" && this.state.documentTables != null) {
                for (var i = 0; i < this.state.documentTables.length; i++) {
                    let contentOpt = this.state.documentTables[i];
                    if (contentOpt.pageId == this.state.pageId) {
                        let contentOptKey = "contentOpt" + (i + 1);

                        let ddlDisplay = contentOpt.componentName != null && contentOpt.componentName != "" ? contentOpt.componentName + "-V" + contentOpt.versionId : contentOpt.id + "-V" + contentOpt.versionId;
                        contentOptions.push(<option value={contentOpt.id} key={contentOptKey}>{ddlDisplay}</option>);
                    }
                }
            }
        }

        let contentIdField = contentType != null && contentType != "" ?
            <select name='contentId' value={contentId} onChange={this.handleChange} required>
                {contentOptions}
            </select>
            : <select name='contentId' disabled><option value="" key="contentOpt0">--Select--</option></select>;

        let viewMoreLinkField = (titleStyle == "View" || titleStyle == "ViewAll") ?
            <>
                <label>View More Link: </label>
                <input name='viewMoreLink' type='text' defaultValue={viewMoreLink} onChange={this.handleChange} required />
            </> : "";

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('Card')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />

                    <label>Card Style: </label>
                    <select name='cardStyle' value={cardStyle} onChange={this.handleChange} required>
                        <option value="" key="cardStyleKey0">--Select--</option>
                        <option value="Default" key="cardStyleKey1">Default</option>
                        <option value="Flat" key="cardStyleKey2">Flat</option>
                    </select>

                    <label>Title: </label>
                    <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required />

                    <label>Title Style: </label>
                    <select name='titleStyle' value={titleStyle} onChange={this.handleChange} required>
                        <option value="" key="titleKey0">--Select--</option>
                        <option value="Standard" key="titleKey1">Standard</option>
                        <option value="ViewAll" key="titleKey2">ViewAll</option>
                        <option value="View" key="titleKey3">View</option>
                        <option value="Hide" key="titleKey4">Hide</option>
                    </select>

                    {viewMoreLinkField}
            
                    <label>Content Type: </label>
                    <select name='contentType' value={contentType} onChange={this.handleChange} required>
                        <option value="" key="contentTypeKey0">--Select--</option>
                        <option value="HtmlEditor" key="contentTypeKey1">{componentMap.componentTypeDisplay('HtmlEditor')}</option>
                        <option value="Calendar" key="contentTypeKey2">{componentMap.componentTypeDisplay('Calendar')}</option>
                        <option value="BannerContainer" key="contentTypeKey3">{componentMap.componentTypeDisplay('BannerContainer')}</option>
                        <option value="EventTable" key="contentTypeKey4">{componentMap.componentTypeDisplay('EventTable')}</option>
                        <option value="BlogTable" key="contentTypeKey5">{componentMap.componentTypeDisplay('BlogTable')}</option>
                        <option value="NewsTable" key="contentTypeKey6">{componentMap.componentTypeDisplay('NewsTable')}</option>
                        <option value="DocumentTable" key="contentTypeKey7">{componentMap.componentTypeDisplay('DocumentTable')}</option>
                        <option value="BirthdayTable" key="contentTypeKey8">{componentMap.componentTypeDisplay('BirthdayTable')}</option>
                    </select>

                    <label>Content Style: </label>
                    <select name='contentStyle' value={contentStyle} onChange={this.handleChange} required>
                        <option value="" key="contentStyleKey0">--Select--</option>
                        {/*
                        <option value="ImageTitleDescription" key="contentStyleKey1">ImageTitleDescription</option>
                        <option value="ImageTitleDescriptionVertical" key="contentStyleKey2">ImageTitleDescriptionVertical</option>
                        <option value="ImageTitleSubtitleText" key="contentStyleKey3">ImageTitleSubtitleText</option>
                        <option value="TitleDescription" key="contentStyleKey4">TitleDescription</option>
                        */}
                        <option value="Default" key="contentStyleKey7">Default</option>
                        <option value="Warning" key="contentStyleKey5">Warning(Red)</option>
                        <option value="Update" key="contentStyleKey6">Update(Blue)</option>
                    </select>

                    <label>Content: </label>
                    {contentIdField}

                    <div className='dual-grid__1-2'>
                        {saveButton}
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetCard?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            card: data.card,
            htmlEditors: data.htmlEditors,
            calendars: data.calendars,
            bannerContainers: data.bannerContainers,
            eventTables: data.eventTables,
            blogTables: data.blogTables,
            newsTables: data.newsTables,
            birthdayTables: data.birthdayTables,
            documentTables: data.documentTables,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteCard?componentId=' + this.state.card.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default CardForm;