import React, { Component } from 'react';
import adminSettingsService from '../Admin/AdminSettingsService';
import './sidemenu.css';

export class SideMenu extends Component {
    static displayName = SideMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            menuItems: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    toggleAccordion(e) {
        let toggleId = e.target.attributes.getNamedItem('data-toggle-id').value;
        let menuItems = this.state.menuItems;

        //Find the page and update the toggle
        loop1: 
        for (var mainIndex = 0; mainIndex < menuItems.mainPages.length; mainIndex++) {
            let mainPage = menuItems.mainPages[mainIndex].page;
            let parentPages = menuItems.mainPages[mainIndex].parentPages;
            let isMainToggleOpen = menuItems.mainPages[mainIndex].isToggleOpen;

            if (mainPage.id == toggleId) {
                menuItems.mainPages[mainIndex].isToggleOpen = !isMainToggleOpen;
                break loop1;
            }

            for (var parentIndex = 0; parentIndex < parentPages.length; parentIndex++) {
                let parentPage = parentPages[parentIndex].page;
                let childPages = parentPages[parentIndex].childPages;
                let isParentToggleOpen = parentPages[parentIndex].isToggleOpen;

                if (parentPage.id == toggleId) {
                    parentPages[parentIndex].isToggleOpen = !isParentToggleOpen;
                    break loop1;
                }

                for (var childIndex = 0; childIndex < childPages.length; childIndex++) {
                    let childPage = childPages[childIndex].page;
                    let subPages = childPages[childIndex].subPages;
                    let isChildToggleOpen = childPages[childIndex].isToggleOpen;

                    if (childPage.id == toggleId) {
                        childPages[childIndex].isToggleOpen = !isChildToggleOpen;
                        break loop1;
                    }

                    for (var subIndex = 0; subIndex < subPages.length; subIndex++) {
                        let subPage = subPages[subIndex].page;
                        let isSubToggleOpen = subPages[subIndex].isToggleOpen;

                        if (subPage.id == toggleId) {
                            subPages[subIndex].isToggleOpen = !isSubToggleOpen;
                            break loop1;
                        }
                    }
                }
            }
        }

        //Change the state of the page's toggle
        this.setState({ menuItems: menuItems });
    }

    render() {
        let menuItems = this.state.menuItems;
        //console.log(menuItems);

        let menuItemArray = [];
        if (menuItems != null) {
            for (var mainIndex = 0; mainIndex < menuItems.mainPages.length; mainIndex++) {
                let mainPage = menuItems.mainPages[mainIndex].page;
                let parentPages = menuItems.mainPages[mainIndex].parentPages;
                let isMainToggleOpen = menuItems.mainPages[mainIndex].isToggleOpen;

                let mainName = mainPage.name.toUpperCase();
                let mainUrl = mainPage.friendlyUrl;
                let mainLink = mainPage.allowNavigation ? <a href={mainUrl}>{mainName}</a> : <span>{mainName}</span>;
                let mainToggleDisplay = (parentPages.length > 0 && !isMainToggleOpen) ? " +" : (parentPages.length > 0 && isMainToggleOpen) ? " -" : "";
                menuItemArray.push(<li style={mainMenuItem} key={mainPage.id} className='main-menu-item'>{mainLink}<span data-toggle-id={mainPage.id} data-menu-type='main' className='toggle-icon' style={toggleIcon} onClick={this.toggleAccordion.bind(this)}>{mainToggleDisplay}</span></li>);

                if (!isMainToggleOpen) continue;//Skip if closed
                for (var parentIndex = 0; parentIndex < parentPages.length; parentIndex++) {
                    let parentPage = parentPages[parentIndex].page;
                    let childPages = parentPages[parentIndex].childPages;
                    let isParentToggleOpen = parentPages[parentIndex].isToggleOpen;

                    let parentName = parentPage.name.toUpperCase();
                    let parentUrl = mainPage.friendlyUrl + '/' + parentPage.friendlyUrl;
                    let parentLink = parentPage.allowNavigation ? <a href={parentUrl}>{parentName}</a> : <span>{parentName}</span>;
                    let parentToggleDisplay = (childPages.length > 0 && !isParentToggleOpen) ? " +" : (childPages.length > 0 && isParentToggleOpen) ? " -" : "";
                    menuItemArray.push(<li style={parentMenuItem} className='parent-menu-item' data-main-toggle-id={mainPage.id}>{parentLink}<span data-toggle-id={parentPage.id} data-menu-type='parent' className='toggle-icon' style={toggleIcon} onClick={this.toggleAccordion.bind(this)}>{parentToggleDisplay}</span></li>);

                    if (!isParentToggleOpen) continue;//Skip if closed
                    for (var childIndex = 0; childIndex < childPages.length; childIndex++) {
                        let childPage = childPages[childIndex].page;
                        let subPages = childPages[childIndex].subPages;
                        let isChildToggleOpen = childPages[childIndex].isToggleOpen;

                        let childName = childPage.name.toUpperCase();
                        let childUrl = mainPage.friendlyUrl + '/' + parentPage.friendlyUrl + '/' + childPage.friendlyUrl;
                        let childLink = childPage.allowNavigation ? <a href={childUrl}>{childName}</a> : <span>{childName}</span>;
                        let childToggleDisplay = (subPages.length > 0 && !isChildToggleOpen) ? " +" : (subPages.length > 0 && isChildToggleOpen) ? " -" : "";
                        menuItemArray.push(<li style={childMenuItem} className='child-menu-item' data-main-toggle-id={mainPage.id} data-parent-toggle-id={parentPage.id}>{childLink}<span data-toggle-id={childPage.id} data-menu-type='child' className='toggle-icon' style={toggleIcon} onClick={this.toggleAccordion.bind(this)}>{childToggleDisplay}</span></li>);

                        if (!isChildToggleOpen) continue;//Skip if closed
                        for (var subIndex = 0; subIndex < subPages.length; subIndex++) {
                            let subPage = subPages[subIndex].page;
                            

                            let subName = subPage.name.toUpperCase();
                            let subUrl = mainPage.friendlyUrl + '/' + parentPage.friendlyUrl + '/' + childPage.friendlyUrl + '/' + subPage.friendlyUrl;
                            let subLink = subPage.allowNavigation ? <a href={subUrl}>{subName}</a> : <span>{subName}</span>;
                            menuItemArray.push(<li style={subMenuItem} className='sub-menu-item' data-main-toggle-id={mainPage.id} data-parent-toggle-id={parentPage.id} data-child-toggle-id={childPage.id}>{subLink}<span data-toggle-id={subLink.id} data-menu-type='sub'></span></li>);
                        }
                    }
                }
            }
        }

        return (
            <div className='side-menu-contianer'>
                <ul style={ulStyle}>
                    {menuItemArray}
                </ul>
            </div>
        );
    }

    async getComponentData() {
        const menuResponse = await fetch('Components/GetSideMenuItems', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const menuData = await menuResponse.json();

        this.setState({
            menuItems: menuData
        });
    }
}

const ulStyle = {
    overflow: 'scroll'
}

const mainMenuItem = {
    transition: 'none',
    backgroundColor: 'transparent',
    padding: '0px 20px',
    minHeight: '50px'
}

const parentMenuItem = {
    marginLeft: '5%',
    transition: 'none',
    backgroundColor: 'transparent',
    padding: '0px 20px',
    minHeight: '50px'
}

const childMenuItem = {
    marginLeft: '10%',
    transition: 'none',
    backgroundColor: 'transparent',
    padding: '0px 20px',
    minHeight: '50px'
}

const subMenuItem = {
    marginLeft: '15%',
    transition: 'none',
    backgroundColor: 'transparent',
    padding: '0px 20px',
    minHeight: '50px'
}

const toggleIcon = {
    fontSize: '36px',
    fontWeight: 'bold',
    marginLeft: '5px',
    verticalAlign: 'sub'
}
