import React, { Component } from 'react';
//import './footer.css';
import { MenuItems } from '../MenuItems/MenuItems';
import { SocialIcons } from '../SocialIcons/SocialIcons';
import { SuperFooter } from './SuperFooter';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            socialId: null,
            copyrightName: null,
            contentColumn1: null,
            contentColumn2: null,
            contentColumn3: null,
            contentColumn4: null,
            privacyPolicyLink: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    getYear() {
        return new Date().getFullYear();
    }

    render() {
        let socialIcons = this.state.socialId != null && this.state.socialId != 0 ? <SocialIcons componentId={this.state.socialId} /> : null;

        let customCol1 = this.state.contentColumn1 != null ? <div dangerouslySetInnerHTML={{ __html: this.state.contentColumn1 }}></div> : <div></div>;
        let customCol2 = this.state.contentColumn2 != null ? <div dangerouslySetInnerHTML={{ __html: this.state.contentColumn2 }}></div> : <div></div>;
        let customCol3 = this.state.contentColumn3 != null ? <div dangerouslySetInnerHTML={{ __html: this.state.contentColumn3 }}></div> : <div></div>;;

        let socialColumn = <div className='footer-social-column'>
            <span>Connect With Us</span>
            {socialIcons}
        </div>;

        let privacyPolicy = this.state.privacyPolicyLink != null && this.state.privacyPolicyLink != "" ?
            <span><a href={this.state.privacyPolicyLink}>Privacy Policy.</a></span> : "";

        return (
            <div className='full-width-wrapper'>
                <div className="footer-wrap">
                
                    <SuperFooter/>

                    <div className="footer">
                        <div className='footer-column-grid'>
                            {customCol1}
                            {customCol2}
                            {socialColumn}
                            {customCol3}
                        </div>

                        {/*
                        <div className='footer-menu-items'>
                            <MenuItems />
                        </div>
                        */}


                        <div style={{ marginTop: '10px', paddingBottom: '5px' }}>
                            <div className="legal-info">
                                <span>&copy; {this.getYear()}</span>
                                <span>{this.state.copyrightName}, All Rights Reserved.</span>
                                { privacyPolicy }
                                <span>Powered by <a href="https://tracsoft.com/">TracSoft</a>.</span>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('Components/GetFooterData?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                socialId: data.socialId,
                copyrightName: data.copyrightName,
                contentColumn1: data.contentColumn1,
                contentColumn2: data.contentColumn2,
                contentColumn3: data.contentColumn3,
                contentColumn4: data.contentColumn4,
                privacyPolicyLink: data.privacyPolicyLink
            });
        }
    }
}

export default Footer;

