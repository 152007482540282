import React, { Component } from 'react';
import './eventform.css';
import { getLoggedInUser } from "../../Authentication";
import TagsBySelection from '../TagsAndChips/TagsBySelection';
import ImageGalleryField from '../../ImageGalleryField';
import HtmlEditorField from '../../HtmlEditorField';

export class EventForm extends Component {
    static displayName = EventForm.name;

    constructor(props) {
        super(props);
        this.state = {
            event: null,
            eventConfiguration: null,
            loggedInUser: null,
            formType: this.props.formType != undefined ? this.props.formType : 'Create'
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newEvent = this.state.event;
        newEvent[e.target.name] = e.target.value;
        this.setState({ event: newEvent });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let event = this.state.event;

        if (this.state.formType == "Create") {
            event.uploader = this.state.loggedInUser.email;
            event.timestamp = new Date();
        }

        let url = this.state.formType == "Update" ? "UpdateEvent" : "CreateEvent";
        fetch('AdminEvent/' + url, {
            method: 'POST',
            body: JSON.stringify(event),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (this.state.formType == "Update") {
                        alert("Event Updated");
                    }
                    else {
                        window.location = '/EventManagement';
                    }
                }
                else {
                    alert(data);
                }
            }
        )
    }

    /* Limit the url key input. */
    validateUrlInput = (e) => {
        if ((e.which !== 32) //Space
            && ((e.which >= 37 && e.which <= 40) //Arrow keys
                || (e.key === "Backspace")
                || (e.key === "-")
                || (e.key >= 0 && e.key <= 9)
                || (e.key >= "a" && e.key <= "z"))) {
            //allow input
        }
        else {
            e.preventDefault();
            return false;
        }
    }

    render() {
        let typesField;
        if (this.state.eventConfiguration != null) {
            let eventType = this.state.event != null ? this.state.event.types : "";
            let eventConfiguration = this.state.eventConfiguration;

            let types = eventType != "" && eventType != null ? eventType.split(',') : [];
            let typeSelections = eventConfiguration.types.split(',');

            let typesKeyVal = [];
            for (var i = 0; i < types.length; i++) {
                typesKeyVal.push({ key: types[i], value: types[i] });
            }

            let typeSelectionsKeyVal = [];
            for (var i = 0; i < typeSelections.length; i++) {
                typeSelectionsKeyVal.push({ key: typeSelections[i], value: typeSelections[i] });
            }

            typesField = <TagsBySelection
                tags={typesKeyVal}
                tagSelections={typeSelectionsKeyVal}
                name='types'
                isRequired={true}
                allowDuplicates={false}
                allowDelete={true}
                showInput={true}
                tagUpdate={this.handleChange}
            />;
        }

        let thumbnailImage;
        let mainImage;
        let title;
        let description;
        let startTime;
        let friendlyUrl;
        if (this.state.event != null) {
            let event = this.state.event;

            thumbnailImage = event.thumbnailImage;
            mainImage = event.mainImage;
            title = event.title;
            description = event.description;
            startTime = event.startTime;
            friendlyUrl = event.friendlyUrl;
        }

        //Get time info
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth().toString().length === 1 ? '0' + (now.getMonth() + 1).toString() : now.getMonth() + 1;
        let date = now.getDate().toString().length === 1 ? '0' + (now.getDate()).toString() : now.getDate();
        let hours = now.getHours().toString().length === 1 ? '0' + now.getHours().toString() : now.getHours();
        let minutes = now.getMinutes().toString().length === 1 ? '0' + now.getMinutes().toString() : now.getMinutes();
        let seconds = now.getSeconds().toString().length === 1 ? '0' + now.getSeconds().toString() : now.getSeconds();

        let startTimeMin = year + '-' + month + '-' + date + 'T' + hours + ':' + (minutes + 5);

        // If no date is set, set a default
        if (startTime != undefined && startTime.toString() == "0001-01-01T00:00:00") {
            startTime = year + '-' + month + '-' + date + 'T' + hours + ':' + minutes;
        }

        let deleteEventButton;
        let formBtnBuffer;
        if (this.state.formType == 'Update' && this.state.loggedInUser != null && this.state.loggedInUser.role == 'Admin' && this.state.event != null) {
            deleteEventButton = <div onClick={this.deleteEvent.bind(this)} className='cancel-btn'>Delete</div>;
            formBtnBuffer = <div style={{ padding: '0 5px', display: 'inline-block' }}></div>
        }

        return (
            <div className='event-form-container'>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='form-title'>
                        <h3>{this.state.formType} Event</h3>
                    </div>

                    <div className='form-fields'>
                        <div className='tri-grid-offset'>
                            <label>Title: </label>
                            <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required />
                            <div></div>

                            <label>Friendly Url: </label>
                            <input name='friendlyUrl' type='text' defaultValue={friendlyUrl} onChange={this.handleChange} required maxLength='25' onKeyDown={this.validateUrlInput} />
                            <div></div>

                            <label>Description: </label>
                            {/*<textarea name='description' rows="4" value={description} onChange={this.handleChange} required ></textarea>*/}
                            <HtmlEditorField content={description} handleEditorChange={this.handleChange} name='description' returnAsEvent={true} />
                            <div></div>

                            <label>Start Time: </label>
                            <input name='startTime' type='datetime-local' min={startTimeMin} defaultValue={startTime} onChange={this.handleChange} required />
                            <div></div>

                            <label>Types: </label>
                            {typesField}
                            <div></div>

                            <label>Thumbnail Image: </label>
                            <input name='thumbnailImage' type='text' defaultValue={thumbnailImage} onChange={this.handleChange} hidden />
                            <ImageGalleryField name='thumbnailImage' currentImageUrl={thumbnailImage} handleImageChange={this.handleChange} />
                            <div></div>

                            <label>Main Image: </label>
                            <input name='mainImage' type='text' defaultValue={mainImage} onChange={this.handleChange} hidden />
                            <ImageGalleryField name='mainImage' currentImageUrl={mainImage} handleImageChange={this.handleChange} />
                            <div></div>
                        </div>
                    </div>

                    <div className='form-submit'>
                        <button type='submit' className='form-button'>Save</button>
                        {formBtnBuffer}
                        {deleteEventButton}
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let eventId = this.props.eventId !== undefined ? this.props.eventId : 0;
        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        const response = await fetch('AdminEvent/GetEventData?eventId=' + eventId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            event: data.event,
            eventConfiguration: data.eventConfiguration,
            loggedInUser: loggedInUser
        });
    }

    async deleteEvent(e) {
        e.preventDefault();

        let eventId = this.state.event.id;
        let confirmDelete = window.confirm("Are you sure you want to delete this event?");

        if (confirmDelete) {
            let id = parseInt(eventId);
            const response = await fetch('AdminEvent/DeleteEvent?eventId=' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                window.location = '/EventManagement';
            }
            else {
                alert(data);
            }
        }
    }
}

export default EventForm;
