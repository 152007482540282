import React, { Component } from 'react';
import './sidenav.css';
import HtmlEditorField from '../../HtmlEditorField';

export class SideNavForm extends Component {
    static displayName = SideNavForm.name;

    constructor(props) {
        super(props);
        this.state = {
            sideNav: null,
            formType: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newSideNav = this.state.sideNav;
        newSideNav[e.target.name] = e.target.value;
        this.setState({ sideNav: newSideNav });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let sideNav = this.state.sideNav;

        let url = this.state.formType == "Update" ? "UpdateSideNav" : "CreateSideNav";
        fetch('AdminSideNav/' + url, {
            method: 'POST',
            body: JSON.stringify(sideNav),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    alert("Saved");
                    window.location = '/SideNavManagement';
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let componentName;
        let content;

        if (this.state.sideNav != null) {
            let sideNav = this.state.sideNav;
            componentName = sideNav.componentName;
            content = sideNav.content;
        }

        console.log(componentName);

        return (
            <div className='side-nav-form-container'>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='form-title'>
                        <h3>{this.state.formType} Side Nav</h3>
                    </div>

                    <div className='form-fields'>
                        <div className='tri-grid-offset'>
                            <label>Component Name: </label>
                            <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required />
                            <div></div>

                            <label>Content: </label>
                            <div className='editor-wrapper'>
                                <HtmlEditorField content={content} handleEditorChange={this.handleChange} name='content' returnAsEvent={true} />
                            </div>
                            <div></div>
                        </div>
                    </div>

                    <div className='form-submit'>
                        <button type='submit' className='form-button'>Save</button>
                    </div>
                </form>
            </div>  
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('AdminSideNav/GetSideNav?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            sideNav: data,
            formType: formType
        });
    }
}

export default SideNavForm;
