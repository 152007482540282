import React, { Component } from 'react';
import Cleave from 'cleave.js/react';
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';
import States from '../GlobalSets/States';
import states from '../GlobalSets/States';
import reCaptchaData from '../ReCaptchaData';
import ReCAPTCHA from "react-google-recaptcha";
import { post } from 'axios';

export class Form extends Component {
    static displayName = Form.name;

    constructor(props) {
        super(props);
        this.state = {
            form: null,
            formFields: null,
            formValues: [],
            captchaToken: null,
            file: '',
            confirmationEmail: ""
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        var fieldId = e.target.attributes.getNamedItem('data-fieldId').value;
        var fieldType = e.target.type;

        let newFormValues = this.state.formValues;
        for (var i = 0; i < this.state.formValues.length; i++) {
            if (this.state.formValues[i].fieldName == name) {
                if (fieldType == 'checkbox') {
                    newFormValues[i].fieldValue = e.target.checked ? "true" : "false";
                }
                else {
                    newFormValues[i].fieldValue = value;
                    break;
                }
            }
        }

        this.setState({ formValues: newFormValues });
    }

    handleConfirmationEmailChange = (e) => {
        this.setState({ confirmationEmail: e.target.value });
    }

    setFile(e) {
        this.setState({ file: e.target.files[0] });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        window.document.body.style.cursor = "wait";

        //Verify captcha before submit
        if (this.state.captchaToken != null) {
            fetch('Captcha/IsReCaptchValid?captchaToken=' + this.state.captchaToken, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (data == true) {
                        let form = this.state.form;
                        let formValues = this.state.formValues;
                        let file = this.state.file;
                        let confirmationEmail = this.state.confirmationEmail;

                        const url = `FormComponent/SubmitForm`;
                        const formData = new FormData();
                        formData.append('Form', JSON.stringify({ form }));
                        formData.append('NameValuePairs', JSON.stringify({ formValues }));
                        formData.append('File', file);
                        formData.append('ConfirmationEmail', confirmationEmail);

                        fetch(url,
                            {
                                method: 'POST',
                                body: formData,
                                headers: {
                                    'Accept': 'application/json'
                                }
                            }
                        ).then(
                            submissionResponse => submissionResponse.json()
                        ).then(
                            submissionData => {
                                if (submissionData == "Sent") {
                                    let successMessage = (form.successMessage != null && form.successMessage != "") ? form.successMessage : "Successful Submission";
                                    alert(successMessage);
                                    window.document.body.style.cursor = "default";
                                    window.location.reload();
                                }
                                else {
                                    alert(submissionData);
                                    window.document.body.style.cursor = "default";
                                }
                            }
                        );
                    }
                    else {
                        alert("reCaptcha verification failed");
                        window.document.body.style.cursor = "default";
                    }
                }
            );
        }
        else {
            alert("reCaptcha verification failed");
            window.document.body.style.cursor = "default";
        }
    }

    onCaptchaChange = (value) => {
        if (value) {
            this.setState({ captchaToken: value });
        }
        else {
            this.setState({ captchaToken: null });
        }
    }

    render() {
        let fields = [];
        if (this.state.formFields != null) {
            let formFields = this.state.formFields;
            
            for (var i = 0; i < formFields.length; i++) {
                let field = formFields[i];
                let fieldTag;

                switch (field.fieldType) {
                    case 'input':
                        if (field.inputType == 'text') {
                            fieldTag = <input
                                name={field.name}
                                type={field.inputType}
                                placeholder={field.placeholder}
                                required={field.isRequired}
                                minLength={field.minLength > 0 ? field.minLength : ""}
                                maxLength={field.maxLength > 0 ? field.maxLength : 1000}
                                onChange={this.handleChange}
                                data-fieldId={field.id}
                            />;
                        }
                        else { //number
                            fieldTag = <input
                                name={field.name}
                                type={field.inputType}
                                placeholder={field.placeholder}
                                required={field.isRequired}
                                min={field.minLength > 0 ? field.minLength : ""}
                                max={field.maxLength > 0 ? field.maxLength : 99999999}
                                onChange={this.handleChange}
                                data-fieldId={field.id}
                            />;
                        }
                        break;
                    case 'textarea':
                        fieldTag = <textarea
                            name={field.name}
                            rows="4"
                            required={field.isRequired}
                            onChange={this.handleChange}
                            data-fieldId={field.id}
                        ></textarea>;
                        break;
                    case 'selectList':
                        let ddlOptions = [];
                        ddlOptions.push(<option key="ddl0" value="">{field.placeholder != null && field.placeholder != "" ? field.placeholder : "--Select--"}</option>);
                        let fieldOpts = field.options.split(',');
                        for (var j = 0; j < fieldOpts.length; j++) {
                            let ddlKey = "ddl" + (j + 1);
                            ddlOptions.push(<option key={ddlKey} value={fieldOpts[j]}>{fieldOpts[j]}</option>);
                        }

                        fieldTag = <select
                            name={field.name}
                            required={field.isRequired}
                            onChange={this.handleChange}
                            data-fieldId={field.id}
                        >
                            {ddlOptions}
                        </select>;
                        break;
                    case 'checkbox':
                        fieldTag = <div className='checkbox-wrapper'>
                            <input
                                name={field.name}
                                type='checkbox'
                                onChange={this.handleChange}
                                data-fieldId={field.id}
                            />
                        </div>;
                        break;
                    case 'email':
                        fieldTag = <input
                            name={field.name}
                            type='email'
                            placeholder={field.placeholder}
                            required={field.isRequired}
                            onChange={this.handleChange}
                            data-fieldId={field.id}
                            maxLength={50}
                        />;
                        break;
                    case 'phone':
                        fieldTag = <Cleave
                            name={field.name}
                            options={{ phone: true, phoneRegionCode: 'US' }}
                            pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                            placeholder='### ### ####'
                            required={field.isRequired}
                            onChange={this.handleChange}
                            data-fieldId={field.id}
                        />;
                        break;
                    case 'state':
                        let statesArray = states.getStates();
                        let stateOptions = [];
                        stateOptions.push(<option key='stateOpt0' value="">{field.placeholder != null && field.placeholder != "" ? field.placeholder : "--Select--"}</option>);
                        for (var j = 0; j < statesArray.length; j++) {
                            let stateOptKey = 'stateOpt' + (j + 1);
                            stateOptions.push(<option key={stateOptKey} value={statesArray[j]}>{statesArray[j]}</option>);
                        }

                        fieldTag = <select
                            name={field.name}
                            required={field.isRequired}
                            onChange={this.handleChange}
                            data-fieldId={field.id}
                        >
                            {stateOptions}
                        </select>;
                        break;
                    case 'fileUpload':
                        fieldTag = <input
                            name={field.name}
                            required={field.isRequired}
                            onChange={e => this.setFile(e)}
                            data-fieldId={field.id}
                            type="file"
                            className="file-input"
                        />;
                        break;
                    case 'futureDatePicker':
                        //Get the minimum allowed date
                        let baseDate = new Date();
                        baseDate.setDate(baseDate.getDate() + 1);
                        let year = baseDate.getFullYear();
                        let month = baseDate.getMonth().toString().length === 1 ? '0' + (baseDate.getMonth() + 1).toString() : baseDate.getMonth() + 1;
                        let date = baseDate.getDate().toString().length === 1 ? '0' + (baseDate.getDate()).toString() : baseDate.getDate();

                        let requestDateMin = year + '-' + month + '-' + date;

                        fieldTag = <input
                            name={field.name}
                            required={field.isRequired}
                            onChange={this.handleChange}
                            data-fieldId={field.id}
                            type="date"
                            min={requestDateMin}
                        />;
                        break;
                    default:
                        break;
                }

                if (fieldTag != undefined) {
                    let requiredIcon = field.isRequired ? <span className='required-flag' title='This field is required.'>*</span> : "";

                    fields.push(
                        <>
                            <label>{field.name}{requiredIcon}: </label>
                            {fieldTag}
                        </>
                    );
                }
            }
        }

        let confirmationEmailField;
        if (this.state.form != null) {
            let form = this.state.form;

            if (form.includeConfirmationEmail) {
                confirmationEmailField = <>
                    <label>Confirmation Email: </label>
                    <input
                        name='confirmationEmail'
                        type='email'
                        placeholder='email@domain.com'
                        required={false}
                        onChange={this.handleConfirmationEmailChange}
                    />
                </>;
            }
        }

        let formName = this.state.form != null ? this.state.form.name : "";

        return (
            <div className='form-component-container'>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='form-title'>
                        {/*<h4>{formName}</h4>*/}
                    </div>

                    <div className='dual-grid-offset'>
                        {fields}

                        {confirmationEmailField}

                        <label></label>
                        <div id="reCaptchaWrapper">
                            <ReCAPTCHA sitekey={reCaptchaData.getSiteKey()} onChange={this.onCaptchaChange} />
                        </div>
                    </div>

                    <div className='form-submit'>
                        <button type='submit' className='form-button submit-btn'>Submit</button>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let formId = this.props.formId;
        if (formId != undefined) {
            const response = await fetch('Components/GetFormData?formId=' + formId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();
            
            if (data != null) {
                //console.log(data);

                //set field values
                let formValues = [];
                for (var i = 0; i < data.formFields.length; i++) {
                    if (data.formFields[i].fieldType == 'checkbox') {
                        formValues.push({ fieldId: data.formFields[i].id, fieldName: data.formFields[i].name, fieldValue: 'false' });
                    }
                    else if (data.formFields[i].fieldType == 'fileUpload') {
                        //Remove fileUpload as normal field
                    }
                    else {
                        formValues.push({ fieldId: data.formFields[i].id, fieldName: data.formFields[i].name, fieldValue: null });
                    }
                }
                //console.log(formValues);
                //console.log(data.formFields);
                this.setState({
                    form: data.form,
                    formFields: data.formFields,
                    formValues: formValues
                });
            }
        }
    }
}

export default Form;