import React, { Component } from 'react';
import { CustomLayout } from '../CustomLayout/CustomLayout';
import { StaticBreadcrumb } from '../Breadcrumb/StaticBreadcrumb';

export class Event extends Component {
    static displayName = Event.name;

    constructor(props) {
        super(props);
        this.state = {
            event: null,
            eventConfiguration: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    formatDateTime(dateTime, includeTime, isTextFormat) {
        let timestamp = new Date(dateTime.toString());

        let year = timestamp.getFullYear();
        let month = timestamp.getMonth().toString().length === 1 ? '0' + (timestamp.getMonth() + 1).toString() : timestamp.getMonth() + 1;
        let date = timestamp.getDate().toString().length === 1 ? '0' + (timestamp.getDate()).toString() : timestamp.getDate();
        let day = timestamp.getDay();
        let hours = timestamp.getHours().toString().length === 1 ? '0' + timestamp.getHours().toString() : timestamp.getHours();
        let midday = hours >= 12 ? "PM" : "AM";
        if (hours == 0) {
            hours = 12;
        }
        if (hours >= 13) {
            hours = hours - 12;
            if (hours.toString().length === 1) hours = '0' + hours;
        }
        let minutes = timestamp.getMinutes().toString().length === 1 ? '0' + timestamp.getMinutes().toString() : timestamp.getMinutes();

        var abvMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var abvWeekDays = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

        let timestampFormat;
        if (isTextFormat) {
            timestampFormat = includeTime ?
                (abvWeekDays[day] + " " + abvMonths[month - 1] + " " + date + ", " + year + " " + hours + ":" + minutes + " " + midday) :
                (abvWeekDays[day] + " " + abvMonths[month - 1] + " " + date + ", " + year);
        }
        else {
            timestampFormat = includeTime ?
                (month + "/" + date + "/" + year + " " + hours + ":" + minutes + " " + midday) :
                (month + "/" + date + "/" + year);
        }

        return timestampFormat;
    }

    render() {
        let mainImage;
        let title;
        let description;
        let startTime;

        if (this.state.event != null && this.state.eventConfiguration != null) {
            let event = this.state.event;
            let eventConfiguration = this.state.eventConfiguration;
            mainImage = (event.mainImage != null && event.mainImage != "" && event.mainImage != undefined) ? <img className='event-image' alt="event image" src={event.mainImage} /> : "";
            title = event.title;
            description = event.description;
            startTime = this.formatDateTime(event.startTime, true, true);
        }

        let staticBreadcrumbContent = <a href='/events'>EVENTS /</a>;

        return (
            <CustomLayout>
                <StaticBreadcrumb content={staticBreadcrumbContent} />

                <div className='event-container'>
                    <div className='event-title'>{title}</div>
                    <div className='event-time'>{startTime}</div>

                    {mainImage}
                    <div className='event-description' dangerouslySetInnerHTML={{ __html: description }}></div>
                    {/*<div className='event-description'>{description}</div>*/}
                </div>  
            </CustomLayout>
        );
    }

    async getComponentData() {
        let friendlyUrl = this.props.match.params.eventFriendlyUrl;

        const response = await fetch('components/GetEvent?friendlyUrl=' + friendlyUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            event: data.event,
            eventConfiguration: data.eventConfiguration
        });
    }
}
