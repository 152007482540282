import React, { Component } from 'react';

import GarbageFees from '../images/ClientStatic/garbage_fees.png';
import GarbageFeesHover from '../images/ClientStatic/garbage_fees_hover.png';
import PayMyBill from '../images/ClientStatic/pay_my_bill.png';
import PayMyBillHover from '../images/ClientStatic/pay_my_bill_hover.png';
import PaymentOptions from '../images/ClientStatic/payment_options.png';
import PaymentOptionsHover from '../images/ClientStatic/payment_options_hover.png';
import ReportAProblem from '../images/ClientStatic/report_a_problem.png';
import ReportAProblemHover from '../images/ClientStatic/report_a_problem_hover.png';

export class IconLinkContainer extends Component {
    static displayName = IconLinkContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            iconLinkContainer: null,
            iconLinks: []
        };
        this.onHover = this.onHover.bind(this);
        this.offHover = this.offHover.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    onHover = (icon) => {
        let element = icon.target.parentElement;
        let id = element.id;
        let oppId = id + "_hover";
        let oppElement = window.document.getElementById(oppId);

        if (oppElement != null) {
            element.style.display = "none";
            oppElement.style.display = "block";
        }
    }

    offHover = (icon) => {
        let element = icon.target.parentElement;
        let id = element.id;
        let oppId = id.split("_hover")[0];
        let oppElement = window.document.getElementById(oppId);;
        
        if (oppElement != null) {
            element.style.display = "none";
            oppElement.style.display = "block";
        }
    }

    render() {
        let icons = [];
        for (var i = 0; i < this.state.iconLinks.length; i++) {
            let iconLink = this.state.iconLinks[i];

            let iconSrc = null;
            let iconHoverSrc = null;
            let iconName = null;
            let iconClass = "";
            let iconTarget = "";
            switch (iconLink.icon) {
                case "garbage_fees":
                    iconSrc = GarbageFees;
                    iconHoverSrc = GarbageFeesHover;
                    iconName = "Garbage Fees";
                    iconClass = "garbage-fees";
                    iconTarget = "";
                    break;
                case "pay_my_bill":
                    iconSrc = PayMyBill;
                    iconHoverSrc = PayMyBillHover;
                    iconName = "Pay My Bill";
                    iconClass = "pay-my-bill";
                    iconTarget = "_blank";
                    break;
                case "payment_options":
                    iconSrc = PaymentOptions;
                    iconHoverSrc = PaymentOptionsHover;
                    iconName = "Payment Options";
                    iconClass = "payment-options";
                    iconTarget = "";
                    break;
                case "report_a_problem":
                    iconSrc = ReportAProblem;
                    iconHoverSrc = ReportAProblemHover;
                    iconName = "Report a Problem";
                    iconClass = "report-a-problem";
                    iconTarget = "";
                    break;
                default:
                    break;
            }

            if (iconSrc != null && iconHoverSrc != null && iconName != null) {
                let iconId = iconLink.icon;
                let iconHoverId = iconLink.icon + "_hover";

                let icon = <img src={iconSrc} alt="icon link" />;
                let iconHover = <img src={iconHoverSrc} alt="icon link" />;
                let iconText = <div >{iconName}</div>;

                let iconContent = <div id={iconId} onMouseOver={this.onHover}>{icon}{iconText}</div>;
                let iconHoverContent = <div id={iconHoverId} onMouseOut={this.offHover} style={{ display: 'none' }}>{iconHover}{iconText}</div>;

                let iconLinkClasses = "icon-link-opt " + iconClass;
                icons.push(<a href={iconLink.link} alt={"Go to " + iconName} target={iconTarget} className={iconLinkClasses}>{iconContent}{iconHoverContent}</a>);
            }
        }

        return (
            <div className='full-width-wrapper__phone'>
                <div className='icon-link-container-container'>
                    {icons}
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetIconLinkContainer?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            iconLinkContainer: data.iconLinkContainer,
            iconLinks: data.iconLinks
        });
    }
}

