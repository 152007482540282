import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import adminSettingsService from '../Admin/AdminSettingsService';

export default class AuthorizeUserRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            isEventInitialized: false,
            isBlogInitialized: false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        if (!this.state.ready) {
            return <div></div>;
        }
        else {
            const { component: Component, ...rest } = this.props;

            return <Route {...rest}
                render={(props) => {
                    
                    if (this.props.requireEvent == true && !this.state.isEventInitialized) {
                        return <Redirect to={'/'} />
                    }
                    else if (this.props.requireBlog == true && !this.state.isBlogInitialized) {
                        return <Redirect to={'/'} />
                    }
                    else {
                        return <Component {...props} />
                    }
                }}
            />
        }
    }

    async getComponentData() {
        const isEventInitialized = await adminSettingsService.isEventInitialized();
        const isBlogInitialized = await adminSettingsService.isBlogInitialized();

        this.setState({
            isEventInitialized: isEventInitialized,
            isBlogInitialized: isBlogInitialized,
            ready: true
        });
    }
}