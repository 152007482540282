import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';

export class BirthdayTable extends Component {
    static displayName = BirthdayTable.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            birthdayTable: null,
            birthdays: [],
            filteredBirthdays: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let birthdayTable = this.state.birthdayTable;
        let birthdays = this.state.filteredBirthdays;

        let tableName;

        if (birthdayTable != null) {
            tableName = birthdayTable.componentName;
        }

        let tableRows = [];
        if (birthdays != null && birthdays.length > 0 && birthdayTable != null) {
            let maxEntries = birthdayTable.maxEntries > 0 ? birthdayTable.maxEntries : birthdays.length;
            let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            let currentDate = new Date();
            let currentMonth = currentDate.getMonth() + 1;
            let currentDay = currentDate.getDate();

            for (var i = 0; i < birthdays.length; i++) {
                let birthday = birthdays[i];

                //Only display the max allowed rows
                if (tableRows.length < maxEntries) {
                    //Only display current and future birthdays
                    if (birthday.month >= currentMonth && birthday.day >= currentDay) {
                        let name = birthday.firstName + " " + birthday.lastName;
                        let birthdayFormat = months[birthday.month - 1] + " " + birthday.day;

                        let row;
                        if (birthdayTable.isCompact) {
                            row = <div className='row--compact'>
                                <span className='bday-name'>{name}</span>
                                <span className='bday-birthday'>{birthdayFormat}</span>
                                <span className='bday-department'>{birthday.department}</span>
                            </div>;
                        }
                        else {
                            row = {
                                name: name,
                                birthday: birthdayFormat,
                                department: birthday.department
                            };
                        }

                        tableRows.push(row);
                    }
                }
            }
        }

        let table;
        if (birthdayTable != null) {
            if (birthdayTable.isCompact) {
                table = <div className='compact-table'>
                    {tableRows}
                </div>;
                tableName = null;
            }
            else {
                let tableData = {
                    columns: [
                        { label: 'Name', field: 'name', sort: 'asc' },
                        { label: 'Birthday', field: 'birthday', sort: 'asc' },
                        { label: 'Department', field: 'department', sort: 'asc' }
                    ],
                    rows: tableRows
                };
                table = <MDBDataTable entries={100} data={tableData} className='full-table' />;
            }
        }

        return (
            <div className='birthday-table-container'>
                <div className='table-name'>
                    {/*{tableName}*/}
                </div>

                {table}
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetBirthdayTable?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            birthdayTable: data.birthdayTable,
            birthdays: data.birthdays,
            filteredBirthdays: data.birthdays
        });
    }
}