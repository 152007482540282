import React, { Component } from 'react';
import './formbuilder.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

// Sections of page
import FormForm from '../FormForm/FormForm';
import FormFieldForm from '../FormFieldForm/FormFieldForm';
import DnDFieldeOrder from './DnDFieldOrder';

export class FormBuilder extends Component {
    static displayName = FormBuilder.name;

    constructor(props) {
        super(props);
        this.state = {
            form: null,
            formFields: null,
            currentCenter: null,
            tempCurrentCenter: null,
            componentSelectionId: 0,
            resetRequired: false,
            columnTab: 'formDetails'
        }
    }

    componentDidMount() {
        this.getComponentData();
    }

    /* Handle changes when form is saved */
    async onFormSaved() {
        await this.setState({
            resetRequired: true,
            tempCurrentCenter: null
        });

        await this.getComponentData();
    }

    /* Handle changes when a form field is saved */
    async onFormFieldSaved() {
        await this.setState({
            resetRequired: true,
            tempCurrentCenter: null,
            currentCenter: null,
            componentSelectionId: null
        });

        await this.getComponentData();
    }

    /* Set the center to the FormField component form */
    getFieldComponentForm(fieldId, formId, formType) {
        let fieldForm = <FormFieldForm
            formId={formId}
            formFieldId={fieldId}
            formType={formType}
            onFormFieldSaved={this.onFormFieldSaved.bind(this)}
        />;

        let componentSelectionId = fieldId > 0 ? fieldId : null;

        this.setState({
            resetRequired: true,
            componentSelectionId: componentSelectionId,
            currentCenter: null,
            tempCurrentCenter: fieldForm,
            columnTab: 'fieldDetails'
        });

        this.getComponentData();
    }

    /* Delete form field */
    async deleteFormFieldComponent(fieldId, fieldName) {
        let confirmDelete = window.confirm("Are you sure you want to delete field '" + fieldName + "'?");

        if (confirmDelete) {
            let id = parseInt(fieldId);
            const response = await fetch('AdminForms/DeleteFormFieldComponent?formFieldId=' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                await this.setState({
                    resetRequired: true,
                    tempCurrentCenter: null,
                    currentCenter: null,
                });

                await this.getComponentData();
            }
            else {
                alert(data);
            }
        }
    }

    /* Update the center so the same component with different data will display */
    resetCurrentCenter() {
        this.setState({
            currentCenter: this.state.tempCurrentCenter,
            tempCurrentCenter: null
        });
    }

    /* Update the order of fileds in the form */
    async fieldOrderUpdate(componentArray) {
        let componentIdsOrdered = [];
        for (var i = 0; i < componentArray.length; i++) {
            componentIdsOrdered.push(parseInt(componentArray[i].id));
        }
        
        const response = await fetch('AdminForms/UpdateFormFieldOrder', {
            method: 'POST',
            body: JSON.stringify({ "FormFieldIds": componentIdsOrdered }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != "Saved") {
            alert(data);
        }
    }

    /* Update the current tab */
    switchTab(tabName) {
        this.setState({
            columnTab: tabName,
            resetRequired: true
        });

        this.getComponentData();
    }

    render() {
        let form = this.state.form;
        let formFields = this.state.formFields;

        let formNameTitle;
        let formData;
        if (form != null) {
            formNameTitle = <h2>{form.name} Form Builder</h2>;
            formData = <FormForm formId={form.id} formType='Update' onFormSaved={this.onFormSaved.bind(this)} />;
        }
        else {
            formNameTitle = <h2>Form Builder</h2>;
            formData = "";
        }

        let formFieldData;
        let fields = [];
        if (formFields != null && form != null) {
            formFields.forEach((field, i) => {
                let key = 'associatedFields' + i;

                let isComponentSelected = (this.state.componentSelectionId == field.id) ? true : false;
                let currentComponentClasses = isComponentSelected ? "current-component selected-component" : "current-component";
                //console.log(field.name, currentComponentClasses);

                let currentField = <div key={key} className={currentComponentClasses}>
                    <FontAwesomeIcon icon={faEllipsisV} style={{ marginRight: '5%' }} />
                    <div className='current-component__data'>
                        {field.name}
                    </div>
                    <div className='current-component__manage'>
                        <span className='current-component__edit' onClick={this.getFieldComponentForm.bind(this, field.id, form.id, 'Update')}><FontAwesomeIcon icon={faEdit} /></span>
                        <span className='current-component__delete' onClick={this.deleteFormFieldComponent.bind(this, field.id, field.name)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                    </div>
                </div>;

                fields.push({ id: field.id.toString(), content: currentField });
            });
        }

        let fieldsDnD = !this.state.resetRequired ?
            <DnDFieldeOrder fields={fields} fieldOrderUpdate={this.fieldOrderUpdate} /> : "";

        if (form != null) {
            formFieldData = <div>
                <h3>Fields</h3>
                {fieldsDnD}
                <div><button onClick={this.getFieldComponentForm.bind(this, 0, form.id, 'Create')}>Create New Field</button></div>
            </div >;
        }
        
        let managementData = this.state.currentCenter != null ?
            this.state.currentCenter : <div className='intro-data'>
                <h3>&nbsp;</h3>
                <div className='explination-container'>
                    <span>Use this area to update form fields.</span>
                </div>
            </div>;

        //Reset all columns
        if (this.state.resetRequired) {
            formFieldData = "";
            managementData = "";
            formData = "";
        }
        if (this.state.tempCurrentCenter != null) {
            this.resetCurrentCenter();
        }

        let tabData;
        if (this.state.columnTab == 'fieldDetails') {
            let fieldDetails = <div className='center-column current-tab'>
                {managementData}
            </div>;
            tabData = fieldDetails;
        }
        else {
            let formDetails = <div className='right-column current-tab'>
                {formData}
            </div>;
            tabData = formDetails;
        }

        return (
            <div className='form-builder-container'>
                <div className='tri-grid'>
                    <div className='tri-grid--full-center page-title'>{formNameTitle}</div>

                    <div className='left-column'>
                        {formFieldData}
                    </div>
                    
                    <div>
                        <div className='column-tabs'>
                            <button className={this.state.columnTab == 'fieldDetails' ? 'current-tab' : ''} onClick={this.switchTab.bind(this, 'fieldDetails')}>Field Details</button>
                            <button className={this.state.columnTab == 'formDetails' ? 'current-tab' : ''} onClick={this.switchTab.bind(this, 'formDetails')}>Form Details</button>
                        </div>

                        {tabData}
                    </div>
                </div>
            </div>
        );
    }

    async getComponentData() {
        let formId = this.props.match.params.formId;

        //Get form data
        const formResponse = await fetch('AdminForms/GetForm?formId=' + formId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const formData = await formResponse.json();

        //Get form field data
        const associatedFormFieldResponse = await fetch('AdminForms/GetAssociatedFormFields?formId=' + formId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const associatedFormFieldData = await associatedFormFieldResponse.json();

        this.setState({
            form: formData,
            formFields: associatedFormFieldData,
            //currentCenter: null,
            resetRequired: false
        });
    }
}

