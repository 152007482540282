import React, { Component } from 'react';

export class HRSeparator extends Component {
    static displayName = HRSeparator.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            hrSeparator: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        return (
            <hr className="after-header-hr" />
        );
    }

    async getComponentData() {
        const response = await fetch('Components/GetHRSeparator?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                hrSeparator: data
            });
        }
    }
}
