import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import HtmlEditorField from '../../../HtmlEditorField';

export class HtmlEditorForm extends Component {
    static displayName = HtmlEditorForm.name;

    constructor(props) {
        super(props);
        this.state = {
            htmlEditor: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let htmlEditor = this.state.htmlEditor;
        htmlEditor.versionId = this.state.versionId;
        htmlEditor.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateHtmlEditor" : "CreateHtmlEditor";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(htmlEditor),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "HtmlEditor", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    handleChange = (e) => {
        let newHtmlEditor = this.state.htmlEditor;
        newHtmlEditor[e.target.name] = e.target.value;
        this.setState({ htmlEditor: newHtmlEditor });
    }

    handleEditorChange = (content) => {
        //console.log(content);
        let newHtmlEditor = this.state.htmlEditor;
        newHtmlEditor['content'] = content;
        this.setState({ htmlEditor: newHtmlEditor });
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let content = null;
        let componentName = null;

        if (this.state.htmlEditor != null) {
            let htmlEditor = this.state.htmlEditor;
            content = htmlEditor.content;
            componentName = htmlEditor.componentName;
        }

        let editorComponentWrapperClasses = this.state.versionStatus == 'draft' ? 'dual-grid__1-2 editor-wrapper' : 'dual-grid__1-2 editor-wrapper disable-wrapper';
        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';

        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('HtmlEditor')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />

                    <div className={editorComponentWrapperClasses}>
                        <HtmlEditorField content={content} handleEditorChange={this.handleEditorChange} />
                    </div>

                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetHtmlEditor?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            htmlEditor: data,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteHtmlEditor?componentId=' + this.state.htmlEditor.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                //window.location = '/UpdatePageComponent/' + this.state.pageId;
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default HtmlEditorForm;

