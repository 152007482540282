// Component Thumbnails
import NoImageAvailable from './images/NoImageAvailable.png';
import ActionButtonThumbnail from './images/ComponentThumbnails/ActionButton.png';
import AnnouncementThumbnail from './images/ComponentThumbnails/Announcement.png';
import BannerContainerThumbnail from './images/ComponentThumbnails/Banner.png';
import BigText5050Thumbnail from './images/ComponentThumbnails/BigText5050.png';
import Contact5050Thumbnail from './images/ComponentThumbnails/Contact5050.png';
import Features1Thumbnail from './images/ComponentThumbnails/Features1.png';
import Grid5050Thumbnail from './images/ComponentThumbnails/Grid5050.png';
import HRSeparatorThumbnail from './images/ComponentThumbnails/HRSeparator.png';
import HtmlEditorThumbnail from './images/ComponentThumbnails/HtmlEditor.png';
import SingleFeatures1Thumbnail from './images/ComponentThumbnails/SingleFeatures1.png';

export class ComponentMap {
    /* Return a component type name for display */
    componentTypeDisplay(componentType) {
        let displayName;
        switch (componentType) {
            case "Announcement":
                displayName = "Announcement";
                break;
            case "BannerContainer":
                displayName = "Banner";
                break;
            case "IconLinkContainer":
                displayName = "Icon Link Container";
                break;
            case "ColumnsTabContainer":
                displayName = "Columns Tab Container";
                break;
            case "Accordion":
                displayName = "Accordion";
                break;
            case "Navbar":
                displayName = "Navbar";
                break;
            case "Footer":
                displayName = "Footer";
                break;
            case "HtmlEditor":
                displayName = "Text Editor";
                break;
            case "SocialIcons":
                displayName = "Social Links";
                break;
            case "BigText5050":
                displayName = "Image Descriptor Right";
                break;
            case "Contact5050":
                displayName = "Form Container";
                break;
            case "SingleFeatures1":
                displayName = "Featured Item";
                break;
            case "Features1":
                displayName = "Featured Item Set";
                break;
            case "Grid5050":
                displayName = "Image Descriptor Left";
                break;
            case "ActionButton":
                displayName = "Call Button";
                break;
            case "HRSeparator":
                displayName = "Page Separator";
                break;
            case "Card":
                displayName = "Card";
                break;
            case "CardContainer":
                displayName = "Card Container";
                break;
            case "Calendar":
                displayName = "Calendar";
                break;
            case "DocumentTable":
                displayName = "Document Table";
                break;
            case "EventTable":
                displayName = "Event Table";
                break;
            case "BlogTable":
                displayName = "Blog Table";
                break;
            case "BlogPreview":
                displayName = "Blog Preview";
                break;
            case "NewsTable":
                displayName = "News Table";
                break;
            case "BirthdayTable":
                displayName = "Birthday Table";
                break;
            case "SideContainer":
                displayName = "Side Container";
                break;
            default:
                displayName = componentType;
                break;
        }
        return displayName;
    }

    /* Return the thumbnail that matches with the component */
    getThumbnail(componentType) {
        let thumbnail;
        switch (componentType) {
            case "Announcement":
                thumbnail = AnnouncementThumbnail;
                break;
            case "BannerContainer":
                thumbnail = BannerContainerThumbnail;
                break;
            case "IconLinkContainer":
                thumbnail = NoImageAvailable;
                break;
            case "ColumnsTabContainer":
                thumbnail = NoImageAvailable;
                break;
            case "Accordion":
                thumbnail = NoImageAvailable;
                break;
            case "Navbar":
                thumbnail = NoImageAvailable;
                break;
            case "Footer":
                thumbnail = NoImageAvailable;
                break;
            case "HtmlEditor":
                thumbnail = HtmlEditorThumbnail;
                break;
            case "SocialIcons":
                thumbnail = NoImageAvailable;
                break;
            case "BigText5050":
                thumbnail = BigText5050Thumbnail;
                break;
            case "Contact5050":
                thumbnail = Contact5050Thumbnail;
                break;
            case "SingleFeatures1":
                thumbnail = SingleFeatures1Thumbnail;
                break;
            case "Features1":
                thumbnail = Features1Thumbnail;
                break;
            case "Grid5050":
                thumbnail = Grid5050Thumbnail;
                break;
            case "ActionButton":
                thumbnail = ActionButtonThumbnail;
                break;
            case "HRSeparator":
                thumbnail = HRSeparatorThumbnail;
                break;
            case "Card":
                thumbnail = NoImageAvailable;
                break;
            case "CardContainer":
                thumbnail = NoImageAvailable;
                break;
            case "Calendar":
                thumbnail = NoImageAvailable;
                break;
            case "DocumentTable":
                thumbnail = NoImageAvailable;
                break;
            case "EventTable":
                thumbnail = NoImageAvailable;
                break;
            case "BlogTable":
                thumbnail = NoImageAvailable;
                break;
            case "BlogPreview":
                thumbnail = NoImageAvailable;
                break;
            case "NewsTable":
                thumbnail = NoImageAvailable;
                break;
            case "BirthdayTable":
                thumbnail = NoImageAvailable;
                break;
            case "SideContainer":
                thumbnail = NoImageAvailable;
                break;
            default:
                thumbnail = NoImageAvailable;
                break;
        }
        return thumbnail;
    }
}

const componentMap = new ComponentMap();
export default componentMap;