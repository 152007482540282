export class Services {
    /* Return a formated date and time */
    formatDateTime(dateTime, includeTime, isTextFormat, includeDay, isFullWords) {
        let timestamp = new Date(dateTime.toString());

        let year = timestamp.getFullYear();
        let month = timestamp.getMonth().toString().length === 1 ? '0' + (timestamp.getMonth() + 1).toString() : timestamp.getMonth() + 1;
        let date = timestamp.getDate().toString().length === 1 ? '0' + (timestamp.getDate()).toString() : timestamp.getDate();
        let day = timestamp.getDay();
        let hours = timestamp.getHours().toString().length === 1 ? '0' + timestamp.getHours().toString() : timestamp.getHours();
        let midday = hours >= 12 ? "PM" : "AM";
        if (hours == 0) {
            hours = 12;
        }
        if (hours >= 13) {
            hours = hours - 12;
            if (hours.toString().length === 1) hours = '0' + hours;
        }
        let minutes = timestamp.getMinutes().toString().length === 1 ? '0' + timestamp.getMinutes().toString() : timestamp.getMinutes();

        var abvMonths = isFullWords ?
            ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] :
            ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var abvWeekDays = isFullWords ?
            ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"] :
            ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

        let timestampFormat;
        if (isTextFormat) {
            if (includeDay) {
                timestampFormat = includeTime ?
                    (abvWeekDays[day] + ", " + abvMonths[month - 1] + " " + date + ", " + year + " " + hours + ":" + minutes + " " + midday) :
                    (abvWeekDays[day] + ", " + abvMonths[month - 1] + " " + date + ", " + year);
            }
            else {
                timestampFormat = includeTime ?
                    (abvMonths[month - 1] + " " + date + ", " + year + " " + hours + ":" + minutes + " " + midday) :
                    (abvMonths[month - 1] + " " + date + ", " + year);
            }
        }
        else {
            timestampFormat = includeTime ?
                (month + "/" + date + "/" + year + " " + hours + ":" + minutes + " " + midday) :
                (month + "/" + date + "/" + year);
        }

        return timestampFormat;
    }
}

const services = new Services();
export default services;