import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import services from '../../Services';

export class NewsManagement extends Component {
    static displayName = NewsManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            newsList: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let newsList = this.state.newsList;

        let tableRows = [];
        if (newsList.length > 0) {
            for (var i = 0; i < newsList.length; i++) {
                let news = newsList[i];

                let timestamp = new Date(news.timestamp.toString());
                let timestampFormat = services.formatDateTime(timestamp, true, false);

                let row = {
                    editNews: <a href={'UpdateNews/' + news.id} className='update-btn' title='Edit'><FontAwesomeIcon icon={faEdit} /></a>,
                    title: news.title,
                    type: news.types,
                    description: news.description,
                    //uploader: news.uploader,
                    timestamp: timestampFormat
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: '', field: 'editNews' },
                { label: 'Title', field: 'title', sort: 'asc' },
                { label: 'Types', field: 'type', sort: 'asc' },
                { label: 'Description', field: 'description', sort: 'asc' },
                //{ label: 'Uploader', field: 'uploader', sort: 'asc' },
                { label: 'Timestamp', field: 'timestamp', sort: 'asc' }
            ],
            rows: tableRows
        };

        return (
            <div className='news-management'>
                <div className='page-title'>
                    <h1>News Management</h1>
                </div>

                <div className='table-container'>
                    <a href='/CreateNews' title='Upload news'><button className='form-management-button'>Upload News</button></a>
                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('AdminNews/GetNews', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            newsList: data
        });
    }
}