import React, { Component, useState, version } from 'react';
import './pagecomponentform.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faEllipsisV, faEye } from '@fortawesome/free-solid-svg-icons';
import PageForm from '../PageForm/PageForm';
import adminSettingsService from '../AdminSettingsService';
import DnDCurrentComponents from './DnDCurrentComponents';
import FakeDnDCurrentComponents from './FakeDnDCurrentComponents';
import { v4 as uuid } from 'uuid';
import componentMap from '../../ComponentMap';
import PageVersionLogManagement from '../PageVersionLogManagement/PageVersionLogManagement';

// Authentication
import { getLoggedInUser } from "../../Authentication";

// Component Forms
import { AnnouncementForm } from '../ComponentForm/AnnouncementForm/AnnouncementForm';
import { BannerContainerForm } from '../ComponentForm/BannerForm/BannerContainerForm';
import { IconLinkContainerForm } from '../ComponentForm/IconLinkContainerForm/IconLinkContainerForm';
import { ColumnsTabContainerForm } from '../ComponentForm/ColumnsTabContainerForm/ColumnsTabContainerForm';
import { AccordionForm } from '../ComponentForm/AccordionForm/AccordionForm';
import { HtmlEditorForm } from '../ComponentForm/HtmlEditorForm/HtmlEditorForm';
import { BigText5050Form } from '../ComponentForm/BigText5050Form/BigText5050Form';
import { Contact5050Form } from '../ComponentForm/Contact5050Form/Contact5050Form';
import { Features1Form } from '../ComponentForm/Features1Form/Features1Form';
import { Grid5050Form } from '../ComponentForm/Grid5050Form/Grid5050Form';
import { ActionButtonForm } from '../ComponentForm/ActionButtonForm/ActionButtonForm';
import { SingleFeatures1Form } from '../ComponentForm/SingleFeatures1Form/SingleFeatures1Form';
import { HRSeparatorForm } from '../ComponentForm/HRSeparatorForm/HRSeparatorForm';
import { CardForm } from '../ComponentForm/CardForm/CardForm';
import { CardContainerForm } from '../ComponentForm/CardContainerForm/CardContainerForm';
import { CalendarForm } from '../ComponentForm/CalendarForm/CalendarForm';
import { DocumentTableForm } from '../ComponentForm/DocumentTableForm/DocumentTableForm';
import { EventTableForm } from '../ComponentForm/EventTableForm/EventTableForm';
import { BlogTableForm } from '../ComponentForm/BlogTableForm/BlogTableForm';
import { BlogPreviewForm } from '../ComponentForm/BlogPreviewForm/BlogPreviewForm';
import { NewsTableForm } from '../ComponentForm/NewsTableForm/NewsTableForm';
import { BirthdayTableForm } from '../ComponentForm/BirthdayTableForm/BirthdayTableForm';
import { SideContainerForm } from '../ComponentForm/SideContainerForm/SideContainerForm';

export class PageComponentForm extends Component {
    static displayName = PageComponentForm.name;

    constructor(props) {
        super(props);
        this.state = {
            currentCenter: null,
            tempCurrentCenter: null,
            resetCurrentComponents: false,
            resetPageDetails: false,
            componentSelection: { type: null, id: null },
            isFormInitialized: false,
            isDocumentInitialized: false,
            isEventInitialized: false,
            isBlogInitialized: false,
            isNewsInitialized: false,
            isBirthdayInitialized: false,
            columnTab: 'pageDetails',
            versionId: 1,
            versionStatus: 'draft',
            versionLimit: 10,

            page: null,
            pageComponents: null,
            allComponentTypes: null,
            loggedInUser: null,
            roles: [],

            allAnnouncements: null,
            allBannerContainers: null,
            allIconLinkContainers: null,
            allColumnsTabContainers: null,
            allAccordions: null,
            allHtmlEditors: null,
            allBigText5050s: null,
            allContact5050s: null,
            allSingleFeatures1s: null,
            allFeatures1s: null,
            allGrid5050s: null,
            allActionButtons: null,
            allHRSeparators: null,
            allCards: null,
            allCardContainers: null,
            allCalendars: null,
            allDocumentTables: null,
            allEventTables: null,
            allBlogTables: null,
            allBlogPreviews: null,
            allNewsTables: null,
            allBirthdayTables: null,
            allSideContainers: null
        };
    }

    componentDidMount() {
        this.getComponentData(true);
    }

    getComponentForm(name, id, formType, pageComponentId) {
        //console.log(name, id, formType, pageComponentId);
        if (isNaN(pageComponentId)) pageComponentId = undefined;
        //console.log(name, id, formType, pageComponentId);
        let centerComponent = null;
        
        switch (name) {
            case "Announcement":
                centerComponent = <AnnouncementForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "BannerContainer":
                centerComponent = <BannerContainerForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "IconLinkContainer":
                centerComponent = <IconLinkContainerForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "ColumnsTabContainer":
                centerComponent = <ColumnsTabContainerForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "Accordion":
                centerComponent = <AccordionForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "HtmlEditor":
                centerComponent = <HtmlEditorForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "BigText5050":
                centerComponent = <BigText5050Form
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "Contact5050":
                centerComponent = <Contact5050Form
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "SingleFeatures1":
                centerComponent = <SingleFeatures1Form
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "Features1":
                centerComponent = <Features1Form
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "Grid5050":
                centerComponent = <Grid5050Form
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "ActionButton":
                centerComponent = <ActionButtonForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "HRSeparator":
                centerComponent = <HRSeparatorForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "Card":
                centerComponent = <CardForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "CardContainer":
                centerComponent = <CardContainerForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "Calendar":
                centerComponent = <CalendarForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "DocumentTable":
                centerComponent = <DocumentTableForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "EventTable":
                centerComponent = <EventTableForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "BlogTable":
                centerComponent = <BlogTableForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "BlogPreview":
                centerComponent = <BlogPreviewForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "NewsTable":
                centerComponent = <NewsTableForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "BirthdayTable":
                centerComponent = <BirthdayTableForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            case "SideContainer":
                centerComponent = <SideContainerForm
                    componentId={id}
                    formType={formType}
                    pageId={this.state.page.id}
                    pageComponentId={pageComponentId}
                    versionStatus={this.state.versionStatus}
                    versionId={this.state.versionId}
                    onComponentSaved={this.onComponentSaved.bind(this)}
                    onComponentDeleted={this.onComponentDeleted.bind(this)} />;
                break;
            default:
                centerComponent = <div>ERROR</div>;
                break;
        }

        //console.log(centerComponent);
        this.setState({
            currentCenter: null,
            tempCurrentCenter: centerComponent,
            resetCurrentComponents: true,
            componentSelection: { type: name, id: id },
            columnTab: 'componentDetails'
        });

        this.getComponentData();
    }

    /* When the page component is saved, update the display via state change */
    async onComponentSaved() {
        //console.log("onComponentSaved");
        await this.setState({
            currentCenter: null,
            tempCurrentCenter: null,
            resetCurrentComponents: true,
            componentSelection: { type: null, id: null }
        });

        await this.getComponentData();
    }

    /* When the page component is deleted, update the display via state change */
    async onComponentDeleted() {
        await this.setState({
            currentCenter: null,
            tempCurrentCenter: null,
            resetCurrentComponents: true,
            componentSelection: { type: null, id: null }
        });

        await this.getComponentData();
    }

    /* Update display when the page component is saved */
    async onPageSaved() {
        await this.setState({
            resetCurrentComponents: true,
            resetPageDetails: true
        });

        await this.getComponentData();
    }

    resetCurrentCenter() {
        this.setState({
            currentCenter: this.state.tempCurrentCenter,
            tempCurrentCenter: null
        });
    }

    getExistingComponents(name) {
        let centerComponent = [];
        switch (name) {
            case "Announcement":
                for (var i = 0; i < this.state.allAnnouncements.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allAnnouncements[i].id, "Update")}>
                                {this.state.allAnnouncements[i].componentName != null ? this.state.allAnnouncements[i].componentName + "-V" + this.state.allAnnouncements[i].versionId : this.state.allAnnouncements[i].id + "-V" + this.state.allAnnouncements[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "BannerContainer":
                for (var i = 0; i < this.state.allBannerContainers.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allBannerContainers[i].id, "Update")}>
                                {this.state.allBannerContainers[i].componentName != null ? this.state.allBannerContainers[i].componentName + "-V" + this.state.allBannerContainers[i].versionId : this.state.allBannerContainers[i].id + "-V" + this.state.allBannerContainers[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "IconLinkContainer":
                for (var i = 0; i < this.state.allIconLinkContainers.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allIconLinkContainers[i].id, "Update")}>
                                {this.state.allIconLinkContainers[i].componentName != null ? this.state.allIconLinkContainers[i].componentName + "-V" + this.state.allIconLinkContainers[i].versionId : this.state.allIconLinkContainers[i].id + "-V" + this.state.allIconLinkContainers[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "ColumnsTabContainer":
                for (var i = 0; i < this.state.allColumnsTabContainers.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allColumnsTabContainers[i].id, "Update")}>
                                {this.state.allColumnsTabContainers[i].componentName != null ? this.state.allColumnsTabContainers[i].componentName + "-V" + this.state.allColumnsTabContainers[i].versionId : this.state.allColumnsTabContainers[i].id + "-V" + this.state.allColumnsTabContainers[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "Accordion":
                for (var i = 0; i < this.state.allAccordions.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allAccordions[i].id, "Update")}>
                                {this.state.allAccordions[i].componentName != null ? this.state.allAccordions[i].componentName + "-V" + this.state.allAccordions[i].versionId : this.state.allAccordions[i].id + "-V" + this.state.allAccordions[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "HtmlEditor":
                for (var i = 0; i < this.state.allHtmlEditors.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allHtmlEditors[i].id, "Update")}>
                                {this.state.allHtmlEditors[i].componentName != null ? this.state.allHtmlEditors[i].componentName + "-V" + this.state.allHtmlEditors[i].versionId : this.state.allHtmlEditors[i].id + "-V" + this.state.allHtmlEditors[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "BigText5050":
                for (var i = 0; i < this.state.allBigText5050s.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allBigText5050s[i].id, "Update")}>
                                {this.state.allBigText5050s[i].componentName != null ? this.state.allBigText5050s[i].componentName + "-V" + this.state.allBigText5050s[i].versionId : this.state.allBigText5050s[i].id + "-V" + this.state.allBigText5050s[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "Contact5050":
                for (var i = 0; i < this.state.allContact5050s.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allContact5050s[i].id, "Update")}>
                                {this.state.allContact5050s[i].componentName != null ? this.state.allContact5050s[i].componentName + "-V" + this.state.allContact5050s[i].versionId : this.state.allContact5050s[i].id + "-V" + this.state.allContact5050s[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "SingleFeatures1":
                for (var i = 0; i < this.state.allSingleFeatures1s.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allSingleFeatures1s[i].id, "Update")}>
                                {this.state.allSingleFeatures1s[i].componentName != null ? this.state.allSingleFeatures1s[i].componentName + "-V" + this.state.allSingleFeatures1s[i].versionId : this.state.allSingleFeatures1s[i].id + "-V" + this.state.allSingleFeatures1s[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "Features1":
                for (var i = 0; i < this.state.allFeatures1s.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allFeatures1s[i].id, "Update")}>
                                {this.state.allFeatures1s[i].componentName != null ? this.state.allFeatures1s[i].componentName + "-V" + this.state.allFeatures1s[i].versionId : this.state.allFeatures1s[i].id + "-V" + this.state.allFeatures1s[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "Grid5050":
                for (var i = 0; i < this.state.allGrid5050s.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allGrid5050s[i].id, "Update")}>
                                {this.state.allGrid5050s[i].componentName != null ? this.state.allGrid5050s[i].componentName + "-V" + this.state.allGrid5050s[i].versionId : this.state.allGrid5050s[i].id + "-V" + this.state.allGrid5050s[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "ActionButton":
                for (var i = 0; i < this.state.allActionButtons.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allActionButtons[i].id, "Update")}>
                                {this.state.allActionButtons[i].componentName != null ? this.state.allActionButtons[i].componentName + "-V" + this.state.allActionButtons[i].versionId : this.state.allActionButtons[i].id + "-V" + this.state.allActionButtons[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "HRSeparator":
                for (var i = 0; i < this.state.allHRSeparators.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allHRSeparators[i].id, "Update")}>
                                {this.state.allHRSeparators[i].componentName != null ? this.state.allHRSeparators[i].componentName + "-V" + this.state.allHRSeparators[i].versionId : this.state.allHRSeparators[i].id + "-V" + this.state.allHRSeparators[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "Card":
                for (var i = 0; i < this.state.allCards.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allCards[i].id, "Update")}>
                                {this.state.allCards[i].componentName != null ? this.state.allCards[i].componentName + "-V" + this.state.allCards[i].versionId : this.state.allCards[i].id + "-V" + this.state.allCards[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "CardContainer":
                for (var i = 0; i < this.state.allCardContainers.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allCardContainers[i].id, "Update")}>
                                {this.state.allCardContainers[i].componentName != null ? this.state.allCardContainers[i].componentName + "-V" + this.state.allCardContainers[i].versionId : this.state.allCardContainers[i].id + "-V" + this.state.allCardContainers[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "Calendar":
                for (var i = 0; i < this.state.allCalendars.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allCalendars[i].id, "Update")}>
                                {this.state.allCalendars[i].componentName != null ? this.state.allCalendars[i].componentName + "-V" + this.state.allCalendars[i].versionId : this.state.allCalendars[i].id + "-V" + this.state.allCalendars[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "DocumentTable":
                for (var i = 0; i < this.state.allDocumentTables.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allDocumentTables[i].id, "Update")}>
                                {this.state.allDocumentTables[i].componentName != null ? this.state.allDocumentTables[i].componentName + "-V" + this.state.allDocumentTables[i].versionId : this.state.allDocumentTables[i].id + "-V" + this.state.allDocumentTables[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "EventTable":
                for (var i = 0; i < this.state.allEventTables.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allEventTables[i].id, "Update")}>
                                {this.state.allEventTables[i].componentName != null ? this.state.allEventTables[i].componentName + "-V" + this.state.allEventTables[i].versionId : this.state.allEventTables[i].id + "-V" + this.state.allEventTables[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "BlogTable":
                for (var i = 0; i < this.state.allBlogTables.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allBlogTables[i].id, "Update")}>
                                {this.state.allBlogTables[i].componentName != null ? this.state.allBlogTables[i].componentName + "-V" + this.state.allBlogTables[i].versionId : this.state.allBlogTables[i].id + "-V" + this.state.allBlogTables[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "BlogPreview":
                for (var i = 0; i < this.state.allBlogPreviews.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allBlogPreviews[i].id, "Update")}>
                                {this.state.allBlogPreviews[i].componentName != null ? this.state.allBlogPreviews[i].componentName + "-V" + this.state.allBlogPreviews[i].versionId : this.state.allBlogPreviews[i].id + "-V" + this.state.allBlogPreviews[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "NewsTable":
                for (var i = 0; i < this.state.allNewsTables.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allNewsTables[i].id, "Update")}>
                                {this.state.allNewsTables[i].componentName != null ? this.state.allNewsTables[i].componentName + "-V" + this.state.allNewsTables[i].versionId : this.state.allNewsTables[i].id + "-V" + this.state.allNewsTables[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "BirthdayTable":
                for (var i = 0; i < this.state.allBirthdayTables.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allBirthdayTables[i].id, "Update")}>
                                {this.state.allBirthdayTables[i].componentName != null ? this.state.allBirthdayTables[i].componentName + "-V" + this.state.allBirthdayTables[i].versionId : this.state.allBirthdayTables[i].id + "-V" + this.state.allBirthdayTables[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            case "SideContainer":
                for (var i = 0; i < this.state.allSideContainers.length; i++) {
                    centerComponent.push(
                        <div className='existing-component' key={'existingComponent' + i}>
                            <button onClick={this.getComponentForm.bind(this, name, this.state.allSideContainers[i].id, "Update")}>
                                {this.state.allSideContainers[i].componentName != null ? this.state.allSideContainers[i].componentName + "-V" + this.state.allSideContainers[i].versionId : this.state.allSideContainers[i].id + "-V" + this.state.allSideContainers[i].versionId}
                            </button>
                        </div>
                    );
                }
                break;
            default:
                centerComponent = <div>ERROR</div>;
                break;
        }

        let centerComponentContainer = <div>
            <h3>{componentMap.componentTypeDisplay(name)} Components</h3>
            {centerComponent}
        </div>;

        this.setState({
            currentCenter: centerComponentContainer,
            resetCurrentComponents: true
        });

        this.getComponentData();
    }

    getComponentTypes(status) {
        let centerComponent;

        if (this.state.allComponentTypes != null) {
            let componentTypes = [];

            for (var i = 0; i < this.state.allComponentTypes.length; i++) {
                let componentType = this.state.allComponentTypes[i];
                
                if (!this.state.isFormInitialized && componentType == "Contact5050") {
                    continue;
                }
                if (!this.state.isDocumentInitialized && componentType == "DocumentTable") {
                    continue;
                }
                if (!this.state.isEventInitialized && componentType == "EventTable") {
                    continue;
                }
                if (!this.state.isBlogInitialized && (componentType == "BlogTable" || componentType == "BlogPreview")) {
                    continue;
                }
                if (!this.state.isNewsInitialized && componentType == "NewsTable") {
                    continue;
                }
                if (!this.state.isBirthdayInitialized && componentType == "BirthdayTable") {
                    continue;
                }
                if (componentType == "Navbar" || componentType == "Footer" || componentType == "SocialIcons") {
                    continue;
                }

                let key = status + 'ComponentType' + i;
                let onBtnClick = status == "existing" ? this.getExistingComponents.bind(this, componentType) : this.getComponentForm.bind(this, componentType, 0, 'Create');
                componentTypes.push(
                    <button className='component-type-button' onClick={onBtnClick} key={key}>
                        <span>{componentMap.componentTypeDisplay(this.state.allComponentTypes[i])}</span>
                        <img src={componentMap.getThumbnail(this.state.allComponentTypes[i])} />
                    </button>
                );
            }

            centerComponent = <div className='component-type-choice'>
                <h3>Component Types</h3>
                <div className='component-type-choice--grid'>
                    {componentTypes}
                </div>
            </div>;
        }
        else {
            centerComponent = <div>ERROR</div>;
        }

        this.setState({
            currentCenter: centerComponent,
            resetCurrentComponents: true,
            componentSelection: { type: null, id: null },
            columnTab: 'componentDetails'
        });

        this.getComponentData();
    }

    async deleteCurrentPageComponent(pageComponentId) {
        let confirmDelete = window.confirm("Are you sure you want to delete page component " + pageComponentId + "?");

        if (confirmDelete) {
            //let pageId = this.state.page.id;
            let pcId = parseInt(pageComponentId);
            const response = await fetch('Admin/DeletePageComponent?pageComponentId=' + pcId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                //window.location = '/UpdatePageComponent/' + pageId;
                this.setState({
                    resetCurrentComponents: true,
                    componentSelection: { type: null, id: null }
                });
                this.getComponentData();
                this.resetCurrentCenter();
            }
            else {
                alert(data);
            }
        }
    }

    /* Update the order of the components on the page */
    async componentOrderUpdate(componentArray) {
        let componentIdsOrdered = [];
        for (var i = 0; i < componentArray.length; i++) {
            componentIdsOrdered.push(parseInt(componentArray[i].id));
        }
        //console.log(componentIdsOrdered);
        //Save the order of components on backend where the array index is the order for the component associated with the id
        const response = await fetch('Admin/UpdatePageComponentOrder', {
            method: 'POST',
            body: JSON.stringify({ "PageComponentIds": componentIdsOrdered }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != "Saved") {
            alert(data);
        }
    }

    /* Return the component object that is associated with the page component */
    getComponentFromPageComponent(pageComponent) {
        let component = null;
        if (pageComponent != null) {
            switch (pageComponent.type) {
                case "Announcement":
                    for (var i = 0; i < this.state.allAnnouncements.length; i++) {
                        if (this.state.allAnnouncements[i].id == pageComponent.componentId) {
                            component = this.state.allAnnouncements[i];
                            break;
                        }
                    }
                    break;
                case "BannerContainer":
                    for (var i = 0; i < this.state.allBannerContainers.length; i++) {
                        if (this.state.allBannerContainers[i].id == pageComponent.componentId) {
                            component = this.state.allBannerContainers[i];
                            break;
                        }
                    }
                    break;
                case "IconLinkContainer":
                    for (var i = 0; i < this.state.allIconLinkContainers.length; i++) {
                        if (this.state.allIconLinkContainers[i].id == pageComponent.componentId) {
                            component = this.state.allIconLinkContainers[i];
                            break;
                        }
                    }
                    break;
                case "ColumnsTabContainer":
                    for (var i = 0; i < this.state.allColumnsTabContainers.length; i++) {
                        if (this.state.allColumnsTabContainers[i].id == pageComponent.componentId) {
                            component = this.state.allColumnsTabContainers[i];
                            break;
                        }
                    }
                    break;
                case "Accordion":
                    for (var i = 0; i < this.state.allAccordions.length; i++) {
                        if (this.state.allAccordions[i].id == pageComponent.componentId) {
                            component = this.state.allAccordions[i];
                            break;
                        }
                    }
                    break;
                case "HtmlEditor":
                    for (var i = 0; i < this.state.allHtmlEditors.length; i++) {
                        if (this.state.allHtmlEditors[i].id == pageComponent.componentId) {
                            component = this.state.allHtmlEditors[i];
                            break;
                        }
                    }
                    break;
                case "BigText5050":
                    for (var i = 0; i < this.state.allBigText5050s.length; i++) {
                        if (this.state.allBigText5050s[i].id == pageComponent.componentId) {
                            component = this.state.allBigText5050s[i];
                            break;
                        }
                    }
                    break;
                case "Contact5050":
                    for (var i = 0; i < this.state.allContact5050s.length; i++) {
                        if (this.state.allContact5050s[i].id == pageComponent.componentId) {
                            component = this.state.allContact5050s[i];
                            break;
                        }
                    }
                    break;
                case "SingleFeatures1":
                    for (var i = 0; i < this.state.allSingleFeatures1s.length; i++) {
                        if (this.state.allSingleFeatures1s[i].id == pageComponent.componentId) {
                            component = this.state.allSingleFeatures1s[i];
                            break;
                        }
                    }
                    break;
                case "Features1":
                    for (var i = 0; i < this.state.allFeatures1s.length; i++) {
                        if (this.state.allFeatures1s[i].id == pageComponent.componentId) {
                            component = this.state.allFeatures1s[i];
                            break;
                        }
                    }
                    break;
                case "Grid5050":
                    for (var i = 0; i < this.state.allGrid5050s.length; i++) {
                        if (this.state.allGrid5050s[i].id == pageComponent.componentId) {
                            component = this.state.allGrid5050s[i];
                            break;
                        }
                    }
                    break;
                case "ActionButton":
                    for (var i = 0; i < this.state.allActionButtons.length; i++) {
                        if (this.state.allActionButtons[i].id == pageComponent.componentId) {
                            component = this.state.allActionButtons[i];
                            break;
                        }
                    }
                    break;
                case "HRSeparator":
                    for (var i = 0; i < this.state.allHRSeparators.length; i++) {
                        if (this.state.allHRSeparators[i].id == pageComponent.componentId) {
                            component = this.state.allHRSeparators[i];
                            break;
                        }
                    }
                    break;
                case "Card":
                    for (var i = 0; i < this.state.allCards.length; i++) {
                        if (this.state.allCards[i].id == pageComponent.componentId) {
                            component = this.state.allCards[i];
                            break;
                        }
                    }
                    break;
                case "CardContainer":
                    for (var i = 0; i < this.state.allCardContainers.length; i++) {
                        if (this.state.allCardContainers[i].id == pageComponent.componentId) {
                            component = this.state.allCardContainers[i];
                            break;
                        }
                    }
                    break;
                case "Calendar":
                    for (var i = 0; i < this.state.allCalendars.length; i++) {
                        if (this.state.allCalendars[i].id == pageComponent.componentId) {
                            component = this.state.allCalendars[i];
                            break;
                        }
                    }
                    break;
                case "DocumentTable":
                    for (var i = 0; i < this.state.allDocumentTables.length; i++) {
                        if (this.state.allDocumentTables[i].id == pageComponent.componentId) {
                            component = this.state.allDocumentTables[i];
                            break;
                        }
                    }
                    break;
                case "EventTable":
                    for (var i = 0; i < this.state.allEventTables.length; i++) {
                        if (this.state.allEventTables[i].id == pageComponent.componentId) {
                            component = this.state.allEventTables[i];
                            break;
                        }
                    }
                    break;
                case "BlogTable":
                    for (var i = 0; i < this.state.allBlogTables.length; i++) {
                        if (this.state.allBlogTables[i].id == pageComponent.componentId) {
                            component = this.state.allBlogTables[i];
                            break;
                        }
                    }
                    break;
                case "BlogPreview":
                    for (var i = 0; i < this.state.allBlogPreviews.length; i++) {
                        if (this.state.allBlogPreviews[i].id == pageComponent.componentId) {
                            component = this.state.allBlogPreviews[i];
                            break;
                        }
                    }
                    break;
                case "NewsTable":
                    for (var i = 0; i < this.state.allNewsTables.length; i++) {
                        if (this.state.allNewsTables[i].id == pageComponent.componentId) {
                            component = this.state.allNewsTables[i];
                            break;
                        }
                    }
                    break;
                case "BirthdayTable":
                    for (var i = 0; i < this.state.allBirthdayTables.length; i++) {
                        if (this.state.allBirthdayTables[i].id == pageComponent.componentId) {
                            component = this.state.allBirthdayTables[i];
                            break;
                        }
                    }
                    break;
                case "SideContainer":
                    for (var i = 0; i < this.state.allSideContainers.length; i++) {
                        if (this.state.allSideContainers[i].id == pageComponent.componentId) {
                            component = this.state.allSideContainers[i];
                            break;
                        }
                    }
                    break;
                default:
                    component = null;
                    break;
            }
        }
        return component;
    }

    /* Update the current tab */
    switchTab(tabName) {
        this.setState({
            columnTab: tabName,
            resetCurrentComponents: true
        });

        this.getComponentData();
    }

    /* Update the version from the selection in the ddl */
    updateCurrentVersion(e) {
        let value = e.target.value.split("|");
        let versionId = parseInt(value[0]);
        this.setState({
            versionId: versionId,
            versionStatus: value[1],
            resetCurrentComponents: true,
            currentCenter: null,
            tempCurrentCenter: null,
            componentSelection: { type: null, id: null }
        });
        this.getComponentData();
    }

    /* Update the versions for a new draft */
    async createDraft(e) {
        e.preventDefault();
        document.body.style.cursor = 'wait';

        let confirmAction = window.confirm("Are you sure you want to create a new draft?");
        if (confirmAction) {
            const response = await fetch('Version/CreateDraft?pageId=' + this.state.page.id + "&copyVersionId=" + this.state.versionId + "&loggedInEmail=" + this.state.loggedInUser.email, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            this.setState({
                versionId: data,
                versionStatus: 'draft',
                resetCurrentComponents: true,
                currentCenter: null,
                tempCurrentCenter: null,
                componentSelection: { type: null, id: null }
            });
            this.getComponentData();
        }
        document.body.style.cursor = 'default';
    }

    /* Send current draft to pending for approval */
    async submitDraft(e) {
        e.preventDefault();
        document.body.style.cursor = 'wait';

        let confirmAction = window.confirm("Are you sure you want to submit this draft?");
        if (confirmAction) {
            let versionComments = window.prompt("Version Comments:");
            let pageLink = window.location;
            const response = await fetch('Version/SubmitDraft?pageId=' + this.state.page.id + "&loggedInEmail=" + this.state.loggedInUser.email + "&pageLink=" + pageLink + "&comments=" + versionComments, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            this.setState({
                versionStatus: 'pending',
                resetCurrentComponents: true,
                currentCenter: null,
                tempCurrentCenter: null,
                componentSelection: { type: null, id: null }
            });
            this.getComponentData();
        }
        document.body.style.cursor = 'default';
    }

    /* Send current pending version back to draft */
    async rejectDraft(e) {
        e.preventDefault();
        document.body.style.cursor = 'wait';

        let confirmAction = window.confirm("Are you sure you want to reject this draft?");
        if (confirmAction) {
            let versionComments = window.prompt("Version Comments:");
            let pageLink = window.location;
            const response = await fetch('Version/RejectDraft?pageId=' + this.state.page.id + "&loggedInEmail=" + this.state.loggedInUser.email + "&pageLink=" + pageLink + "&comments=" + versionComments, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            this.setState({
                versionStatus: 'draft',
                resetCurrentComponents: true,
                currentCenter: null,
                tempCurrentCenter: null,
                componentSelection: { type: null, id: null }
            });
            this.getComponentData();
        }
        document.body.style.cursor = 'default';
    }

    /* Make the current working draft live */
    async publishDraft(e) {
        e.preventDefault();
        document.body.style.cursor = 'wait';

        let confirmAction = window.confirm("Are you sure you want to publish this draft?");
        if (confirmAction) {
            let versionComments = window.prompt("Version Comments:");
            let pageLink = window.location;
            const response = await fetch('Version/PublishDraft?pageId=' + this.state.page.id + "&versionId=" + this.state.versionId + "&loggedInEmail=" + this.state.loggedInUser.email + "&pageLink=" + pageLink + "&comments=" + versionComments, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            this.setState({
                versionId: data,
                versionStatus: 'live',
                resetCurrentComponents: true,
                currentCenter: null,
                tempCurrentCenter: null,
                componentSelection: { type: null, id: null }
            });
            this.getComponentData();
        }
        document.body.style.cursor = 'default';
    }

    /* Delete the current working draft */
    async deleteDraft(e) {
        e.preventDefault();
        document.body.style.cursor = 'wait';

        let confirmAction = window.confirm("Are you sure you want to delete this draft?");
        if (confirmAction) {
            const response = await fetch('Version/DeleteDraft?pageId=' + this.state.page.id + "&loggedInEmail=" + this.state.loggedInUser.email, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            this.setState({
                resetCurrentComponents: true,
                currentCenter: null,
                tempCurrentCenter: null,
                componentSelection: { type: null, id: null }
            });
            this.getComponentData(true);
        }
        document.body.style.cursor = 'default';
    }

    render() {
        let page = this.state.page;
        let pageComponents = this.state.pageComponents;

        let managementData = this.state.currentCenter != null ? this.state.currentCenter :
            <div className='intro-data'>
                <h3>&nbsp;</h3>
                <div className='explination-container'>
                    <span>This area is used to add/update components and connect components to the selected page.</span>
                </div>
            </div>;

        let pageData;
        let pageNameTitle;
        if (page != null && !this.state.resetPageDetails) {
            pageData = <PageForm pageId={page.id} formType='Update' isExternal={page.isExternal} onPageSaved={this.onPageSaved.bind(this)} />;

            pageNameTitle = <h2>{page.name} Page & Component Management</h2>;
        }
        else {
            pageData = "";
            pageNameTitle = <h2>Page & Component Management</h2>;
        }

        let componentData;
        if (pageComponents != null) {
            var componentsOnPage = [];

            pageComponents.forEach((component, i) => {
                if (component.versionId == this.state.versionId) {
                    let key = 'currentComponents' + i;

                    let pageComponentId = component.id.toString();
                    let componentObj = this.getComponentFromPageComponent(component);
                    let isComponentSelected = (this.state.componentSelection.type == component.type && this.state.componentSelection.id == component.componentId) ? true : false;
                    let currentComponentClasses = isComponentSelected ? "current-component selected-component" : "current-component";
                    currentComponentClasses += this.state.versionStatus == 'draft' ? "" : " disable-dnd";

                    let currentComponentDnDIcon = this.state.versionStatus == 'draft' ?
                        <FontAwesomeIcon icon={faEllipsisV} className='current-component__drag-icon' /> :
                        "";

                    let currentComponentManagementBtns = this.state.versionStatus == 'draft' ?
                        <>
                            <span className='current-component__edit' onClick={this.getComponentForm.bind(this, component.type, component.componentId, 'Update', component.id)}><FontAwesomeIcon icon={faEdit} /></span>
                            <span className='current-component__delete' onClick={this.deleteCurrentPageComponent.bind(this, component.id)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                        </> :
                        <>
                            <span className='current-component__view' onClick={this.getComponentForm.bind(this, component.type, component.componentId, 'Update', component.id)}><FontAwesomeIcon icon={faEye} /></span>
                        </>;

                    let componentContent = <div key={key} className={currentComponentClasses}>
                        {currentComponentDnDIcon}
                        <div className='current-component__data'>
                            {(componentObj != null && componentObj.componentName != null) ? componentObj.componentName : component.componentId}
                            <br />
                        ({componentMap.componentTypeDisplay(component.type)})
                    </div>
                        <div className='current-component__manage'>
                            {currentComponentManagementBtns}
                        </div>
                    </div>;

                    componentsOnPage.push(
                        { id: pageComponentId, content: componentContent }
                    );
                }
            });

            let componentColumns = {
                [uuid()]: {
                    name: 'Current Components',
                    items: componentsOnPage
                }
            };

            let currentComponentDataDnD = (this.state.versionStatus == 'draft') ?
                <DnDCurrentComponents columns={componentColumns} componentOrderUpdate={this.componentOrderUpdate} /> :
                <FakeDnDCurrentComponents columns={componentColumns} />;
            if (this.state.resetCurrentComponents) currentComponentDataDnD = "";

            //Check if a draft is already available
            let hasDraft = false;
            if (pageComponents != null) {
                for (var i = 0; i < pageComponents.length; i++) {
                    if (pageComponents[i].status == "draft") {
                        hasDraft = true;
                        break;
                    }
                }
            }

            //Check if a pending is already available
            let hasPending = false;
            if (pageComponents != null) {
                for (var i = 0; i < pageComponents.length; i++) {
                    if (pageComponents[i].status == "pending") {
                        hasPending = true;
                        break;
                    }
                }
            }

            let versionOptions = [];
            if (pageComponents != null) {
                let sortedPageComponents = pageComponents;
                sortedPageComponents.sort((a, b) => (a.versionId > b.versionId) ? 1 : ((b.versionId > a.versionId) ? -1 : 0)); //Sort by versionId
                //console.log(sortedPageComponents);

                let lastVersionId = sortedPageComponents[0] != undefined ? sortedPageComponents[0].versionId : 1;
                let tempMostRecentPC = sortedPageComponents[0];
                let mostRecentVersionArray = [];
                for (var i = 0; i < sortedPageComponents.length; i++) {//Get array of most recent versions by versionId
                    if (sortedPageComponents[i].versionId == lastVersionId) {
                        if (sortedPageComponents[i].timestamp > tempMostRecentPC.timestamp) {
                            tempMostRecentPC = sortedPageComponents[i];
                        }
                    }
                    else {
                        mostRecentVersionArray.push(tempMostRecentPC);//Add most recent of previous version
                        lastVersionId = sortedPageComponents[i].versionId;
                        tempMostRecentPC = sortedPageComponents[i];
                    }
                }
                mostRecentVersionArray.push(tempMostRecentPC);//Add the last most recent pc
                //console.log(mostRecentVersionArray);

                mostRecentVersionArray.reverse();
                if (mostRecentVersionArray.length > 0) {
                    for (var i = 0; i < mostRecentVersionArray.length; i++) {
                        //Skip if version limit met
                        if ((i + 1) > this.state.versionLimit) {
                            break;
                        }

                        if (mostRecentVersionArray[i] != undefined) {
                            let versionOptKey = "version" + mostRecentVersionArray[i].versionId;
                            let timestamp = new Date(mostRecentVersionArray[i].timestamp);

                            versionOptions.push(<option key={versionOptKey} value={mostRecentVersionArray[i].versionId + "|" + mostRecentVersionArray[i].status}>
                                V{mostRecentVersionArray[i].versionId} {timestamp.getMonth() + 1}/{timestamp.getDate()}/{timestamp.getFullYear()} {mostRecentVersionArray[i].status != "backup" ? "(" + mostRecentVersionArray[i].status + ")" : ""}
                            </option>);
                        }
                    }
                }

                let today = new Date();
                let tempVersionOpts = <option value={this.state.versionId + "|" + this.state.versionStatus}>V{this.state.versionId} {today.getMonth() + 1}/{today.getDate()}/{today.getFullYear()} ({this.state.versionStatus})</option>;
                if (versionOptions.length == 0 || (!hasDraft && this.state.versionStatus == 'draft')) versionOptions.unshift(tempVersionOpts);
            }

            //Determine the version buttons to display
            let versionButtons = [];
            if (this.state.versionStatus == "live" || this.state.versionStatus == "backup") {
                if (!hasDraft && !hasPending) {
                    versionButtons.push(<button onClick={this.createDraft.bind(this)}>Create Draft</button>);
                }
            }
            else if (this.state.versionStatus == "draft") {
                if (hasDraft) {
                    versionButtons.push(<button onClick={this.submitDraft.bind(this)}>Submit</button>);
                    versionButtons.push(<button onClick={this.deleteDraft.bind(this)}>Delete</button>);
                }
                else {
                    versionButtons.push(<button onClick={this.deleteDraft.bind(this)}>Delete</button>);
                }
            }
            else if (this.state.versionStatus == "pending") {
                //console.log(this.state.loggedInUser);
                if (this.state.loggedInUser != null && (this.state.loggedInUser.role == "Admin")) {
                    versionButtons.push(<button onClick={this.publishDraft.bind(this)}>Publish</button>);
                    versionButtons.push(<button onClick={this.rejectDraft.bind(this)}>Reject</button>);
                }
            }

            let addComponentBtns = "";
            if (this.state.versionStatus == "draft") {
                if (this.state.loggedInUser != null && this.state.loggedInUser.company == "TracSoft" && this.state.loggedInUser.role == "Admin") {
                    addComponentBtns = <>
                        <div><button onClick={this.getComponentTypes.bind(this, 'existing')}>Add Existing Component</button></div>
                        <div><button onClick={this.getComponentTypes.bind(this, 'new')}>Add New Component</button></div>
                    </>;
                }
                else {
                    addComponentBtns = <>
                        <div><button onClick={this.getComponentTypes.bind(this, 'new')}>Add Component</button></div>
                    </>;
                }
            }

            let versionSelectValue = this.state.versionId + "|" + this.state.versionStatus;
            //console.log(versionSelectValue);
            
            let versionSelectDDL =
                <select id='versionSelect' className='version-select' defaultValue={versionSelectValue} onChange={this.updateCurrentVersion.bind(this)} >
                    {versionOptions}
                </select>;
            if (this.state.resetCurrentComponents) versionSelectDDL = "";
            componentData = <div>
                <h3>Page Components</h3>

                <div className='version-container'>
                    {versionSelectDDL}
                    <div className='version-button-container'>
                        {versionButtons}
                    </div>
                </div>

                {currentComponentDataDnD}

                {addComponentBtns}
            </div>;
            //console.log(document.getElementById("versionSelect"));
        }

        let pagePreviewLink = this.state.page != null ? 'PagePreview/' + this.state.page.id + "/" + this.state.versionId : "";

        if (this.state.tempCurrentCenter != null) {
            this.resetCurrentCenter();
        }

        let versionLog = this.state.page != null ? <PageVersionLogManagement pageId={this.state.page.id} /> : "";

        let tabData;
        if (this.state.columnTab == 'componentDetails') {
            let componentDetails = <div className='center-column current-tab'>
                {managementData}
            </div>;
            tabData = componentDetails;
        }
        else if (this.state.columnTab == 'pageVersionLog') {
            let versionLogDetails = <div className='right-column current-tab'>
                {versionLog}
            </div>;
            tabData = versionLogDetails;
        }
        else {
            let pageDetails = <div className='right-column current-tab'>
                {pageData}
            </div>;
            tabData = pageDetails;
        }

        let versionLogTab = this.state.loggedInUser != null && (this.state.loggedInUser.role == "Admin") ?
            <button className={this.state.columnTab == 'pageVersionLog' ? 'current-tab' : ''} onClick={this.switchTab.bind(this, 'pageVersionLog')}>Version Log</button> : "";

        let previewButton = <a href={pagePreviewLink} target="_blank" className='preview-button' style={{ float: 'right', margin: '0 10px' }}>PREVIEW</a>;
        if (this.state.page != null && this.state.page.isExternal) {
            componentData = "";
            previewButton = "";
        }

        return (
            <div className='grid-wrapper page-component-form'>
                <div className='tri-grid'>
                    <div className='tri-grid--full-center page-title'>
                        {pageNameTitle}
                    </div>

                    <div className='left-column'>
                        {componentData}
                    </div>

                    <div>
                        <div className='column-tabs'>
                            <button className={this.state.columnTab == 'componentDetails' ? 'current-tab' : ''} onClick={this.switchTab.bind(this, 'componentDetails')}>Component Details</button>
                            <button className={this.state.columnTab == 'pageDetails' ? 'current-tab' : ''} onClick={this.switchTab.bind(this, 'pageDetails')}>Page Details</button>
                            {versionLogTab}

                            {previewButton}
                        </div>

                        {tabData}
                    </div>
                </div>
            </div>
        );
    }

    async getComponentData(shouldSetVersion) {
        shouldSetVersion = shouldSetVersion == undefined || shouldSetVersion == null ? false : shouldSetVersion;
        let pageId = (this.props.pageId !== undefined) ? this.props.pageId : 0;

        const response = await fetch('Admin/GetPageComponentData?pageId=' + pageId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();
        //console.log(data);

        const isFormInitialized = await adminSettingsService.isFormInitialized();

        const isDocumentInitialized = await adminSettingsService.isDocumentInitialized();

        const isEventInitialized = await adminSettingsService.isEventInitialized();

        const isBlogInitialized = await adminSettingsService.isBlogInitialized();

        const isNewsInitialized = await adminSettingsService.isNewsInitialized();

        const isBirthdayInitialized = await adminSettingsService.isBirthdayInitialized();

        const versionLimitString = await adminSettingsService.getVersionLimit();
        var versionLimit = !isNaN(versionLimitString) ? parseInt(versionLimitString) : this.state.versionLimit;

        let versionId = this.state.versionId;
        let versionStatus = this.state.versionStatus;
        if (shouldSetVersion) {
            for (var i = 0; i < data.pageComponents.length; i++) { //Determine version
                //console.log(data.pageComponents[i]);
                if (data.pageComponents[i].status == 'draft' || data.pageComponents[i].status == 'pending') {
                    versionId = data.pageComponents[i].versionId;
                    versionStatus = data.pageComponents[i].status;
                    break;
                }
                else if (data.pageComponents[i].status == 'live') {
                    versionId = data.pageComponents[i].versionId;
                    versionStatus = data.pageComponents[i].status;
                }
            }
        }

        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        this.setState({
            resetCurrentComponents: false,
            resetPageDetails: false,
            isFormInitialized: isFormInitialized,
            isDocumentInitialized: isDocumentInitialized,
            isEventInitialized: isEventInitialized,
            isBlogInitialized: isBlogInitialized,
            isNewsInitialized: isNewsInitialized,
            isBirthdayInitialized: isBirthdayInitialized,
            versionId: versionId,
            versionStatus: versionStatus,
            versionLimit: versionLimit,

            page: data.page,
            pageComponents: data.pageComponents,
            allComponentTypes: data.allComponentTypes,
            loggedInUser: loggedInUser,

            allAnnouncements: data.allComponents.announcements,
            allBannerContainers: data.allComponents.bannerContainers,
            allIconLinkContainers: data.allComponents.iconLinkContainers,
            allColumnsTabContainers: data.allComponents.columnsTabContainers,
            allAccordions: data.allComponents.accordions,
            allHtmlEditors: data.allComponents.htmlEditors,
            allBigText5050s: data.allComponents.bigText5050s,
            allContact5050s: data.allComponents.contact5050s,
            allSingleFeatures1s: data.allComponents.singleFeatures1s,
            allFeatures1s: data.allComponents.features1s,
            allGrid5050s: data.allComponents.grid5050s,
            allActionButtons: data.allComponents.actionButtons,
            allHRSeparators: data.allComponents.hrSeparators,
            allCards: data.allComponents.cards,
            allCardContainers: data.allComponents.cardContainers,
            allCalendars: data.allComponents.calendars,
            allDocumentTables: data.allComponents.documentTables,
            allEventTables: data.allComponents.eventTables,
            allBlogTables: data.allComponents.blogTables,
            allBlogPreviews: data.allComponents.blogPreviews,
            allNewsTables: data.allComponents.newsTables,
            allBirthdayTables: data.allComponents.birthdayTables,
            allSideContainers: data.allComponents.sideContainers
        });
    }
}

export default PageComponentForm;