import React, { Component } from 'react';
import './documentupload.css';
import { getLoggedInUser } from "../../Authentication";
import TagsBySelection from '../TagsAndChips/TagsBySelection';
import TagsByInput from '../TagsAndChips/TagsByInput';
import { post } from 'axios';

export class DocumentUpload extends Component {
    static displayName = DocumentUpload.name;

    constructor(props) {
        super(props);
        this.state = {
            documentData: null,
            file: '',
            documentConfig: null,
            loggedInUser: null,
            formType: this.props.formType != undefined ? this.props.formType : 'Create'
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    setFile(e) {
        this.setState({ file: e.target.files[0] });
    } 

    handleChange = (e) => {
        let newDocument = this.state.documentData;
        newDocument[e.target.name] = e.target.value;
        this.setState({ documentData: newDocument });
    }

    async handleSubmit(e) {
        e.preventDefault();

        let document = this.state.documentData;

        if (this.state.formType == 'Create') {
            //Save file to public folder
            const url = `UploadFiles/UploadFile`;
            const formData = new FormData();
            formData.append('file', this.state.file);
            formData.append('folderName', "Documents");
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            let response = await post(url, formData, config);

            let docLocation = response.data;//Get save location of doc
            document.location = docLocation;

            document.uploader = this.state.loggedInUser.email;
            document.timestamp = new Date();
        }

        let url = this.state.formType == "Update" ? "UpdateDocument" : "CreateDocument";
        fetch('AdminDocument/' + url, {
            method: 'POST',
            body: JSON.stringify(document),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (this.state.formType == "Update") {
                        alert("Document Updated");
                    }
                    else {
                        window.location = '/DocumentManagement';
                    }
                }
                else {
                    alert(data);
                }
            }
        )
    }

    render() {
        let typeField;
        if (this.state.documentConfig != null) {
            let documentDataType = this.state.documentData != null ? this.state.documentData.type : "";
            let documentConfig = this.state.documentConfig;

            let types = documentDataType != "" && documentDataType != null ? documentDataType.split(',') : [];
            let typeSelections = documentConfig.types.split(',');

            let typesKeyVal = [];
            for (var i = 0; i < types.length; i++) {
                typesKeyVal.push({ key: types[i], value: types[i] });
            }

            let typeSelectionsKeyVal = [];
            for (var i = 0; i < typeSelections.length; i++) {
                typeSelectionsKeyVal.push({ key: typeSelections[i], value: typeSelections[i] });
            }

            typeField = <TagsBySelection
                tags={typesKeyVal}
                tagSelections={typeSelectionsKeyVal}
                name='type'
                isRequired={true}
                allowDuplicates={false}
                allowDelete={true}
                showInput={true}
                tagUpdate={this.handleChange}
            />;
        }

        let name;
        let descriptionField;
        if (this.state.documentData != null) {
            let doc = this.state.documentData;
            name = doc.name;

            descriptionField = <TagsByInput
                tags={doc.description}
                tagUpdate={this.handleChange}
                name='description'
                isRequired={false}
                allowDuplicates={false}
                inputType='text'
            />;
        }

        let documentField;
        if (this.state.formType == 'Create') {
            documentField = <>
                <label>Document: </label>
                <input type="file" onChange={e => this.setFile(e)} className='input-file-field' required />
                <div></div>
            </>;
        }

        let viewDocButton;
        if (this.state.formType == 'Update') {
            if (this.state.documentData != null) {
                viewDocButton = <a href={this.state.documentData.location} target="_blank" className='view-link tri-grid__1-3'>View Document</a>
            }
        }

        let deleteDocButton;
        let formBtnBuffer;
        if (this.state.formType == 'Update' && this.state.loggedInUser != null && this.state.loggedInUser.role == 'Admin' && this.state.documentData != null) {
            deleteDocButton = <div onClick={this.deleteDocument.bind(this)} className='cancel-btn'>Delete</div>;
            formBtnBuffer = <div style={{ padding: '0 5px', display: 'inline-block' }}></div>
        }

        let disabled = true;
        if (this.state.loggedInUser != null) {
            disabled = this.state.loggedInUser.role != "Admin";
        }

        return (
            <div className='document-upload-container'>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='form-title'>
                        <h3>Document Upload</h3>
                    </div>

                    <div className='form-fields'>
                        <div className='tri-grid-offset'>
                            {documentField}
                            {viewDocButton}

                            <label>Name: </label>
                            <input name='name' type='text' defaultValue={name} onChange={this.handleChange} required />
                            <div></div>

                            <label>Type: </label>
                            {typeField}
                            <div></div>

                            <label>Description: </label>
                            {descriptionField}
                            <div></div>
                        </div>
                    </div>

                    <div className='form-submit'>
                        <button type='submit' disabled={disabled} className='form-button'>Save</button>
                        {formBtnBuffer}
                        {deleteDocButton}
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let documentId = this.props.documentId !== undefined ? this.props.documentId : 0;
        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        const response = await fetch('AdminDocument/GetDocumentData?documentId=' + documentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            documentData: data.document,
            documentConfig: data.documentConfig,
            loggedInUser: loggedInUser
        });
    }

    async deleteDocument(e) {
        e.preventDefault();

        let documentId = this.state.documentData.id;
        let confirmDelete = window.confirm("Are you sure you want to delete this document?");

        if (confirmDelete) {
            let id = parseInt(documentId);
            const response = await fetch('AdminDocument/DeleteDocument?documentId=' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                window.location = '/DocumentManagement';
            }
            else {
                alert(data);
            }
        }
    }
}

export default DocumentUpload;