import React, { Component } from 'react';
import EventForm from './EventForm';

export class CreateEvent extends Component {
    static displayName = CreateEvent.name;

    render() {
        return (
            <EventForm eventId='0' formType='Create' />  
        );
    }
}