import React, { Component } from 'react';
import NewsForm from './NewsForm';

export class UpdateNews extends Component {
    static displayName = UpdateNews.name;

    constructor(props) {
        super(props);
        this.state = {
            newsId: this.props.match.params.newsId
        };
    }

    render() {
        return (
            <NewsForm newsId={this.state.newsId} formType='Update' />
        );
    }
}