import React, { Component } from 'react';
import { Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NoImageAvailable from './images/NoImageAvailable.png';

export class ImageGalleryField extends Component {
    static displayName = ImageGalleryField.name;

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            images: null,
            search: null
        };

        this.toggle = this.toggle.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.loadImages = this.loadImages.bind(this);
        this.chooseImage = this.chooseImage.bind(this);
    }

    async componentDidMount() {
        this.loadImages();
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    async handleSearchChange(e) {
        await this.setState({
            search: e.target.value
        });

        if (this.state.search != null) {
            if (this.state.search.length > 2) {
                this.setState({
                    images: this.state.images.filter(u => u.imageDescription.toLowerCase().includes(this.state.search.toLowerCase()))
                });
            }
            else if (this.state.search == 0) {
                this.loadImages();
            }
        }
    }

    async loadImages() {
        var response = await fetch('ImageUpload/RetrieveAll', {
            method: 'POST'
        });

        var images = await response.json();

        this.setState({
            images: images
        });
    }

    chooseImage(e) {
        var image = e.target;
        var url = image.getAttribute('src');

        let nameValuePair = { name: this.props.name, value: url };
        let fakeEvent = { target: nameValuePair };

        this.props.handleImageChange(fakeEvent);
        this.toggle();
    }

    render() {
        let currentImageUrl = this.props.currentImageUrl;
        if (currentImageUrl == null || currentImageUrl == "" || currentImageUrl == undefined) {
            currentImageUrl = NoImageAvailable;
        }

        let imageGallery;
        let search;
        if (this.state.images != null) {
            search = <Input type="text" value={this.state.search} onChange={this.handleSearchChange} placeholder="Enter 3 or more characters to search images.." />;
            imageGallery = this.state.images.map(image => {
                var imgSrc = "images/" + image.imageUrl;
                return <div id="imageContainer" key={imgSrc} ><div id="imageContainerTop"><img style={{ maxWidth: "100%", maxHeight: "150px" }} src={imgSrc} onClick={this.chooseImage} /></div><div id="imageContainerDescription">{image.imageDescription}</div></div>;
            });
        }

        let currentField = this.props.isDisabled ?
            <div className="current-image-gallery-field-container" style={currentImageGalleryFieldContainer}>
                <img id="currentImageUrl" src={currentImageUrl} style={{ maxWidth: "200px", maxHeight: "150px" }} />
            </div> :
            <div className="current-image-gallery-field-container" style={currentImageGalleryFieldContainerToggle} onClick={this.toggle}>
                <img id="currentImageUrl" src={currentImageUrl} style={{ maxWidth: "200px", maxHeight: "150px" }} />
            </div>;

        return (
            <div className='image-gallery-field-container'>
                <div>
                    {currentField}
                </div>

                <Modal style={{ maxWidth: "900px" }} isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Image Gallery</ModalHeader>
                    <ModalBody>
                        {search}
                        {imageGallery}
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const currentImageGalleryFieldContainer = {
    textAlign: 'left',
    position: 'relative',
    width: '200px',
    height: '150px',
    cursor: 'default'
}

const currentImageGalleryFieldContainerToggle = {
    textAlign: 'left',
    position: 'relative',
    width: '200px',
    height: '150px',
    cursor: 'pointer'
}

export default ImageGalleryField;
