import React, { Component } from 'react';
import './tagsandchips.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

/* This version of tags provides the user with a selection of tags to choose from. */
export class TagsBySelection extends Component {
    static displayName = TagsBySelection.name;

    constructor(props) {
        super(props);
        this.state = {
            tags: [], // [{id,tag:{key,value} }] *id is relevant only to this component
            tagSelections: [], // [{key,value}]
            isRequired: this.props.isRequired != undefined ? this.props.isRequired : false,
            allowDuplicates: this.props.allowDuplicates != undefined ? this.props.allowDuplicates : false,
            allowDelete: this.props.allowDelete != undefined ? this.props.allowDelete : true,
            showInput: this.props.showInput != undefined ? this.props.showInput : true,
            //minTags: this.props.minTags != undefined ? this.props.minTags : -1,
            //maxTags: this.props.maxTags != undefined ? this.props.maxTags : -1
            tagsByGrid: this.props.tagsByGrid != undefined ? this.props.tagsByGrid : [], // An array of a set of points [[rowStart, rowEnd, columnStart, columnEnd]]
        };
    }

    componentDidMount() {
        let tags = this.props.tags;// This should be an array of key/value pairs where the key will be saved and the value will be displayed
        let tagSelections = this.props.tagSelections;// This should be an array of key/value pairs

        //console.log(tags);
        //console.log(tagSelections);

        let tagPairArray = [];
        if (tags != undefined && tags != "" && tags != null) {
            for (var i = 0; i < tags.length; i++) {
                tagPairArray.push({ id: i, tag: { key: tags[i].key, value: tags[i].value } });
            }
        }

        this.setState({
            tags: tagPairArray,
            tagSelections: tagSelections
        });
    }

    /* Send data back to parent component */
    updateParentComponent() {
        let tagKeyOnlyArray = [];
        for (var i = 0; i < this.state.tags.length; i++) {
            tagKeyOnlyArray.push(this.state.tags[i].tag.key);
        }

        let tagString = tagKeyOnlyArray.join();
        let nameValuePair = { name: this.props.name, value: tagString };
        let fakeEvent = { target: nameValuePair };
        this.props.tagUpdate(fakeEvent);
    }

    /* Get the largest current tag id and return one larger */
    getNextTagId() {
        let currentTags = this.state.tags;
        let largestId = 0;
        for (var i = 0; i < currentTags.length; i++) {
            if (currentTags[i].id > largestId) largestId = currentTags[i].id;
        }
        return largestId + 1;
    }

    /* Convert selection to tags */
    handleChange = (e) => {
        let tagKey = e.target.value;
        let tag;
        for (var i = 0; i < this.state.tagSelections.length; i++) {
            if (this.state.tagSelections[i].key == tagKey) {
                tag = this.state.tagSelections[i];
                break;
            }
        }

        document.getElementById('tagDDL').value = "";// Reset the tag selection field
        if (this.state.allowDuplicates) {
            let newTags = this.state.tags;
            newTags.push({ id: this.getNextTagId(), tag: { key: tag.key, value: tag.value } });
            this.setState({
                tags: newTags
            });
            this.updateParentComponent();// Send data back to parent component
        }
        else {
            if (!this.isTagExisting(tagKey)) { // Check if the tag already exists
                let newTags = this.state.tags;
                newTags.push({ id: this.getNextTagId(), tag: { key: tag.key, value: tag.value } });
                this.setState({
                    tags: newTags
                });
                this.updateParentComponent();// Send data back to parent component
            }
            else {
                alert('Tag already exists');
            }
        }
    }

    /* Return true if a tag is already in the array, otherwise return false. Check by tag key (not id) */
    isTagExisting(tagKey) {
        let currentTags = this.state.tags;
        let isExisting = false;
        for (var i = 0; i < currentTags.length; i++) {
            if (currentTags[i].tag.key == tagKey) {
                isExisting = true;
                break;
            }
        }
        return isExisting;
    }

    /* Remove tag from array */
    removeTag = (tagId) => {
        let currentTags = this.state.tags;
        for (var i = 0; i < currentTags.length; i++) {
            if (currentTags[i].id == tagId) {
                currentTags.splice(i, 1);
            }
        }
        this.setState({ tags: currentTags });

        this.updateParentComponent();// Send data back to parent component
    }

    render() {
        let tags = this.state.tags;
        let tagSelections = this.state.tagSelections;

        //Current tags
        let tagIconWrapperClass = "";
        let tagIcons = [];
        if (this.state.tagsByGrid.length > 0) { // Create a grid of tags
            let tagsByGrid = this.state.tagsByGrid;
            for (var i = 0; i < tags.length; i++) {
                if (typeof tagsByGrid[i] !== 'undefined') {
                    tagIcons.push(
                        <div key={i} className='grid-tag' style={{ gridRowStart: tagsByGrid[i][0], gridRowEnd: tagsByGrid[i][1], gridColumnStart: tagsByGrid[i][2], gridColumnEnd: tagsByGrid[i][3] }}>
                            {tags[i].tag.value}
                        </div>
                    );
                }
                else { // Show extra tags if the gird and tag counts do not align, if this happens, there is an issue with the amount of grid arrays 
                    tagIcons.push(<div style={{ pointerEvents: 'none' }} key={i} className='tag'>{tags[i].tag.value} </div>);
                }
            }
            tagIconWrapperClass = "tag-grid-wrapper";
        }
        else { // Create normal tags
            for (var i = 0; i < tags.length; i++) {
                if (this.state.allowDelete) {
                    tagIcons.push(<div key={i} className='tag'>{tags[i].tag.value} <span className='remove-tag' onClick={this.removeTag.bind(this, tags[i].id)}><FontAwesomeIcon icon={faTimesCircle} /></span></div>);
                }
                else {
                    tagIcons.push(<div style={{ pointerEvents: 'none' }} key={i} className='tag'>{tags[i].tag.value} </div>);
                }
            }
            tagIconWrapperClass = "tag-wrapper";
        }
        
        //Tag Options
        let isRequired = (this.state.isRequired && this.state.tags.length < 1) ? true : false;
        //let minTagCount = (this.state.minTags > -1)
        let tagOpts = [];
        tagOpts.push(<option key='tagOpt0' value=''>--Select--</option>);
        for (var i = 0; i < tagSelections.length; i++) {
            //Only add if duplicate is allowed or it is not already selected
            if ((!this.state.allowDuplicates && !this.isTagExisting(tagSelections[i].key)) || (this.state.allowDuplicates)) {
                let optKey = 'tagOpt' + (i + 1);
                tagOpts.push(<option key={optKey} value={tagSelections[i].key}>{tagSelections[i].value}</option>);
            }
        }
        let tagDDL = this.state.showInput ? <select id='tagDDL' name='tagDDL' onChange={this.handleChange} required={isRequired}>{tagOpts}</select> : "";

        return (
            <div className='tags-and-chips-container'>
                {tagDDL}
                <div className={tagIconWrapperClass}>
                    {tagIcons}
                </div>
            </div>  
        );
    }
}

export default TagsBySelection;