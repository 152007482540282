import React, { Component } from 'react';
//import './socialIcons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare, faTwitterSquare, faYoutubeSquare, faLinkedin, faPinterestSquare, faTumblrSquare } from '@fortawesome/free-brands-svg-icons';
import { faBlog } from '@fortawesome/free-solid-svg-icons';

export class SocialIcons extends Component {
    static displayName = SocialIcons.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            facebookLink: null,
            instagramLink: null,
            twitterLink: null,
            youtubeLink: null,
            linkedinLink: null,
            pinterestLink: null,
            tumblrLink: null,
            blogLink: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let facebookLink = (this.state.facebookLink != null && this.state.facebookLink != "" && this.state.facebookLink != undefined) ? <a href={this.state.facebookLink} alt="facebook link" target="_blank"><FontAwesomeIcon icon={faFacebookSquare} alt="facebook icon" /> <span>Facebook</span></a> : null;
        let instagramLink = (this.state.instagramLink != null && this.state.instagramLink != "" && this.state.instagramLink != undefined) ? <a href={this.state.instagramLink} alt="instagram link" target="_blank"><FontAwesomeIcon icon={faInstagramSquare} alt="instagram icon" /> <span>Instagram</span></a> : null;
        let twitterLink = (this.state.twitterLink != null && this.state.twitterLink != "" && this.state.twitterLink != undefined) ? <a href={this.state.twitterLink} alt="twitter link" target="_blank"><FontAwesomeIcon icon={faTwitterSquare} alt="twitter icon" /> <span>Twitter</span></a> : null;
        let youtubeLink = (this.state.youtubeLink != null && this.state.youtubeLink != "" && this.state.youtubeLink != undefined) ? <a href={this.state.youtubeLink} alt="youtube link" target="_blank"><FontAwesomeIcon icon={faYoutubeSquare} alt="youtube icon" /> <span>YouTube</span></a> : null;
        let linkedinLink = (this.state.linkedinLink != null && this.state.linkedinLink != "" && this.state.linkedinLink != undefined) ? <a href={this.state.linkedinLink} alt="linkedin link" target="_blank"><FontAwesomeIcon icon={faLinkedin} alt="linkedin icon" /> <span>LinkedIn</span></a> : null;
        let pinterestLink = (this.state.pinterestLink != null && this.state.pinterestLink != "" && this.state.pinterestLink != undefined) ? <a href={this.state.pinterestLink} alt="pinterest link" target="_blank"><FontAwesomeIcon icon={faPinterestSquare} alt="pinterest icon" /> <span>Pinterest</span></a> : null;
        let tumblrLink = (this.state.tumblrLink != null && this.state.tumblrLink != "" && this.state.tumblrLink != undefined) ? <a href={this.state.tumblrLink} alt="tumblr link" target="_blank"><FontAwesomeIcon icon={faTumblrSquare} alt="tumblr icon" /> <span>Tumblr</span></a> : null;
        //let blogLink = (this.state.blogLink != null && this.state.blogLink != "" && this.state.blogLink != undefined) ? <a href={this.state.blogLink} alt="blog link" className='non-hollow-icon' target="_blank"><FontAwesomeIcon icon={faBlog} alt="blog icon" /></a> : null;

        let containerClasses = this.props.isInNavbar ? "social-icons social-icons--navbar" : "social-icons";
        return (
            <div className={containerClasses}>
                {facebookLink}
                {instagramLink}
                {twitterLink}
                {youtubeLink}
                {linkedinLink}
                {pinterestLink}
                {tumblrLink}
                {/*{blogLink}*/}
            </div>
        );
    }

    async getComponentData() {
        if (this.state.componentId != null && this.state.componentId != 0) {
            const respond = await fetch('Components/GetSocialInfo?componentId=' + this.state.componentId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await respond.json();

            if (data != null) {
                this.setState({
                    facebookLink: data.facebookLink,
                    instagramLink: data.instagramLink,
                    twitterLink: data.twitterLink,
                    youtubeLink: data.youtubeLink,
                    linkedinLink: data.linkedinLink,
                    pinterestLink: data.pinterestLink,
                    tumblrLink: data.tumblrLink,
                    blogLink: data.blogLink
                });
            }
        }
    }
}

export default SocialIcons;