import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import ImageGalleryField from '../../../ImageGalleryField';
import HtmlEditorField from '../../../HtmlEditorField';

export class Contact5050Form extends Component {
    static displayName = Contact5050Form.name;

    constructor(props) {
        super(props);
        this.state = {
            contact5050: null,
            formType: null,
            pageId: this.props.pageId,
            allForms: null,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newContact5050 = this.state.contact5050;

        // Handle direct changes
        newContact5050[e.target.name] = e.target.value;

        // Handle indirect changes
        if (e.target.name == "format") {
            newContact5050["imageLink"] = null;
            newContact5050["content"] = null;
        }

        this.setState({ contact5050: newContact5050 });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let contact5050 = this.state.contact5050;
        contact5050.versionId = this.state.versionId;
        contact5050.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateContact5050" : "CreateContact5050";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(contact5050),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                    var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "Contact5050", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    
                    //window.location = '/UpdatePageComponent/' + this.state.pageId;
                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        let title = null;
        let description = null;
        let formId = null;
        let imageLink = null;
        let componentName = null;
        let content = null;
        let format = null;

        if (this.state.contact5050 != null) {
            let contact5050 = this.state.contact5050;
            title = contact5050.title;
            description = contact5050.description;
            formId = contact5050.formId;
            imageLink = contact5050.imageLink;
            componentName = contact5050.componentName;
            content = contact5050.content;
            format = contact5050.format;
        }

        let formFieldOptions = [];
        formFieldOptions.push(<option key='formOpt0' value=''>--Select--</option>);
        if (this.state.allForms != null) {
            let allForms = this.state.allForms;
            for (var i = 0; i < allForms.length; i++) {
                let formOptKey = 'formOpt' + (i+1);
                formFieldOptions.push(<option key={formOptKey} value={allForms[i].id}>{allForms[i].name}</option>);
            }
        }

        let isImageGalleryFieldDisabled = this.state.versionStatus == 'draft' ? false : true;
        let editorWrapper = this.state.versionStatus == 'draft' ? 'editor-wrapper' : 'editor-wrapper disable-wrapper';
        let imageLinkField = <>
            <label>Image Link: </label>
            <input name='imageLink' type='text' defaultValue={imageLink} onChange={this.handleChange} required hidden />
            <ImageGalleryField name='imageLink' currentImageUrl={imageLink} handleImageChange={this.handleChange} isDisabled={isImageGalleryFieldDisabled} />
        </>;
        let contentField = <>
            <label>Content: </label>
            <div className={editorWrapper}>
                <HtmlEditorField content={content} handleEditorChange={this.handleChange} name='content' returnAsEvent={true} />
            </div>
        </>;
        if (format == "imageLeft") {
            contentField = "";
        }
        else if (format == "contentLeft") {
            imageLinkField = "";
        }
        else {
            contentField = "";
            imageLinkField = "";
        }

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('Contact5050')}</h3>
                    </div>
                    
                    <label>Component Name: </label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />
                                      
                    <label>Title: </label>
                    <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required />
                                    
                    <label>Description: </label>
                    <textarea name='description' rows="4" value={description} onChange={this.handleChange} required ></textarea>
                   
                    <label>Form: </label>
                    <select name='formId' value={formId} onChange={this.handleChange} required>
                        {formFieldOptions}
                    </select>

                    <label>Format: </label>
                    <select name='format' value={format} onChange={this.handleChange} required>
                        <option key='formatSelect' value=''>--Select--</option>
                        <option key='formatFull' value='full'>Full</option>
                        <option key='formatImageLeft' value='imageLeft'>Image</option>
                        <option key='formatContentLeft' value='contentLeft'>Content</option>
                    </select>

                    {imageLinkField}

                    {contentField}

                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetContact5050?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        //Get all form data
        const formResponse = await fetch('AdminForms/GetAllForms', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const formData = await formResponse.json();

        this.setState({
            contact5050: data,
            formType: formType,
            allForms: formData
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteContact5050?componentId=' + this.state.contact5050.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                //window.location = '/UpdatePageComponent/' + this.state.pageId;
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default Contact5050Form;