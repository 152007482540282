import React, { Component } from 'react';
import BlogForm from './BlogForm';

export class UpdateBlog extends Component {
    static displayName = UpdateBlog.name;

    constructor(props) {
        super(props);
        this.state = {
            blogId: this.props.match.params.blogId
        };
    }

    render() {
        return (
            <BlogForm blogId={this.state.blogId} formType='Update' />
        );
    }
}