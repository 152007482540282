import React, { Component } from 'react';

export class Accordion extends Component {
    static displayName = Accordion.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            accordion: null,
            accordionTabs: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    toggleTab = (e) => {
        // Get the tab bar even if the clicked element is a child of the bar element
        let tabBar = e.target;
        if (!tabBar.className.includes("accordion-header--close") && !tabBar.className.includes("accordion-header--open")) {
            tabBar = tabBar.parentElement;
        }

        // Change the display
        if (tabBar.className.includes("accordion-header--close")) {
            tabBar.classList.remove("accordion-header--close");
            tabBar.classList.add("accordion-header--open");

            tabBar.childNodes[1].innerHTML = "-";

            tabBar.nextSibling.classList.remove("accordion-content--close");
            tabBar.nextSibling.classList.add("accordion-content--open");
        }
        else if (tabBar.className.includes("accordion-header--open")) {
            tabBar.classList.remove("accordion-header--open");
            tabBar.classList.add("accordion-header--close");

            tabBar.childNodes[1].innerHTML = "+";

            tabBar.nextSibling.classList.remove("accordion-content--open");
            tabBar.nextSibling.classList.add("accordion-content--close");
        }
    }

    render() {
        let tabs = [];
        for (var i = 0; i < this.state.accordionTabs.length; i++) {
            let accordionTab = this.state.accordionTabs[i];
            let tabKey = "accordionTab" + i;

            let tab = <div key={tabKey} className='tab'>
                <div className='tab-header accordion-header--close' onClick={this.toggleTab}>
                    <span className='tab-title'>{accordionTab.title}</span>
                    <span className='toggle-icon'>+</span>
                </div>
                <div className='tab-content accordion-content--close'>
                    <div className="general-html-editor-wrapper" dangerouslySetInnerHTML={{ __html: accordionTab.content }}></div>
                </div>
            </div>;
            tabs.push(tab);
        }

        let headerContent = null;
        if (this.state.accordion != null) {
            let accordion = this.state.accordion;

            headerContent = accordion.headerContent;
        }

        let headerDiv = headerContent != null && headerContent != "" && headerContent != undefined ?
            <div className='general-html-editor-wrapper' dangerouslySetInnerHTML={{ __html: headerContent }}></div> : "";

        return (
            <div className='accordion-container'>
                {headerDiv}
                {tabs}
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetAccordion?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            accordion: data.accordion,
            accordionTabs: data.accordionTabs
        });
    }
}
