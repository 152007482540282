import React, { Component } from 'react';
import { removeLoginCookie, getLoggedInUser } from "../../Authentication";
import './admindashboard.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFile, faPaperclip, faObjectGroup, faPalette, faWarehouse, faTruckLoading, faBoxes, faLayerGroup, faAddressCard, faCog, faTools, faMapMarkedAlt, faFileAlt, faUsers, faFileUpload, faCalendarAlt, faBlog, faNewspaper, faBirthdayCake, faImage, faColumns } from '@fortawesome/free-solid-svg-icons';
import logo from '../../images/TracSoftLogo.png';
import adminSettingsService from '../AdminSettingsService';
import { User } from 'oidc-client';

export class AdminDashboard extends Component {
    static displayName = AdminDashboard.name;

    constructor(props) {
        super(props);
        this.state = {
            isFormInitialized: false,
            isDocumentInitialized: false,
            isEventInitialized: false,
            isBlogInitialized: false,
            isNewsInitialized: false,
            isBirthdayInitialized: false,
            loggedInUser: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleLogOut = (e) => {
        e.preventDefault();
        removeLoginCookie();
        window.location = '/Login';
    }

    toggleSideNav = () => {
        var sideNav = document.getElementById("sideNav");
        var sideNavStatus = sideNav.getAttribute("data-status");
        var links = sideNav.getElementsByTagName("a");
        var main = document.getElementById("main");

        if (sideNavStatus == "open") {
            sideNav.style.width = "75px";
            main.style.marginLeft = "75px";
            for (var i = 0; i < links.length; i++) {
                links[i].style.visibility = "hidden";
            }
            sideNav.setAttribute("data-status", "close");
        }
        else {
            sideNav.style.width = "250px";
            main.style.marginLeft = "250px";
            for (var i = 0; i < links.length; i++) {
                links[i].style.visibility = "visible";
            }
            sideNav.setAttribute("data-status", "open");
        }
    }

    render() {
        let navbarParentUrl = this.props.navbarParentUrl;
        let loggedInUser = this.state.loggedInUser;

        let formPages;
        if (this.state.isFormInitialized) {
            formPages = <a href='/FormBuilderManagement' className={navbarParentUrl == 'FormBuilderManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faFileAlt} className="icon" />Forms</a>;
        }

        let documentPages;
        if (this.state.isDocumentInitialized) {
            documentPages = <a href='/DocumentManagement' className={navbarParentUrl == 'DocumentManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faFileUpload} className="icon" />Documents</a>;
        }

        let eventPages;
        if (this.state.isEventInitialized) {
            eventPages = <a href='/EventManagement' className={navbarParentUrl == 'EventManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faCalendarAlt} className="icon" />Events</a>;
        }

        let blogPages;
        if (this.state.isBlogInitialized) {
            blogPages = <a href='/BlogManagement' className={navbarParentUrl == 'BlogManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faBlog} className="icon" />Blogs</a>;
        }

        let newsPages;
        if (this.state.isNewsInitialized && loggedInUser.role == "Admin") {
            newsPages = <a href='/NewsManagement' className={navbarParentUrl == 'NewsManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faNewspaper} className="icon" />Newsletter</a>;
        }

        let birthdayPages;
        if (this.state.isBirthdayInitialized && loggedInUser.role == "Admin") {
            birthdayPages = <a href='/BirthdayManagement' className={navbarParentUrl == 'BirthdayManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faBirthdayCake} className="icon" />Birthday</a>;
        }

        let imageModule = <a href='/ImageManagement' className={navbarParentUrl == 'ImageManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faImage} className="icon" />Images</a>;


        let tracSoftSettings = (loggedInUser != null && loggedInUser.role == "Admin" && loggedInUser.company == "TracSoft") ? <a href='/TracSoftSettings' className={navbarParentUrl == 'TracSoftSettings' ? 'nav-item selected-parent-page' : 'nav-item'} title='TracSoft Settings' ><FontAwesomeIcon icon={faTools} className="icon" /></a> : "";

        let settings;
        let siteInfo;
        let users;
        let roles;
        if (loggedInUser != null && (loggedInUser.role == "Admin")) {
            settings = <a href='/Settings' className={navbarParentUrl == 'Settings' ? 'nav-item settings-cog selected-parent-page' : 'nav-item settings-cog'} title='Settings'><FontAwesomeIcon icon={faCog} className="icon" /></a>;
            siteInfo = <a href='/LayoutManagement' className={navbarParentUrl == 'LayoutManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faMapMarkedAlt} className="icon" />Site Information</a>;
            users = <a href='UserManagement' className={navbarParentUrl == 'UserManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faUsers} className="icon" />Users</a>;
            roles = <a href='RoleManagement' className={navbarParentUrl == 'RoleManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faAddressCard} className="icon" />Roles</a>;
        }

        let sideNavPages = <a href='SideNavManagement' className={navbarParentUrl == 'SideNavManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faColumns} className="icon" />Side Navs</a>;

        return (
            <div>
                <div className='nav-container'>
                    <div id='topNav' className='top-nav'>
                        <h1><a href="AdminHome">TracSoft CMS</a></h1>
                        <p><FontAwesomeIcon icon={faBars} className="icon" onClick={this.toggleSideNav} /></p>

                        <h3 className="logout" onClick={this.handleLogOut}>Log Out</h3>

                        {settings}
                        {tracSoftSettings}
                    </div>
                    <div id='sideNav' data-status='open' className='side-nav'>
                        <a href="AdminHome" className='ts-logo-wrapper'><img className='ts-logo icon' src={logo} /></a>
                        <a href='/PageComponentManagement' className={navbarParentUrl == 'PageComponentManagement' ? 'selected-parent-page' : ''}><FontAwesomeIcon icon={faFile} className="icon" />Pages</a>
                        {sideNavPages}
                        {imageModule}
                        {formPages}
                        {documentPages}
                        {eventPages}
                        {blogPages}
                        {newsPages}
                        {birthdayPages}
                        {siteInfo}
                        {users}
                        {roles}
                    </div>
                </div>
                <div id='main'>
                    {this.props.component}
                </div>
            </div>
        );
    }

    async getComponentData() {
        let isFormInitialized = await adminSettingsService.isFormInitialized();
        let isDocumentInitialized = await adminSettingsService.isDocumentInitialized();
        let isEventInitialized = await adminSettingsService.isEventInitialized();
        let isBlogInitialized = await adminSettingsService.isBlogInitialized();
        let isNewsInitialized = await adminSettingsService.isNewsInitialized();
        let isBirthdayInitialized = await adminSettingsService.isBirthdayInitialized();

        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        this.setState({
            isFormInitialized: isFormInitialized,
            isDocumentInitialized: isDocumentInitialized,
            isEventInitialized: isEventInitialized,
            isBlogInitialized: isBlogInitialized,
            isNewsInitialized: isNewsInitialized,
            isBirthdayInitialized: isBirthdayInitialized,
            loggedInUser: loggedInUser
        });
    }
}

export default AdminDashboard;
