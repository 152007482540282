import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import AuthorizeAdminRoute from './components/api-authorization/AuthorizeAdminRoute';
import AuthorizeUserRoute from './components/api-authorization/AuthorizeUserRoute';
import { Layout } from './components/Layout';
import { PageComponentManagement } from './components/Admin/PageComponentManagement/PageComponentManagement';
import { CreatePage } from './components/Admin/PageForm/CreatePage/CreatePage';
import { UpdatePage } from './components/Admin/PageForm/UpdatePage/UpdatePage';
import { CreateExternalPage } from './components/Admin/PageForm/CreateExternalPage';
import { UpdateExternalPage } from './components/Admin/PageForm/UpdateExternalPage';
import { UpdatePageComponent } from './components/Admin/PageComponentForm/UpdatePageComponent/UpdatePageComponent';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './components/normalize.css';
import './components/Icons/css/all.css';
import './core.css';
import { Page } from './components/Pages';
import { AdminHome } from './components/Admin/AdminHome/AdminHome';
import { Login } from './components/Login/Login';
import { Settings } from './components/Admin/Settings/Settings';
import { LayoutManagement } from './components/Admin/LayoutManagement/LayoutManagement';
import { FormBuilderManagement } from './components/Admin/FormBuilderManagement/FormBuilderManagement';
import { CreateForm } from './components/Admin/FormForm/CreateForm';
import { FormBuilder } from './components/Admin/FormBuilder/FormBuilder';
import { FormBuilderSubmissions } from './components/Admin/FormBuilderSubmissions/FormBuilderSubmissions';
import { FormBuilderSingleSubmission } from './components/Admin/FormBuilderSingleSubmission/FormBuilderSingleSubmission';
import { TracSoftSettings } from './components/Admin/TracSoftSettings/TracSoftSettings';
import { PagePreview } from './components/Admin/PagePreview/PagePreview';
import { UserManagement } from './components/Admin/UserManagement/UserManagement';
import { RoleManagement } from './components/Admin/RoleManagement/RoleManagement';
import { CreateUser } from './components/Admin/UserForm/CreateUser';
import { UpdateUser } from './components/Admin/UserForm/UpdateUser';
import { DocumentManagement } from './components/Admin/DocumentManagement/DocumentManagement';
import { CreateDocument } from './components/Admin/DocumentUpload/CreateDocument';
import { UpdateDocument } from './components/Admin/DocumentUpload/UpdateDocument';
import { EventManagement } from './components/Admin/EventManagement/EventManagement';
import { CreateEvent } from './components/Admin/EventForm/CreateEvent';
import { UpdateEvent } from './components/Admin/EventForm/UpdateEvent';
import { Event } from './components/Event/Event';
import { BlogManagement } from './components/Admin/BlogManagement/BlogManagement';
import { CreateBlog } from './components/Admin/BlogForm/CreateBlog';
import { UpdateBlog } from './components/Admin/BlogForm/UpdateBlog';
import { Blog } from './components/Blog/Blog';
import { PreviewBlog } from './components/Blog/PreviewBlog';
import { NewsManagement } from './components/Admin/NewsManagement/NewsManagement';
import { CreateNews } from './components/Admin/NewsForm/CreateNews';
import { UpdateNews } from './components/Admin/NewsForm/UpdateNews';
import { BirthdayManagement } from './components/Admin/BirthdayManagement/BirthdayManagement';
import { ImageManagement } from './components/Admin/ImageManagement/ImageManagement';
import { SideNavManagement } from './components/Admin/SideNavManagement/SideNavManagement';
import { CreateSideNav } from './components/Admin/SideNavForm/CreateSideNav';
import { UpdateSideNav } from './components/Admin/SideNavForm/UpdateSideNav';

export default class App extends Component {
    static displayName = App.name;

    /*
     * ATTENTION
     * Hard coded url paths should be added to Models.GlobalComponents.HardCodedUrl.AllHardCodedUrls to avoid duplicate url creation with dynamic page creation 
     */
  render () {
    return (
        <Layout>
            <Switch>
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <Route exact path='/' component={Page} />
            
                <AuthorizeAdminRoute path='/PageComponentManagement' component={PageComponentManagement} navbarParentUrl='PageComponentManagement' />
                <AuthorizeAdminRoute path='/CreatePage' component={CreatePage} navbarParentUrl='PageComponentManagement' />
                <AuthorizeAdminRoute path='/UpdatePage/:pageId' component={UpdatePage} navbarParentUrl='PageComponentManagement' />
                <AuthorizeAdminRoute path='/CreateExternalPage' component={CreateExternalPage} navbarParentUrl='PageComponentManagement' />
                <AuthorizeAdminRoute path='/UpdateExternalPage/:pageId' component={UpdateExternalPage} navbarParentUrl='PageComponentManagement' />
                <AuthorizeAdminRoute path='/UpdatePageComponent/:pageId' component={UpdatePageComponent} navbarParentUrl='PageComponentManagement' />
                <AuthorizeAdminRoute path='/AdminHome/' component={AdminHome} />
                <AuthorizeAdminRoute path='/Settings' component={Settings} navbarParentUrl='Settings' />
                <AuthorizeAdminRoute path='/LayoutManagement' component={LayoutManagement} navbarParentUrl='LayoutManagement' />
                <AuthorizeAdminRoute path='/FormBuilderManagement' component={FormBuilderManagement} requireForm={true} navbarParentUrl='FormBuilderManagement' />
                <AuthorizeAdminRoute path='/CreateForm' component={CreateForm} requireForm={true} navbarParentUrl='FormBuilderManagement' />
                <AuthorizeAdminRoute path='/FormBuilder/:formId' component={FormBuilder} requireForm={true} navbarParentUrl='FormBuilderManagement' />
                <AuthorizeAdminRoute path='/FormBuilderSubmissions/:formId' component={FormBuilderSubmissions} requireForm={true} navbarParentUrl='FormBuilderManagement' />
                <AuthorizeAdminRoute path='/FormBuilderSingleSubmission/:formSubmissionId' component={FormBuilderSingleSubmission} requireForm={true} navbarParentUrl='FormBuilderManagement' />
                <AuthorizeAdminRoute path='/TracSoftSettings' component={TracSoftSettings} navbarParentUrl='TracSoftSettings' />
                <AuthorizeAdminRoute path='/PagePreview/:pageId/:versionId' component={PagePreview} navbarParentUrl='PageComponentManagement' />
                <AuthorizeAdminRoute path='/UserManagement' component={UserManagement} navbarParentUrl='UserManagement' />
                <AuthorizeAdminRoute path='/CreateUser' component={CreateUser} navbarParentUrl='UserManagement' />
                <AuthorizeAdminRoute path='/UpdateUser/:userId' component={UpdateUser} navbarParentUrl='UserManagement' />
                <AuthorizeAdminRoute path='/RoleManagement' component={RoleManagement} navbarParentUrl='RoleManagement' />
                <AuthorizeAdminRoute path='/DocumentManagement' component={DocumentManagement} requireDocument={true} navbarParentUrl='DocumentManagement' />
                <AuthorizeAdminRoute path='/CreateDocument' component={CreateDocument} requireDocument={true} navbarParentUrl='DocumentManagement' />
                <AuthorizeAdminRoute path='/UpdateDocument/:documentId' component={UpdateDocument} requireDocument={true} navbarParentUrl='DocumentManagement' />
                <AuthorizeAdminRoute path='/EventManagement' component={EventManagement} requireEvent={true} navbarParentUrl='EventManagement' />
                <AuthorizeAdminRoute path='/CreateEvent' component={CreateEvent} requireEvent={true} navbarParentUrl='EventManagement' />
                <AuthorizeAdminRoute path='/UpdateEvent/:eventId' component={UpdateEvent} requireEvent={true} navbarParentUrl='EventManagement' />
                <AuthorizeAdminRoute path='/BlogManagement' component={BlogManagement} requireBlog={true} navbarParentUrl='BlogManagement' />
                <AuthorizeAdminRoute path='/CreateBlog' component={CreateBlog} requireBlog={true} navbarParentUrl='BlogManagement' />
                <AuthorizeAdminRoute path='/UpdateBlog/:blogId' component={UpdateBlog} requireBlog={true} navbarParentUrl='BlogManagement' />
                <AuthorizeAdminRoute path='/PreviewBlog/:blogFriendlyUrl' component={PreviewBlog} requireBlog={true} navbarParentUrl='BlogManagement' />
                <AuthorizeAdminRoute path='/NewsManagement' component={NewsManagement} requireNews={true} navbarParentUrl='NewsManagement' />
                <AuthorizeAdminRoute path='/CreateNews' component={CreateNews} requireNews={true} navbarParentUrl='NewsManagement' />
                <AuthorizeAdminRoute path='/UpdateNews/:newsId' component={UpdateNews} requireNews={true} navbarParentUrl='NewsManagement' />
                <AuthorizeAdminRoute path='/BirthdayManagement' component={BirthdayManagement} requireBirthday={true} navbarParentUrl='BirthdayManagement' />
                <AuthorizeAdminRoute path='/ImageManagement' component={ImageManagement} navbarParentUrl='ImageManagement' />
                <AuthorizeAdminRoute path='/SideNavManagement' component={SideNavManagement} navbarParentUrl='SideNavManagement' />
                <AuthorizeAdminRoute path='/CreateSideNav' component={CreateSideNav} navbarParentUrl='SideNavManagement' />
                <AuthorizeAdminRoute path='/UpdateSideNav/:sideNavId' component={UpdateSideNav} navbarParentUrl='SideNavManagement' />

                <AuthorizeUserRoute path='/Event/:eventFriendlyUrl' component={Event} requireEvent={true} />
                <AuthorizeUserRoute path='/Blog/:blogFriendlyUrl' component={Blog} requireBlog={true} />

                <Route path='/Login/' component={Login} />
                <Route path='/:parentMenu/:childMenu/:subMenu/:friendlyUrl' component={Page} />
                <Route path='/:parentMenu/:childMenu/:friendlyUrl' component={Page} />
                <Route path='/:parentMenu/:friendlyUrl' component={Page} />
                <Route path='/:friendlyUrl' component={Page} />
            </Switch>
      </Layout>
    );
  }
}