import React, { Component } from 'react';
import PageForm from '../PageForm';
import '../pageform.css';

export class UpdatePage extends Component {
    static displayName = UpdatePage.name;

    constructor(props) {
        super(props);
        this.state = {
            pageId: this.props.match.params.pageId
        };
    }

    render() {
        return (
            <PageForm pageId={this.state.pageId} formType='Update' />
        );
    }
}