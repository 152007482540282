import React, { Component } from 'react';
import './birthdaymanagement.css';
import { post } from 'axios';

export class BirthdayManagement extends Component {
    static displayName = BirthdayManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            file: '',
            hasFile: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    setFile(e) {
        this.setState({ file: e.target.files[0] });
    }

    async handleSubmit(e) {
        e.preventDefault();

        //Save file to public folder
        const url = `UploadFiles/UploadOverwriteFile`;
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('folderName', "Birthdays");
        formData.append('fileName', "Birthdays");
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        let response = await post(url, formData, config);

        let docLocation = response.data;//Get save location of doc
            
        window.location = '/BirthdayManagement';
    }

    render() {
        let documentField;
        
        documentField = <>
            <label>Document: </label>
            <input type="file" accept=".csv" onChange={e => this.setFile(e)} className='input-file-field' required />
            <div></div>
        </>;
        

        let viewDocButton;
        let saveBtnText = "Upload";
        if (this.state.hasFile) {
            viewDocButton = <a href="/Birthdays/Birthdays.csv" target="_blank" className='view-link tri-grid__1-3'>View Document</a>;
            saveBtnText = "Overwrite";
        }

        return (
            <div className='birthday-management-container'>
                <form onSubmit={this.handleSubmit} className='grid-wrapper'>
                    <div className='form-title'>
                        <h3>Birthday Management</h3>
                    </div>

                    <div className='form-fields'>
                        <div className='tri-grid-offset'>
                            {viewDocButton}
                            {documentField}
                        </div>
                    </div>

                    <div className='form-submit'>
                        <button type='submit' className='form-button'>{saveBtnText}</button>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let localFilePath = 'Birthdays/Birthdays.csv';
        const response = await fetch('UploadFiles/HasFile?localFilePath=' + localFilePath, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const hasFile = await response.json();

        this.setState({
            hasFile: hasFile
        });
    }
}

export default BirthdayManagement;