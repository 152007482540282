import React, { Component } from 'react';
import { CustomLayout } from '../CustomLayout/CustomLayout';
import { StaticBreadcrumb } from '../Breadcrumb/StaticBreadcrumb';
import services from '../Services';
import MetaTags from 'react-meta-tags';

export class Blog extends Component {
    static displayName = Blog.name;

    constructor(props) {
        super(props);
        this.state = {
            blog: null,
            blogConfiguration: null,
            otherBlogs: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    formatAuthor(blog) {
        let author = blog.uploaderFirstName + " " + blog.uploaderLastName;
        return author;
    }

    getDistinctBlogTags(blog) {
        let blogCategories = blog.categories != null && blog.categories != "" && blog.categories != undefined ? blog.categories.split(',') : [];
        let blogTypes = blog.types != null && blog.types != "" && blog.types != undefined ? blog.types.split(',') : [];
        let tags = blogCategories;
        blogTypes.forEach(tag => { if (!tags.includes(tag)) { tags.push(tag) } });

        return tags;
    }

    render() {
        let title;
        let timestamp;
        let author;
        let content;
        let tagsFormat = [];
        let metaData;
        
        if (this.state.blog != null && this.state.blog.status == "Live" && this.state.blogConfiguration != null) {
            let blog = this.state.blog;
            let blogConfiguration = this.state.blogConfiguration;

            title = blog.title;
            timestamp = services.formatDateTime(blog.timestamp, false, true, true, true);
            author = this.formatAuthor(blog);
            content = blog.content;

            let tags = this.getDistinctBlogTags(blog);
            for (var i = 0; i < tags.length; i++) {
                tagsFormat.push(tags[i]);
                if (i + 1 < tags.length) {
                    tagsFormat.push(", ");
                }
            }

            metaData = <MetaTags>
                <title>{title}</title>
                <meta name="description" content={tags} />
                <meta name="keywords" content={tags} />
            </MetaTags>;
        }

        let staticBreadcrumbContent = <a href='/blogs'>BLOGS /</a>;

        let otherblogSnippets = [];
        for (var i = 0; i < this.state.otherBlogs.length; i++) {
            let otherBlog = this.state.otherBlogs[i];

            let backImg = (otherBlog.thumbnailImage != null && otherBlog.thumbnailImage != "") ? otherBlog.thumbnailImage :
                (this.state.blogConfiguration != null && this.state.blogConfiguration.defaultImage != null && this.state.blogConfiguration.defaultImage != "") ? this.state.blogConfiguration.defaultImage : "";
            let timestamp = services.formatDateTime(otherBlog.timestamp, false, true, false, true);
            let title = otherBlog.title;
            let blogUrl = '/blog/' + otherBlog.friendlyUrl;

            let snippet = <a href={blogUrl} className='blog-snippet' alt="blog image and link" style={{ backgroundImage: "url(" + backImg + ")" }}>
                <div className='snippet-text'>
                    <div className='super-text'>{timestamp}</div>
                    <div className='sub-text'>{title}</div>
                </div>
            </a>;
            otherblogSnippets.push(snippet);
        }

        return (
            <CustomLayout>
                {metaData}

                <StaticBreadcrumb content={staticBreadcrumbContent} />

                <div className='blog-container'>
                    <div className='blog-title'>{title}</div>
                    <div className='blog-upload'>{timestamp}</div>

                    <div className='blog-content' dangerouslySetInnerHTML={{ __html: content }}></div>

                    <div className='blog-tags'>{tagsFormat}</div>

                    <div className='other-blog-preview-container'>
                        <div className='other-blog-preview-title'>More Recent Blogs</div>

                        <div className='other-blog-grid'>
                            {otherblogSnippets}
                        </div>

                        <div className='other-blog-preview-see-all'>
                            <a href='/blogs'>See All</a>
                        </div>
                    </div>
                </div>
            </CustomLayout>
        );
    }

    async getComponentData() {
        let friendlyUrl = this.props.match.params.blogFriendlyUrl;

        const response = await fetch('components/GetBlog?friendlyUrl=' + friendlyUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            blog: data.blog,
            blogConfiguration: data.blogConfiguration,
            otherBlogs: data.otherBlogs
        });
    }
}
