import React, { Component, version } from 'react';
import './pageversionlogmanagement.css';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export class PageVersionLogManagement extends Component {
    static displayName = PageVersionLogManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            allVersionLogs: null,
            filteredVersionLogs: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let filteredVersionLogs = this.state.filteredVersionLogs;

        let tableRows = [];
        if (filteredVersionLogs != null) {
            for (var i = 0; i < filteredVersionLogs.length; i++) {
                let versionLog = filteredVersionLogs[i];

                let timestamp = new Date(versionLog.timestamp);
                let formatTimestamp = timestamp.getMonth() + 1 + "/" + timestamp.getDate() + "/" + timestamp.getFullYear()

                let row = {
                    email: versionLog.email,
                    action: versionLog.action,
                    comments: versionLog.comments != null ? <FontAwesomeIcon icon={faInfoCircle} className='info-icon' title={versionLog.comments} /> : "",
                    timestamp: formatTimestamp
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: 'Email', field: 'email', sort: 'asc' },
                { label: 'Action', field: 'action', sort: 'asc' },
                { label: 'Comments', field: 'comments', sort: 'asc' },
                { label: 'Timestamp', field: 'timestamp', sort: 'asc' }
            ],
            rows: tableRows
        };

        return (
            <div className='page-version-logs-container'>
                <h3 className='page-title'>Version Logs</h3>

                <div className='table-container'>
                    <MDBDataTable entries={10} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        let pageId = this.props.pageId;
        if (pageId != undefined) {
            const response = await fetch('Version/GetVersionLogsPerPage?pageId=' + pageId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            this.setState({
                allVersionLogs: data,
                filteredVersionLogs: data
            });
        }
    }
}

export default PageVersionLogManagement;