import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
import EventCalendar from './EventCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export class EventTable extends Component {
    static displayName = EventTable.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            eventTable: null,
            events: [],
            filteredEvents: [],
            eventConfiguration: null,
            calendarFilter: null,
            showPastEvents: false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    formatDateTime(dateTime, includeTime, isTextFormat) {
        let timestamp = new Date(dateTime.toString());

        let year = timestamp.getFullYear();
        let month = timestamp.getMonth().toString().length === 1 ? '0' + (timestamp.getMonth() + 1).toString() : timestamp.getMonth() + 1;
        let date = timestamp.getDate().toString().length === 1 ? '0' + (timestamp.getDate()).toString() : timestamp.getDate();
        let day = timestamp.getDay();
        let hours = timestamp.getHours().toString().length === 1 ? '0' + timestamp.getHours().toString() : timestamp.getHours();
        let midday = hours >= 12 ? "PM" : "AM";
        if (hours == 0) {
            hours = 12;
        }
        if (hours >= 13) {
            hours = hours - 12;
            if (hours.toString().length === 1) hours = '0' + hours;
        }
        let minutes = timestamp.getMinutes().toString().length === 1 ? '0' + timestamp.getMinutes().toString() : timestamp.getMinutes();

        var abvMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var abvWeekDays = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];

        let timestampFormat;
        if (isTextFormat) {
            timestampFormat = includeTime ?
                (abvWeekDays[day] + " " + abvMonths[month - 1] + " " + date + ", " + year + " " + hours + ":" + minutes + " " + midday):
                (abvWeekDays[day] + " " + abvMonths[month - 1] + " " + date + ", " + year);
        }
        else {
            timestampFormat = includeTime ?
                (month + "/" + date + "/" + year + " " + hours + ":" + minutes + " " + midday) :
                (month + "/" + date + "/" + year);
        }
        
        return timestampFormat;
    }

    sendToEventPage(eventLink) {
        return window.location = eventLink;
    }

    populateCalendarFilter = (date) => {
        let dateFormat = this.formatDateTime(date, false, false);

        this.setState({
            calendarFilter: dateFormat
        });

        document.getElementById("eventTable").scrollIntoView(true);
    }

    removeCalendarFilter = () => {
        this.setState({
            calendarFilter: null
        });

        document.getElementById("eventCalendarComponentRef").scrollIntoView(true);
    }

    togglePastEvents = (e) => {
        let showPastEvents = e.target.checked ? true : false;
        this.setState({ showPastEvents: showPastEvents })
    }

    render() {
        let eventTable = this.state.eventTable;
        let events = this.state.filteredEvents;

        let tableRows = [];
        let calendarEvents = [];
        if (events != null && events.length > 0 && eventTable != null) {
            for (var i = 0; i < events.length; i++) {
                let event = events[i];

                let thumbnailImageSrc = ((event.thumbnailImage == null || event.thumbnailImage == "") && this.state.eventConfiguration != null) ? this.state.eventConfiguration.defaultImage : event.thumbnailImage;
                let singleEventUrl = '/event/' + event.friendlyUrl;
                        
                let startTimeFormat = this.formatDateTime(event.startTime, true, false);
                let dateFormat = this.formatDateTime(event.startTime, false, false);

                let row = {
                    thumbnailImage: <img alt="thumbnail event image" src={thumbnailImageSrc} style={{ width: '110px', height: '65px' }} />,
                    title: event.title,
                    startTime: startTimeFormat,
                    clickEvent: () => this.sendToEventPage(singleEventUrl)
                };

                // Only display future events
                if (!this.state.showPastEvents) {
                    if (new Date(event.startTime.toString()) > new Date()) {
                        if (this.state.calendarFilter == null) {
                            tableRows.push(row);
                        }
                        else {
                            if (this.state.calendarFilter == dateFormat) {
                                tableRows.push(row);
                            }
                        }
                        calendarEvents.push(event);
                    }
                }
                else { // Display all events, including past events
                    if (this.state.calendarFilter == null) {
                        tableRows.push(row);
                    }
                    else {
                        if (this.state.calendarFilter == dateFormat) {
                            tableRows.push(row);
                        }
                    }
                    calendarEvents.push(event);
                }
            }
        }

        let table = {
            columns: [
                { label: '', field: 'thumbnailImage' },
                { label: 'Title', field: 'title', sort: 'asc' },
                { label: 'Start Time', field: 'startTime', sort: 'asc' },
            ],
            rows: tableRows
        };
        let mdbTable = <MDBDataTable entries={100} data={table} displayEntries={false} className='full-table' />;

        let calendarFilter;
        let eventTableClasses = "event-table";
        if (this.state.calendarFilter != null) {
            calendarFilter = <span className='calendar-event-filter'>
                {this.state.calendarFilter}
                <span className='calendar-event-filter-removal' onClick={this.removeCalendarFilter}><FontAwesomeIcon icon={faTimesCircle} /></span>
            </span>;

            eventTableClasses += " calendar-filter";
        }

        return (
            <div className='event-table-container'>
                <div className='checkbox-wrapper'>
                    <span className='checkbox-title'>Show Past Events: </span>
                    <input className='checkbox-filter' name='showPastEvents' type='checkbox' checked={this.state.showPastEvents} onChange={this.togglePastEvents} />
                </div>

                <div className='table-container'>
                    <div id='eventCalendarComponentRef'>
                        <EventCalendar events={calendarEvents} populateCalendarFilter={this.populateCalendarFilter} />
                    </div>

                    <div id='eventTable' className={eventTableClasses}>
                        {calendarFilter}
                        {mdbTable}
                    </div>
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetEventTable?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            eventTable: data.eventTable,
            events: data.events,
            filteredEvents: data.events,
            eventConfiguration: data.eventConfiguration
        });
    }
}
