import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import HtmlEditorField from '../../../HtmlEditorField';

export class SideContainerForm extends Component {
    static displayName = SideContainerForm.name;

    constructor(props) {
        super(props);
        this.state = {
            sideContainer: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newSideContainer = this.state.sideContainer;
        newSideContainer[e.target.name] = e.target.value;
        this.setState({ sideContainer: newSideContainer });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let sideContainer = this.state.sideContainer;
        sideContainer.versionId = this.state.versionId;
        sideContainer.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateSideContainer" : "CreateSideContainer";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(sideContainer),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                    var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "SideContainer", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        let componentName;
        let content1;
        let content2;

        if (this.state.sideContainer != null) {
            let sideContainer = this.state.sideContainer;
            componentName = sideContainer.componentName;
            content1 = sideContainer.content1;
            content2 = sideContainer.content2;
        }

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        let editorComponentWrapperClasses = this.state.versionStatus == 'draft' ? 'dual-grid__1-2 editor-wrapper' : 'dual-grid__1-2 editor-wrapper disable-wrapper';
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('SideContainer')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />

                    <div className='dual-grid__1-2'>
                        <div className='side-bar-grid'>
                            <div>
                                <div className={editorComponentWrapperClasses}>
                                    <HtmlEditorField content={content1} handleEditorChange={this.handleChange} name='content1' returnAsEvent={true} />
                                </div>
                            </div>

                            <div>
                                <div className={editorComponentWrapperClasses}>
                                    <HtmlEditorField content={content2} handleEditorChange={this.handleChange} name='content2' returnAsEvent={true} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetSideContainer?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            sideContainer: data,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteSideContainer?componentId=' + this.state.sideContainer.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default SideContainerForm;