import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEdit, faTrashAlt, faEllipsisV, faEye } from '@fortawesome/free-solid-svg-icons';
import IconLinkForm from './IconLinkForm';
import ComponentFormPopup from '../ComponentFormPopup';
import DnDIconLink from './DnDIconLink';
import FakeDnDIconLink from './FakeDnDIconLink';

export class IconLinkContainerForm extends Component {
    static displayName = IconLinkContainerForm.name;

    constructor(props) {
        super(props);
        this.state = {
            iconLinkContainer: null,
            iconLinks: [],
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false,
            popup: null,
            reset: false
        };
        this.displayPopup = this.displayPopup.bind(this);
        this.cancelClicked = this.cancelClicked.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteIconLink = this.deleteIconLink.bind(this);
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newIconLinkContainer = this.state.iconLinkContainer;

        newIconLinkContainer[e.target.name] = e.target.value;

        this.setState({ iconLinkContainer: newIconLinkContainer });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let iconLinkContainer = this.state.iconLinkContainer;
        iconLinkContainer.versionId = this.state.versionId;
        iconLinkContainer.pageId = this.state.pageId;

        let url = this.state.formType == "Update" ? "UpdateIconLinkContainer" : "CreateIconLinkContainer";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(iconLinkContainer),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "IconLinkContainer", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    displayPopup = (iconLinkId, formType) => {
        let popupForm = <IconLinkForm
            componentId={iconLinkId}
            formType={formType}
            pageId={this.state.pageId}
            pageComponentId={this.props.pageComponentId}
            versionStatus={this.state.versionStatus}
            versionId={this.state.versionId}
            onComponentSaved={this.onPopupComponentSaved.bind(this)}
            isEditOnly={true}
            isInPopup={true}
            iconLinkContainerId={this.state.iconLinkContainer.id}
        />;

        let popup = <ComponentFormPopup
            popupForm={popupForm}
            cancelClicked={this.cancelClicked.bind(this)}
        />;

        this.setState({
            popup: popup
        });
    }

    onPopupComponentSaved() {
        alert("Saved");

        this.setState({
            reset: true,
            popup: null
        });
        this.getComponentData();
    }

    cancelClicked() {
        this.setState({
            popup: null
        });
    }

    async iconLinkOrderUpdate(componentArray) {
        let componentIdsOrdered = [];
        for (var i = 0; i < componentArray.length; i++) {
            componentIdsOrdered.push(parseInt(componentArray[i].id));
        }

        const response = await fetch('Admin/UpdateIconLinkComponentOrder', {
            method: 'POST',
            body: JSON.stringify({ "iconLinkIds": componentIdsOrdered }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != "Saved") {
            alert(data);
        }
    }

    async deleteIconLink(iconLinkId) {
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteIconLink?componentId=' + iconLinkId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.setState({
                    reset: true
                });
                this.getComponentData();
            }
            else {
                alert(data);
            }
        }
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let componentName = null;

        if (this.state.iconLinkContainer != null) {
            let iconLinkContainer = this.state.iconLinkContainer;

            componentName = iconLinkContainer.componentName;
        }

        let mainFormClasses = "tri-grid";
        let sideMenu;
        let addNewIconLinkButton = null;
        let iconLinkButtons = [];
        if (this.state.formType == "Update") {
            addNewIconLinkButton = this.state.versionStatus == 'draft' ?
                <div onClick={this.displayPopup.bind(this, 0, 'Create')} className='side-menu-button'>Add Icon</div> : "";

            for (var i = 0; i < this.state.iconLinks.length; i++) {
                let iconLink = this.state.iconLinks[i];
                //console.log(iconLink);

                let iconLinkKey = "iconLink" + i;

                let currentComponentManagementBtns = this.state.versionStatus == 'draft' ?
                    <>
                        <span className='current-component__edit' onClick={this.displayPopup.bind(this, iconLink.id, 'Update')}><FontAwesomeIcon icon={faEdit} /></span>
                        <span className='current-component__delete' onClick={this.deleteIconLink.bind(this, iconLink.id)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                    </> :
                    <>
                        <span className='current-component__view' onClick={this.displayPopup.bind(this, iconLink.id, 'Update')}><FontAwesomeIcon icon={faEye} /></span>
                    </>;

                let iconLinkBtnClasses = this.state.versionStatus == 'draft' ? 'sub-component-button' : 'sub-component-button disable-dnd';

                let iconLinkBtn =
                    <div key={iconLinkKey} className={iconLinkBtnClasses}>

                        {iconLink.componentName}

                        <div className='current-component__manage'>
                            {currentComponentManagementBtns}
                        </div>
                    </div>;

                iconLinkButtons.push({ id: iconLink.id.toString(), content: iconLinkBtn });
            }

            let iconLinkDnD = (this.state.versionStatus == 'draft') ?
                <DnDIconLink items={iconLinkButtons} itemOrderUpdate={this.iconLinkOrderUpdate.bind(this)} /> :
                <FakeDnDIconLink items={iconLinkButtons} />;

            if (this.state.reset) {
                iconLinkDnD = "";
            }

            sideMenu = <div className='side-menu' style={{ paddingRight: '2%' }}>
                {addNewIconLinkButton}
                {iconLinkDnD}
            </div>;
        }
        else {
            mainFormClasses += " dual-grid__1-2";
        }

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <div className={wrapperClasses}>
                {this.state.popup}

                <form onSubmit={this.handleSubmit}>
                    <div className='dual-grid'>
                        {sideMenu}

                        <div className={mainFormClasses}>
                            <div className='tri-grid__1-3 form-title'>
                                <h3>{componentName} {componentMap.componentTypeDisplay('IconLinkContainer')}</h3>
                            </div>

                            <label>Component Name:</label>
                            <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />
                            <div></div>

                            <div className='tri-grid__1-3'>
                                <button type='submit'>Save</button>
                                {deleteButton}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetIconLinkContainer?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            iconLinkContainer: data.iconLinkContainer,
            iconLinks: data.iconLinks,
            formType: formType,
            reset: false,
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteIconLinkContainer?componentId=' + this.state.iconLinkContainer.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default IconLinkContainerForm;
