import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import TagsBySelection from '../../TagsAndChips/TagsBySelection';

export class BlogPreviewForm extends Component {
    static displayName = BlogPreviewForm.name;

    constructor(props) {
        super(props);
        this.state = {
            blogPreview: null,
            blogConfiguration: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newBlogPreview = this.state.blogPreview;
        newBlogPreview[e.target.name] = e.target.value;
        this.setState({ blogPreview: newBlogPreview });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let blogPreview = this.state.blogPreview;
        blogPreview.versionId = this.state.versionId;
        blogPreview.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateBlogPreview" : "CreateBlogPreview";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(blogPreview),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "BlogPreview", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let componentName;
        let title;
        let seeAllLink;
        let seeAllText;

        let typesField = null;

        if (this.state.blogPreview != null && this.state.blogConfiguration != null) {
            let blogPreview = this.state.blogPreview;
            let blogConfiguration = this.state.blogConfiguration;

            componentName = blogPreview.componentName;
            title = blogPreview.title;
            seeAllLink = blogPreview.seeAllLink;
            seeAllText = blogPreview.seeAllText;

            let types = blogPreview.types != "" && blogPreview.types != null ? blogPreview.types.split(',') : [];
            let typeSelections = blogConfiguration.types != "" && blogConfiguration.types != null ? blogConfiguration.types.split(',') : [];

            let typesKeyVal = [];
            for (var i = 0; i < types.length; i++) {
                typesKeyVal.push({ key: types[i], value: types[i] });
            }
            let typeSelectionsKeyVal = [];
            for (var i = 0; i < typeSelections.length; i++) {
                typeSelectionsKeyVal.push({ key: typeSelections[i], value: typeSelections[i] });
            }

            let allowDelete = this.state.versionStatus == 'draft' ? true : false;
            typesField = <TagsBySelection
                tags={typesKeyVal}
                tagSelections={typeSelectionsKeyVal}
                name='types'
                isRequired={true}
                allowDuplicates={false}
                allowDelete={allowDelete}
                showInput={true}
                tagUpdate={this.handleChange}
            />;
        }

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('BlogPreview')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />

                    <label>Types: </label>
                    {typesField}

                    <label>Title: </label>
                    <input name='title' type='text' defaultValue={title} onChange={this.handleChange} required />

                    <label>See All Link: </label>
                    <input name='seeAllLink' type='text' defaultValue={seeAllLink} onChange={this.handleChange} required />

                    <label>See All Text: </label>
                    <input name='seeAllText' type='text' defaultValue={seeAllText} onChange={this.handleChange} />

                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>  
        );
    };

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetBlogPreview?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            blogPreview: data.blogPreview,
            blogConfiguration: data.blogConfiguration,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteBlogPreview?componentId=' + this.state.blogPreview.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default BlogPreviewForm;