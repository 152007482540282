import React, { Component } from 'react';

export class BigText5050 extends Component {
    static displayName = BigText5050.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            bigText5050: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        let title;
        let body;
        let imageLink;
        if (this.state.bigText5050 != null) {
            title = this.state.bigText5050.title;
            body = this.state.bigText5050.body;
            imageLink = this.state.bigText5050.imageLink;
        }

        return (
            <div className="grid-full feature-5050-contain">
                <div className="grid-half text-left feature-5050">
                    <div>
                        <h1 className="lightweight">{title}<hr /></h1>
                        <p className="lightweight">{body}</p>
                    </div>
                    <div><img src={imageLink} /></div>
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('Components/GetBigText5050?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        if (data != null) {
            this.setState({
                bigText5050: data
            });
        }
    }
}