import React, { Component } from 'react';
import SideNavForm from './SideNavForm';

export class UpdateSideNav extends Component {
    static displayName = UpdateSideNav.name;

    constructor(props) {
        super(props);
        this.state = {
            sideNavId: this.props.match.params.sideNavId
        };
    }

    render() {
        return (
            <SideNavForm componentId={this.state.sideNavId} formType='Update' />
        );
    }
}
