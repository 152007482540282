import React, { Component } from 'react'

export class NavbarTabs extends Component {
    static displayName = NavbarTabs.name;

    constructor(props) {
        super(props);
        this.state = {
            navbarTabs: [],
            currentTab: 0
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    changeCurrentTab = (tabId) => {
        // Close tab if already open
        if (this.state.currentTab == tabId) {
            tabId = 0;
        }

        this.setState({
            currentTab: tabId
        });
    }

    getTabDisplay = (tab) => {
        let columns;
        switch (tab.format) {
            case "1-1-1-1":
                columns = <div className='columns-container columns__1-1-1-1'>
                    <div dangerouslySetInnerHTML={{ __html: tab.column1 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: tab.column2 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: tab.column3 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: tab.column4 }}></div>
                </div>;
                break;
            case "2-1-1":
                columns = <div className='columns-container columns__2-1-1'>
                    <div dangerouslySetInnerHTML={{ __html: tab.column1 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: tab.column3 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: tab.column4 }}></div>
                </div>;
                break;
            case "1-1-2":
                columns = <div className='columns-container columns__1-1-2'>
                    <div dangerouslySetInnerHTML={{ __html: tab.column1 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: tab.column2 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: tab.column3 }}></div>
                </div>;
                break;
            case "2-2":
                columns = <div className='columns-container columns__2-2'>
                    <div dangerouslySetInnerHTML={{ __html: tab.column1 }}></div>
                    <div dangerouslySetInnerHTML={{ __html: tab.column3 }}></div>
                </div>;
                break;
            default:
                break;
        }

        let footerContent = tab.footer != null && tab.footer != "" ?
            <div className='footer-container'>
                <div dangerouslySetInnerHTML={{ __html: tab.footer }}></div>
            </div> : null;

        let tabContents = <div className='tab-contents'>
            <div className='columns-wrapper'>
                {columns}
            </div>
            {footerContent}
        </div>;

        return tabContents;
    }

    render() {
        let currentTab = this.state.currentTab;
        let currentTabDisplay;
        let tabs = [];

        if (this.state.navbarTabs.length > 0) {
            let navbarTabs = this.state.navbarTabs;

            // Get current tab content
            if (currentTab == 0) {
                currentTabDisplay = null;
            }
            else {
                for (var i = 0; i < navbarTabs.length; i++) {
                    let tab = navbarTabs[i];
                    if (currentTab == tab.id) {
                        currentTabDisplay = this.getTabDisplay(tab);
                        break;
                    }
                }
            }

            // Get tabs
            for (var i = 0; i < navbarTabs.length; i++) {
                let navbarTab = navbarTabs[i];

                let tabClasses = "tab";
                if (currentTab == navbarTab.id) {
                    tabClasses += " current-tab";
                }

                //tabs.push(
                //    <div className={tabClasses}
                //        onMouseOver={this.changeCurrentTab.bind(this, navbarTab.id)}
                //        onClick={this.changeCurrentTab.bind(this, navbarTab.id)}>
                //    {navbarTab.title}
                //</div>);

                tabs.push(
                    <div className={tabClasses}
                        onClick={this.changeCurrentTab.bind(this, navbarTab.id)}>
                        {navbarTab.title}
                    </div>);
            }
        }

        return (
            <div className='navbar-tab-wrapper'>
                <div className='navbar-tab-row-wrapper'>
                    <div className='navbar-tab-container'>
                        <div className='tab-row'>
                            {tabs}
                        </div>
                    </div>
                </div>

                <div className='navbar-tab-contents-wrapper'>
                    <div className='navbar-tab-container'>
                        <div className='current-tab-contents'>
                            {currentTabDisplay}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetNavbarTabs', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            navbarTabs: data.navbarTabs
        });
    }
}

export default NavbarTabs;
