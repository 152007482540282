import React, { Component } from 'react';
//import './ContactInfo.css';
export class ContactInfo extends Component {
    static displayName = ContactInfo.name;

render() {
    return (
        <div className="header-contact-info">
            <a href={'tel:' + this.props.phoneNumber}><i className="fas fa-phone"></i><p>{this.props.phoneNumber}</p></a>
        </div>
    );
}
}