import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';

export class HRSeparatorForm extends Component {
    static displayName = HRSeparatorForm.name;

    constructor(props) {
        super(props);
        this.state = {
            hrSeparator: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.hrSeparator != null && !isNaN(this.state.hrSeparator.id)) {
            var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
            var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "HRSeparator", this.state.hrSeparator.id, parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
            
            //window.location = '/UpdatePageComponent/' + this.state.pageId;
            this.props.onComponentSaved();
        }
        else {
            alert("Error");
        }
    }

    render() {
        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentMap.componentTypeDisplay('HRSeparator')}</h3>
                    </div>

                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetHRSeparator', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            hrSeparator: data,
            formType: formType
        });
    }
}

export default HRSeparatorForm;