export class ComponentFormService {
    managePageComponent(pageId, type, componentId, pageComponentId, versionId, versionStatus) {
        //console.log(pageId, type, componentId, pageComponentId, versionId, versionStatus);
        fetch('Admin/ManagePageComponent', {
            method: 'POST',
            body: JSON.stringify({ "PageId": pageId, "Type": type, "ComponentId": componentId, "Id": pageComponentId, "Status": versionStatus, "VersionId": parseInt(versionId) }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                return data;
            }
        );
    }

    pageOrderNotification(formType) {
        return formType == "Update" ? "" : "*Leave blank to create component without adding to page.";
    }
}

const componentFormService = new ComponentFormService();
export default componentFormService;
