import React, { Component } from 'react';
import { CustomLayout } from '../CustomLayout/CustomLayout';
import services from '../Services';

export class PreviewBlog extends Component {
    static displayName = PreviewBlog.name;

    constructor(props) {
        super(props);
        this.state = {
            blog: null,
            blogConfiguration: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    formatAuthor(blog) {
        let author = blog.uploaderFirstName + " " + blog.uploaderLastName;
        return author;
    }

    getDistinctBlogTags(blog) {
        let blogCategories = blog.categories != null && blog.categories != "" && blog.categories != undefined ? blog.categories.split(',') : [];
        let blogTypes = blog.types != null && blog.types != "" && blog.types != undefined ? blog.types.split(',') : [];
        let tags = blogCategories;
        blogTypes.forEach(tag => { if (!tags.includes(tag)) { tags.push(tag) } });

        return tags;
    }

    render() {
        let title;
        let timestamp;
        let author;
        let content;
        let tagsFormat = [];

        if (this.state.blog != null && this.state.blogConfiguration != null) {
            let blog = this.state.blog;
            let blogConfiguration = this.state.blogConfiguration;

            title = blog.title;
            timestamp = services.formatDateTime(blog.timestamp, false, true, true, true);
            author = this.formatAuthor(blog);
            content = blog.content;

            let tags = this.getDistinctBlogTags(blog);
            for (var i = 0; i < tags.length; i++) {
                tagsFormat.push(tags[i]);
                if (i + 1 < tags.length) {
                    tagsFormat.push(", ");
                }
            }
        }

        return (
            <CustomLayout>
                <div className='blog-container'>
                    <div className='blog-title'>{title}</div>
                    <div className='blog-upload'>{timestamp}</div>

                    <div className='blog-content' dangerouslySetInnerHTML={{ __html: content }}></div>

                    <div className='blog-tags'>{tagsFormat}</div>
                </div>
            </CustomLayout>
        );
    }

    async getComponentData() {
        let friendlyUrl = this.props.match.params.blogFriendlyUrl;

        const response = await fetch('components/GetBlog?friendlyUrl=' + friendlyUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            blog: data.blog,
            blogConfiguration: data.blogConfiguration
        });
    }
}