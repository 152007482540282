import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import TagsBySelection from '../../TagsAndChips/TagsBySelection';

export class BirthdayTableForm extends Component {
    static displayName = BirthdayTableForm.name;

    constructor(props) {
        super(props);
        this.state = {
            birthdayTable: null,
            birthdayConfiguration: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus,
            limitEntries: false,
            isEditOnly: this.props.isEditOnly != undefined ? this.props.isEditOnly : false,
            isInPopup: this.props.isInPopup != undefined ? this.props.isInPopup : false,
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newBirthdayTable = this.state.birthdayTable;

        if (e.target.name == "isCompact") {
            newBirthdayTable[e.target.name] = e.target.checked ? true : false;
        }
        else if (e.target.name == "maxEntries") {
            newBirthdayTable[e.target.name] = parseInt(e.target.value);
        }
        else {
            newBirthdayTable[e.target.name] = e.target.value;
        }

        this.setState({ birthdayTable: newBirthdayTable });
    }

    handleLimitEntriesChange = (e) => {
        let limitEntries = e.target.checked ? true : false;
        if (limitEntries) {
            this.setState({
                limitEntries: limitEntries
            });
        }
        else {
            let newBirthdayTable = this.state.birthdayTable;
            newBirthdayTable['maxEntries'] = 0;

            this.setState({
                limitEntries: limitEntries,
                birthdayTable: newBirthdayTable
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let birthdayTable = this.state.birthdayTable;
        birthdayTable.versionId = this.state.versionId;
        birthdayTable.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateBirthdayTable" : "CreateBirthdayTable";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(birthdayTable),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    if (!this.state.isEditOnly) {
                        var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                        var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "BirthdayTable", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    }

                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        if (this.state.isInPopup) {
            deleteButton = null;
        }

        let componentName = null;
        let typesField = null;
        let isCompact = false;
        let maxEntries = 0;

        let limitEntries = this.state.limitEntries;

        if (this.state.birthdayTable != null && this.state.birthdayConfiguration != null) {
            let birthdayTable = this.state.birthdayTable;
            let birthdayConfiguration = this.state.birthdayConfiguration;

            componentName = birthdayTable.componentName;
            isCompact = birthdayTable.isCompact;
            maxEntries = birthdayTable.maxEntries;

            let types = birthdayTable.types != "" && birthdayTable.types != null ? birthdayTable.types.split(',') : [];
            let typeSelections = birthdayConfiguration.types != "" && birthdayConfiguration.types != null ? birthdayConfiguration.types.split(',') : [];

            let typesKeyVal = [];
            for (var i = 0; i < types.length; i++) {
                typesKeyVal.push({ key: types[i], value: types[i] });
            }
            let typeSelectionsKeyVal = [];
            for (var i = 0; i < typeSelections.length; i++) {
                typeSelectionsKeyVal.push({ key: typeSelections[i], value: typeSelections[i] });
            }

            let allowDelete = this.state.versionStatus == 'draft' ? true : false;
            typesField = <TagsBySelection
                tags={typesKeyVal}
                tagSelections={typeSelectionsKeyVal}
                name='types'
                isRequired={true}
                allowDuplicates={false}
                allowDelete={allowDelete}
                showInput={true}
                tagUpdate={this.handleChange}
            />;
        }

        let maxEntriesField;
        if (this.state.limitEntries) {
            maxEntriesField = <>
                <label>Max Entries: </label>
                <input name='maxEntries' type='number' defaultValue={maxEntries} onChange={this.handleChange} min={1} step={1} />
            </>;
        }

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('BirthdayTable')}</h3>
                    </div>

                    <label>Component Name:</label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />

                    <label>Departments: </label>
                    {typesField}

                    <label>Compact View: </label>
                    <div className='checkbox-wrapper'>
                        <input name='isCompact' type='checkbox' checked={isCompact} onChange={this.handleChange} />
                    </div>

                    <label>Limit Entries: </label>
                    <div className='checkbox-wrapper'>
                        <input name='limitEntries' type='checkbox' checked={limitEntries} onChange={this.handleLimitEntriesChange} />
                    </div>

                    {maxEntriesField}

                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetBirthdayTable?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        let limitEntries = data.birthdayTable.maxEntries > 0 ? true : false;

        this.setState({
            birthdayTable: data.birthdayTable,
            birthdayConfiguration: data.birthdayConfiguration,
            formType: formType,
            limitEntries: limitEntries
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteBirthdayTable?componentId=' + this.state.birthdayTable.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default BirthdayTableForm;
