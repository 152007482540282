import React, { Component } from 'react';

export class StaticBreadcrumb extends Component {
    static displayName = StaticBreadcrumb.name;

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let content = this.props.content;

        return (
            <div className='static-breadcrumb-wrap'>
                <div className='static-breadcrumb'>
                    {content}
                </div>
            </div>  
        );
    }
}

export default StaticBreadcrumb;
