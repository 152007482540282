import React, { Component } from 'react';
import '../componentform.css';
import componentFormService from '../ComponentFormService.js';
import componentMap from '../../../ComponentMap';
import TagsBySelection from '../../TagsAndChips/TagsBySelection';

export class Features1Form extends Component {
    static displayName = Features1Form.name;

    constructor(props) {
        super(props);
        this.state = {
            features1: null,
            singleFeatures1s: null,
            formType: null,
            pageId: this.props.pageId,
            versionId: this.props.versionId,
            versionStatus: this.props.versionStatus
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let newFeatures1 = this.state.features1;
        newFeatures1[e.target.name] = e.target.value;
        this.setState({ features1: newFeatures1 });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let features1 = this.state.features1;
        features1.versionId = this.state.versionId;
        features1.pageId = this.state.pageId;
        let url = this.state.formType == "Update" ? "UpdateFeatures1" : "CreateFeatures1";
        fetch('Admin/' + url, {
            method: 'POST',
            body: JSON.stringify(features1),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    var pageComponentId = this.props.pageComponentId != undefined ? this.props.pageComponentId : 0;
                    var isDataSaved = componentFormService.managePageComponent(this.state.pageId, "Features1", parseInt(data), parseInt(pageComponentId), this.state.versionId, this.state.versionStatus);
                    
                    //window.location = '/UpdatePageComponent/' + this.state.pageId;
                    this.props.onComponentSaved();
                }
                else {
                    alert(data);
                }
            }
        );
    }

    render() {
        let deleteButton = this.state.formType == "Update" ? <button className='delete-btn' onClick={this.deleteComponent.bind(this)}>Delete</button> : null;
        let singleFeatures1IdList = null;
        let componentName = null;
        //let allSingleFeatures = [];

        let isFeatures1Set = false;
        if (this.state.features1 != null) {
            let features1 = this.state.features1;
            singleFeatures1IdList = features1.singleFeatures1IdList;
            componentName = features1.componentName;

            isFeatures1Set = true;
        }

        let singleFeatures1ObjArray = [];
        let tagSelections = [];
        if (isFeatures1Set && this.state.singleFeatures1s != null) {
            //Get all options
            for (var i = 0; i < this.state.singleFeatures1s.length; i++) {
                let singleFeatures1 = this.state.singleFeatures1s[i];
                if (singleFeatures1.pageId == this.state.pageId) {
                    let sfName = (singleFeatures1.componentName != null && singleFeatures1.componentName != "") ? singleFeatures1.componentName + "-V" + singleFeatures1.versionId : singleFeatures1.id.toString() + "-V" + singleFeatures1.versionId;
                    tagSelections.push({ key: singleFeatures1.id.toString(), value: sfName });
                }
            }

            //Get current tags
            if (singleFeatures1IdList != null && singleFeatures1IdList != "" && singleFeatures1IdList != undefined) {
                let singleFeatures1IdArray = singleFeatures1IdList.split(',');
                for (var i = 0; i < singleFeatures1IdArray.length; i++) {
                    let sfId = singleFeatures1IdArray[i];
                    for (var j = 0; j < this.state.singleFeatures1s.length; j++) {
                        let singleFeatures1 = this.state.singleFeatures1s[j];
                        if (singleFeatures1.id.toString() == sfId) {
                            let sfName = (singleFeatures1.componentName != null && singleFeatures1.componentName != "") ? singleFeatures1.componentName : singleFeatures1.id.toString();
                            singleFeatures1ObjArray.push({ key: singleFeatures1.id.toString(), value: sfName });
                            break;
                        }
                    }
                }
            }
        }

        let allowDelete = this.state.versionStatus == 'draft' ? true : false;
        let singleFeatures1IdListField = isFeatures1Set ?
            <TagsBySelection
                tags={singleFeatures1ObjArray}
                tagSelections={tagSelections}
                tagUpdate={this.handleChange}
                name='singleFeatures1IdList'
                isRequired={true}
                allowDuplicates={true}
                allowDelete={allowDelete}
            /> : "";

        let wrapperClasses = this.state.versionStatus == 'draft' ? 'grid-wrapper component-form' : 'grid-wrapper component-form disable-form';
        return (
            <form onSubmit={this.handleSubmit} className={wrapperClasses}>
                <div className='dual-grid'>
                    <div className='dual-grid__1-2 form-title'>
                        <h3>{componentName} {componentMap.componentTypeDisplay('Features1')}</h3>
                    </div>

                    <label>Component Name: </label>
                    <input name='componentName' type='text' defaultValue={componentName} onChange={this.handleChange} required maxLength='15' />

                    <label>{componentMap.componentTypeDisplay('SingleFeatures1')} List: </label>
                    {/*<input name='singleFeatures1IdList' type='text' defaultValue={singleFeatures1IdList} onChange={this.handleChange} required />*/}
                    {singleFeatures1IdListField}

                    <div className='dual-grid__1-2'>
                        <button type='submit'>Save</button>
                        {deleteButton}
                    </div>
                </div>
            </form>
        );
    }

    async getComponentData() {
        let componentId = (this.props.componentId !== undefined) ? this.props.componentId : 0;
        let formType = this.props.formType !== undefined ? this.props.formType : 'Create';

        const response = await fetch('Admin/GetFeatures1?componentId=' + componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            features1: data.features1,
            singleFeatures1s: data.singleFeatures1s,
            formType: formType
        });
    }

    async deleteComponent(e) {
        e.preventDefault();
        let confirmDelete = window.confirm("Are you sure you want to delete this component and remove all associations to it?");

        if (confirmDelete) {
            const response = await fetch('Admin/DeleteFeatures1?componentId=' + this.state.features1.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data === "Deleted") {
                //window.location = '/UpdatePageComponent/' + this.state.pageId;
                this.props.onComponentDeleted();
            }
            else {
                alert(data);
            }
        }
    }
}

export default Features1Form;