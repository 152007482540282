import React, { Component } from 'react';

export class FormSubmissionsMassRange extends Component {
    static displayName = FormSubmissionsMassRange.name;

    constructor(props) {
        super(props);
        this.state = {
            formSubmissionMassRangeData: null,
            activationButtonClicked: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let formSubmissionMassRangeData = this.state.formSubmissionMassRangeData;

        formSubmissionMassRangeData[e.target.name] = e.target.value;

        //Handle result changes
        if (e.target.name == "rangeOpt") {
            formSubmissionMassRangeData.date2 = "0001-01-01T00:00:00";
        }

        this.setState({ formSubmissionMassRangeData: formSubmissionMassRangeData });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.activationButtonClicked == "export") {
            this.handleExport();
        }
        else if (this.state.activationButtonClicked == "delete") {
            this.handleDelete();
        }
    }

    handleExport() {
        let formSubmissionMassRangeData = this.state.formSubmissionMassRangeData;

        fetch('AdminForms/GetFormSubmissionExport', {
            method: 'POST',
            body: JSON.stringify(formSubmissionMassRangeData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                let form = data.form;
                let submissionArrays = data.submissionArrays;

                //Export data
                let exportRangeName = formSubmissionMassRangeData.rangeOpt == "between" ? formSubmissionMassRangeData.rangeOpt + "_" + formSubmissionMassRangeData.date1 + "_" + formSubmissionMassRangeData.date2 :
                    formSubmissionMassRangeData.rangeOpt + "_" + formSubmissionMassRangeData.date1
                let csvFileName = form.name + "_" + exportRangeName + ".csv";
                let csvRows = [];
                for (var i = 0; i < submissionArrays.length; i++) {
                    var submissionArray = submissionArrays[i];
                    csvRows.push(submissionArray);
                }

                let csvContent = "data:text/csv;charset=utf-8,"
                    + csvRows.map(e => e.join(",")).join("\n");

                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", csvFileName);
                document.body.appendChild(link);

                link.click();

                //this.getComponentData();
            }
        );
    }

    handleDelete() {
        let confirmWarning = window.confirm("Are you sure you want to delete the chosen range of for submissions?");

        if (confirmWarning) {
            let formSubmissionMassRangeData = this.state.formSubmissionMassRangeData;

            fetch('AdminForms/DeleteFormSubmissionsByDateRange', {
                method: 'POST',
                body: JSON.stringify(formSubmissionMassRangeData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (data == "Deleted") {
                        alert("Form Submissions Deleted");
                        window.location = "/FormBuilderSubmissions/" + this.props.formId;
                    }
                    else {
                        alert(data);
                    }
                }
            );
        }
    }

    render() {
        let rangeOpt;
        let date1Format;
        let date2Format;

        let date2Field = <div></div>;

        if (this.state.formSubmissionMassRangeData != null) {
            let formSubmissionMassRangeData = this.state.formSubmissionMassRangeData;
            rangeOpt = formSubmissionMassRangeData.rangeOpt;

            let date1Raw = new Date(formSubmissionMassRangeData.date1);
            let date1Year = date1Raw.getFullYear();
            let date1Month = (date1Raw.getMonth() + 1).toString().length === 1 ? '0' + (date1Raw.getMonth() + 1).toString() : date1Raw.getMonth() + 1;
            let date1Date = date1Raw.getDate().toString().length === 1 ? '0' + (date1Raw.getDate()).toString() : date1Raw.getDate();

            date1Format = date1Year + '-' + date1Month + '-' + date1Date;

            let date2Raw = new Date(formSubmissionMassRangeData.date2);
            let date2Year = date2Raw.getFullYear();
            let date2Month = (date2Raw.getMonth() + 1).toString().length === 1 ? '0' + (date2Raw.getMonth() + 1).toString() : date2Raw.getMonth() + 1;
            let date2Date = date2Raw.getDate().toString().length === 1 ? '0' + (date2Raw.getDate()).toString() : date2Raw.getDate();

            date2Format = date2Year + '-' + date2Month + '-' + date2Date;

            let date2MinRaw = new Date(formSubmissionMassRangeData.date1);
            date2MinRaw.setDate(date2MinRaw.getDate() + 2);
            let date2MinYear = date2MinRaw.getFullYear();
            let date2MinMonth = (date2MinRaw.getMonth() + 1).toString().length === 1 ? '0' + (date2MinRaw.getMonth() + 1).toString() : date2MinRaw.getMonth() + 1;
            let date2MinDate = date2MinRaw.getDate().toString().length === 1 ? '0' + (date2MinRaw.getDate()).toString() : date2MinRaw.getDate();

            let date2MinFormat = date2MinYear + '-' + date2MinMonth + '-' + date2MinDate;

            if (rangeOpt == "between") {
                date2Field = <div className='field-box'>
                    <label>Date 2</label>
                    <input name='date2' type='date' defaultValue={date2Format} min={date2MinFormat} onChange={this.handleChange} required />
                </div>;
            }
        }

        return (
            <div className='form-submission-mass-range-container'>
                <form onSubmit={this.handleSubmit} className='form-submission-mass-range-form'>
                    <div className='field-box'>
                        <label>Date Range</label>
                        <select name='rangeOpt' value={rangeOpt} onChange={this.handleChange} required>
                            <option value=''>--Select--</option>
                            <option value='before'>Before</option>
                            <option value='after'>After</option>
                            <option value='between'>Between</option>
                        </select>
                    </div>

                    <div className='field-box'>
                        <label>Date 1</label>
                        <input name='date1' type='date' defaultValue={date1Format} onChange={this.handleChange} required />
                    </div>

                    {date2Field}

                    <div className='mass-submit-buttons'>
                        <button type='submit' className='export' onClick={() => (this.state.activationButtonClicked = "export")}>Export</button>
                        <button type='submit' className='delete' onClick={() => (this.state.activationButtonClicked = "delete")}>Delete</button>
                    </div>
                </form>
            </div>  
        );
    }

    async getComponentData() {
        let formId = this.props.formId;
        const formResponse = await fetch('AdminForms/GetFormSubmissionMassRangeData?formId=' + formId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const formData = await formResponse.json();

        this.setState({
            formSubmissionMassRangeData: formData,
            activationButtonClicked: null
        });
    }
}

export default FormSubmissionsMassRange;
