import React, { Component } from 'react';
import SideNavForm from './SideNavForm';

export class CreateSideNav extends Component {
    static displayName = CreateSideNav.name;

    render() {
        return (
            <SideNavForm componentId='0' formType='Create' />
        );
    }
}
