import React, { Component } from 'react';
import './eventmanagement.css';
import { MDBDataTable } from 'mdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCheck, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';

export class EventManagement extends Component {
    static displayName = EventManagement.name;

    constructor(props) {
        super(props);
        this.state = {
            events: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    formatDateTime(dateTime, includeTime) {
        let timestamp = new Date(dateTime.toString());

        let year = timestamp.getFullYear();
        let month = timestamp.getMonth().toString().length === 1 ? '0' + (timestamp.getMonth() + 1).toString() : timestamp.getMonth() + 1;
        let date = timestamp.getDate().toString().length === 1 ? '0' + (timestamp.getDate()).toString() : timestamp.getDate();
        let hours = timestamp.getHours().toString().length === 1 ? '0' + timestamp.getHours().toString() : timestamp.getHours();
        let midday = hours >= 12 ? "PM" : "AM";
        if (hours == 0) {
            hours = 12;
        }
        if (hours >= 13) {
            hours = hours - 12;
            if (hours.toString().length === 1) hours = '0' + hours;
        }
        let minutes = timestamp.getMinutes().toString().length === 1 ? '0' + timestamp.getMinutes().toString() : timestamp.getMinutes();


        let timestampFormat = includeTime ? 
            (month + "/" + date + "/" + year + " " + hours + ":" + minutes + " " + midday) :
            (month + "/" + date + "/" + year);

        return timestampFormat;
    }

    render() {
        let events = this.state.events;

        let tableRows = [];
        if (events.length > 0) {
            for (var i = 0; i < events.length; i++) {
                let event = events[i];

                let startTimeFormat = this.formatDateTime(event.startTime, true);
                let timestampFormat = this.formatDateTime(event.timestamp, false);

                let eventUrl = '/event/' + event.friendlyUrl;

                let row = {
                    editEvent: <a href={'UpdateEvent/' + event.id} className='update-btn' title='Edit'><FontAwesomeIcon icon={faEdit} /></a>,
                    title: event.title,
                    url: eventUrl,
                    types: event.types,
                    startTime: startTimeFormat,
                    //uploader: event.uploader,
                    //timestamp: timestampFormat
                };
                tableRows.push(row);
            }
        }

        let table = {
            columns: [
                { label: '', field: 'editEvent' },
                { label: 'Title', field: 'title', sort: 'asc' },
                { label: 'Url', field: 'url', sort: 'asc' },
                { label: 'Types', field: 'types', sort: 'asc' },
                { label: 'Start Time', field: 'startTime', sort: 'asc' },
                //{ label: 'Uploader', field: 'uploader', sort: 'asc' },
                //{ label: 'Timestamp', field: 'timestamp', sort: 'asc' }
            ],
            rows: tableRows
        };

        return (
            <div className='event-management'>
                <div className='page-title'>
                    <h1>Event Management</h1>
                </div>

                <div className='table-container'>
                    <a href='/CreateEvent' title='Create a new event'><button className='form-management-button'>Create Event</button></a>
                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('AdminEvent/GetEvents', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            events: data
        });
    }
}