import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Services from '../Services';

export class BlogTable extends Component {
    static displayName = BlogTable.name;

    constructor(props) {
        super(props);
        this.state = {
            componentId: props.componentId,
            blogTable: null,
            blogs: [],
            filteredBlogs: [],
            blogConfiguration: null,
            currentFilters: [],
            searchFilters: [],
            paginationBlogIndex: 0,
            cardsPerPagination: 6
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    formatAuthor(blog) {
        let author = blog.uploaderFirstName + " " + blog.uploaderLastName;
        return author;
    }

    sendToBlogPage(blogLink) {
        return window.location = blogLink;
    }

    filterBlogs(newFilter, action) {
        let currentFilters = this.state.currentFilters;
        let searchFilters = this.state.searchFilters;

        //Handle the addition and removal of filters
        if (action == "Add") {
            if (newFilter != null && !currentFilters.includes(newFilter)) {
                currentFilters.push(newFilter);
            }
        }
        else if (action == "Remove") {
            var index = currentFilters.indexOf(newFilter);
            if (index !== -1) {
                currentFilters.splice(index, 1);
            }
        }
        else if (action == "Search") {
            //Remove all filters from previous search
            if (searchFilters.length > 0) {
                for (var i = 0; i < searchFilters.length; i++) {
                    let searchFilter = searchFilters[i];

                    var currentFilterIndex = currentFilters.indexOf(searchFilter);
                    if (currentFilterIndex !== -1) {
                        currentFilters.splice(currentFilterIndex, 1);
                    }
                }
            }

            //Add new filters from search
            if (newFilter != null || newFilter != "" || newFilter != undefined) {
                let terms = newFilter.split(' ');
                for (var i = 0; i < terms.length; i++) {
                    let term = terms[i];
                    if (!currentFilters.includes(term) && term != null && term != "" && term != undefined) {
                        currentFilters.push(term);
                    }
                }
                searchFilters = terms;
            }
        }

        //Handle object filtering 
        if (currentFilters.length > 0) {
            let filteredBlogs = this.state.blogs;
            let newFilteredBlogs = [];
            for (var i = 0; i < filteredBlogs.length; i++) {
                let blog = filteredBlogs[i];

                let title = blog.title.toLowerCase();
                let teaser = blog.teaser.toLowerCase();
                let author = this.formatAuthor(blog).toLowerCase();
                let timestamp = Services.formatDateTime(blog.timestamp, false, true, false, true).toLowerCase();
                let monthYearTimestamp = this.getMonthYearTagFormat(blog.timestamp).toLowerCase();
                let tags = this.getDistinctBlogTags(blog);
                let lowerTags = [];
                for (var j = 0; j < tags.length; j++) {
                    let tag = tags[j];
                    lowerTags.push(tag.toLowerCase());
                }

                for (var j = 0; j < currentFilters.length; j++) {
                    let filter = currentFilters[j].toLowerCase();

                    //console.log(filter);

                    // Blog must contain all tags
                    if (title.includes(filter) ||
                        teaser.includes(filter) ||
                        author.includes(filter) ||
                        timestamp.includes(filter) ||
                        monthYearTimestamp.includes(filter) ||
                        lowerTags.includes(filter)) {

                        if ((j + 1) == currentFilters.length) {
                            newFilteredBlogs.push(blog);
                            break;
                        }
                    }
                    else {
                        break;
                    }
                }
            }

            this.setState({
                currentFilters: currentFilters,
                searchFilters: searchFilters,
                filteredBlogs: newFilteredBlogs,
                paginationBlogIndex: 0
            });
        }
        else {
            this.setState({
                filteredBlogs: this.state.blogs,
                paginationBlogIndex: 0
            });
        }
    }

    searchBlogs = (e) => {
        let searchText = e.target.value;

        this.filterBlogs(searchText, "Search");
    }

    getDistinctBlogTags(blog) {
        let blogCategories = blog.categories != null && blog.categories != "" && blog.categories != undefined ? blog.categories.split(',') : [];
        let blogTypes = blog.types != null && blog.types != "" && blog.types != undefined ? blog.types.split(',') : [];
        let tags = blogCategories;
        blogTypes.forEach(tag => { if (!tags.includes(tag)) { tags.push(tag) } });

        return tags;
    }

    sortTagsCounts(tagA, tagB) {
        const countA = tagA.value;
        const countB = tagB.value;

        let comparison = 0;
        if (countA > countB) {
            comparison = -1;
        }
        else if (countA < countB) {
            comparison = 1;
        }
        return comparison;
    }

    getMonthYearTagFormat(dateTime) {
        let timestamp = new Date(dateTime.toString());

        let year = timestamp.getFullYear();
        let month = timestamp.getMonth().toString().length === 1 ? '0' + (timestamp.getMonth() + 1).toString() : timestamp.getMonth() + 1;

        var abvMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        let tagFormat = abvMonths[month - 1] + " " + year;
        return tagFormat;
    }

    handlePagination = (newIndex) => {
        this.setState({ paginationBlogIndex: newIndex });
    }

    handleArchiveDisplay = (action) => {
        let archiveTags = document.getElementsByClassName("hidden-archive-tag");
        if (archiveTags != undefined) {
            for (var i = 0; i < archiveTags.length; i++) {
                let archiveTag = archiveTags[i];
                archiveTag.style.display = action == "show" ? "block" : "none";
            }

            let showArchivesBtn = document.getElementById("showArchivesBtn");
            let hideArchivesBtn = document.getElementById("hideArchivesBtn");
            if (action == "show") {
                showArchivesBtn.style.display = "none";
                hideArchivesBtn.style.display = "block";
            }
            else {//hide
                showArchivesBtn.style.display = "block";
                hideArchivesBtn.style.display = "none";
            }
        }
    }

    render() {
        let blogTable = this.state.blogTable;
        let blogs = this.state.filteredBlogs;

        let featuredBlog;

        //Set up current filters
        let currentFilterSet = [];
        let currentFilterDetails;
        for (var i = 0; i < this.state.currentFilters.length; i++) {
            let currentFilter = this.state.currentFilters[i];
            let filterKey = "currentFilter" + i;
            let functionalTag = <span className='blog-tag' key={filterKey} onClick={this.filterBlogs.bind(this, currentFilter, "Remove")}>{currentFilter}</span>;
            currentFilterSet.push(functionalTag);
            //if (i + 1 < this.state.currentFilters.length) {
            //    currentFilterSet.push(", ");
            //}
        }
        //Add current filter details
        let currentFilterInfo = <FontAwesomeIcon icon={faInfoCircle} className='info-icon filter-opt-box-title' title='Click tags to remove them' />;
        let currentFilterTitle = <span className='filter-opt-box-title'> Current Filters: </span>;
        currentFilterDetails = <>
            {/*{currentFilterInfo}*/}
            {currentFilterTitle}
        </>;   

        let currentFiltersBox = <div className='current-filters'>
            {currentFilterDetails}
            {currentFilterSet}
        </div>

        let allRawTags = [];
        let filterOpts = [];
        let blogCards = [];
        let monthYearTags = [];
        let popularPosts = [];
        if (blogs != null && blogs.length > 0 && blogTable != null) {
            for (var i = 0; i < blogs.length; i++) {
                let blog = blogs[i];

                // Only display live blogs
                if (blog.status == "Live") {
                    //Handle raw tag data
                    let blogCategories = blog.categories != null && blog.categories != "" && blog.categories != undefined ? blog.categories.split(',') : [];
                    let blogTypes = blog.types != null && blog.types != "" && blog.types != undefined ? blog.types.split(',') : [];
                    blogCategories.forEach(tag => { allRawTags.push(tag) });
                    blogTypes.forEach(tag => { allRawTags.push(tag) });

                    // Handle tags
                    let tags = this.getDistinctBlogTags(blog);
                    tags.forEach(tag => { if (!filterOpts.includes(tag)) { filterOpts.push(tag) } });
                    let tagSet = [];
                    for (var j = 0; j < tags.length; j++) {
                        let tag = tags[j];
                        let tagKey = "tag" + j;
                        let functionalTag = <span className='blog-tag' key={tagKey} onClick={this.filterBlogs.bind(this, tag, "Add")}>{tag}</span>;
                        tagSet.push(functionalTag);
                        if (j + 1 < tags.length) {
                            tagSet.push(", ");
                        }
                    }

                    // Handle MonthYear tags
                    let monthYearTag = this.getMonthYearTagFormat(blog.timestamp);
                    if (!monthYearTags.includes(monthYearTag)) {
                        monthYearTags.push(monthYearTag);
                    }
                        
                    let thumbnailImageSrc = ((blog.thumbnailImage == null || blog.thumbnailImage == "") && this.state.blogConfiguration != null) ? this.state.blogConfiguration.defaultImage : blog.thumbnailImage;
                    let singleBlogUrl = '/blog/' + blog.friendlyUrl;
                    let blogAuthor = this.formatAuthor(blog);
                    let timestampFormat = Services.formatDateTime(blog.timestamp, false, true, false, true);

                    let blogRowKey = "blogRow" + blog.id;
                        
                    // Create all small blogs
                    let blogCard = <div className='blog-card-container' key={blogRowKey}>
                        <div className='blog-image'>
                            <a href={singleBlogUrl} ><img alt="blog thumbnail" src={thumbnailImageSrc} /></a>
                        </div>

                        <div className='blog-data-container'>
                            <div className='blog-title'>
                                <h3><a href={singleBlogUrl} >{blog.title}</a></h3>
                            </div>
                            <div className='blog-upload'>
                                <span className='blog-tag' onClick={this.filterBlogs.bind(this, timestampFormat, "Add")}>{timestampFormat}</span> | { tagSet }
                            </div>

                            <span className='blog-teaser'>
                                <p>{blog.teaser}</p>
                            </span>
                        </div>
                    </div>;
                    blogCards.push(blogCard);

                    // Create featured blog
                    if ((blog.id == blogTable.featuredBlogId) || (featuredBlog == undefined && blogTable.featuredBlogId == 0)) {
                        featuredBlog = <div className='featured-blog-container'>
                            <div className='featured-blog-title'>
                                <h3><a href={singleBlogUrl} >{blog.title}</a></h3>
                            </div>

                            <div className='featured-timestamp'>
                                <span className='blog-tag' onClick={this.filterBlogs.bind(this, timestampFormat, "Add")}>{timestampFormat}</span> | {tagSet}
                            </div>

                            <div className='featured-image'>
                                <a href={singleBlogUrl} ><img alt="featured blog thumbnail" src={thumbnailImageSrc} /></a>
                            </div>

                            <span className='featured-blog-teaser'>
                                <p>{blog.teaser}</p>
                                <span className='continue-tag'><a href={singleBlogUrl} >[Continue Reading...]</a></span>
                            </span>
                        </div>;
                    }

                    // Create Popular Posts (Editor's Picks)
                    if (blogTable.editorPickBlogId.includes(blog.id)) {
                        let popularPost = <div className='popular-post-container'>
                            <div className='popular-post-image'>
                                <a href={singleBlogUrl} ><img alt="popular blog thumbnail" src={thumbnailImageSrc} /></a>
                            </div>

                            <div className='popular-post-content'>
                                <div className='popular-post-title'>
                                    <h3><a href={singleBlogUrl} >{blog.title}</a></h3>
                                </div>

                                <div className='popular-post-tags'>
                                    <span className='blog-tag' onClick={this.filterBlogs.bind(this, timestampFormat, "Add")}>{timestampFormat}</span> | {tagSet}
                                </div>
                            </div>
                        </div>;
                        popularPosts.push(popularPost);
                    }
                }
            }
        }

        // Create popular post box
        let popularPostBox = <div className='popular-post-box'>
            <div className='popular-post-header'>Editor's Picks</div>
            {popularPosts}
        </div>;

        let filterOptsBox;
        if (allRawTags.length > 0) {
            //get tag counts
            let tagsAndCounts = [];
            for (var i = 0; i < allRawTags.length; i++) {
                let tag = allRawTags[i];
                let alreadyExists = false;
                for (var j = 0; j < tagsAndCounts.length; j++) {
                    let tagAndCount = tagsAndCounts[j];
                    if (tagAndCount.key == tag) {
                        tagAndCount.value++;
                        alreadyExists = true;
                        break;
                    }
                }

                if (!alreadyExists) {
                    tagsAndCounts.push({ key: tag, value: 1 });
                }
            }

            //order tags by count highest to lowest
            let orderedTagsAndCounts = tagsAndCounts.sort(this.sortTagsCounts);

            let filterOptsBoxTitle = <span className='filter-opt-box-title'>Tags: </span>;

            let tagBoxOpts = [];
            for (var i = 0; i < orderedTagsAndCounts.length; i++) {
                let currentFilter = orderedTagsAndCounts[i];
                let filterKey = "sideFilterOpt" + i;
                let functionalTag = <span className='blog-tag' key={filterKey} onClick={this.filterBlogs.bind(this, currentFilter.key, "Add")}>{currentFilter.key} ({currentFilter.value})</span>;
                tagBoxOpts.push(functionalTag);
            }

            filterOptsBox = <div className='filter-options'>
                {filterOptsBoxTitle}
                {tagBoxOpts}
            </div>;
        }

        // Handle archives tag box
        let archiveTags = [];
        let maxArchiveShown = 6;
        for (var i = 0; i < monthYearTags.length; i++) {
            let archiveKey = "archive" + i;
            let monthYearTag = monthYearTags[i];

            if (i <= (maxArchiveShown - 1)) {
                archiveTags.push(<span className='blog-tag archive-tag' key={archiveKey} onClick={this.filterBlogs.bind(this, monthYearTag, "Add")}>{monthYearTag}</span>);
            }
            else {
                archiveTags.push(<span style={{ display: 'none'}} className='blog-tag archive-tag hidden-archive-tag' key={archiveKey} onClick={this.filterBlogs.bind(this, monthYearTag, "Add")}>{monthYearTag}</span>);
            }
        }
        let displayArchiveBtns;
        if (archiveTags.length > maxArchiveShown) {
            displayArchiveBtns = <>
                <div id='showArchivesBtn' className='show-archives-btn' onClick={this.handleArchiveDisplay.bind(this, "show")}>More...</div>
                <div id='hideArchivesBtn' className='hide-archives-btn' onClick={this.handleArchiveDisplay.bind(this, "hide")} style={{ display: 'none' }}>Less...</div>
            </>;
        }
        let archivesFilterBox = <div className='filter-archive-box'>
            <div className='archive-box-title'>Archives</div>
            {archiveTags}
            {displayArchiveBtns}
        </div>;

        // Handle search bar
        let searchBar = <div style={{ paddingBottom: '10px' }}>
            <input type='text' placeholder='Search' onKeyUp={this.searchBlogs.bind(this)} />
        </div>

        let customTable;
        let paginatedButtons;
        if (blogTable != null) {
            let minPaginatedIndex = this.state.paginationBlogIndex;
            let maxPaginatedIndex = minPaginatedIndex + (this.state.cardsPerPagination - 1);

            //console.log(minPaginatedIndex, maxPaginatedIndex);

            // Get current blogs in pagination
            let paginatedBlogs = [];
            for (var i = 0; i < blogCards.length; i++) {
                let blogCard = blogCards[i];
                if (i >= minPaginatedIndex && i <= maxPaginatedIndex) {
                    paginatedBlogs.push(blogCard);
                }
            }
            customTable = <div className='blog-table-grid'>{paginatedBlogs}</div>;

            // Get pagination buttons
            let buttons = [];
            if (minPaginatedIndex != 0) {
                let prevText = "< Prev";
                let newIndex = minPaginatedIndex - this.state.cardsPerPagination;
                buttons.push(<span key="prevPage" onClick={this.handlePagination.bind(this, newIndex)}>{prevText}</span>);
            }

            if (blogCards.length > 0) {
                let pageCount = blogCards.length / this.state.cardsPerPagination;
                let pageCountBuffer = 5;

                // Get current page index
                let currentPageIndex = 0;
                for (var i = 0; i < pageCount; i++) {
                    if (minPaginatedIndex == (((i + 1) * this.state.cardsPerPagination) - this.state.cardsPerPagination)) {
                        currentPageIndex = i;
                    }
                }

                // Get page data
                for (var i = 0; i < pageCount; i++) {
                    if (i >= (currentPageIndex - pageCountBuffer) && i <= (currentPageIndex + pageCountBuffer)) {
                        let pageText = i + 1;
                        let pageKey = "Page" + pageText;
                        let newIndex = i * this.state.cardsPerPagination;

                        let currentPageClass = "";
                        if (i == currentPageIndex) {
                            currentPageClass = "current-page";
                        }

                        buttons.push(<span key={pageKey} className={currentPageClass} onClick={this.handlePagination.bind(this, newIndex)}>{pageText}</span>);
                    }
                }
            }

            if ((maxPaginatedIndex < blogCards.length)) {
                let nextText = "Next >";
                let newIndex = maxPaginatedIndex + 1;
                buttons.push(<span key="nextPage" onClick={this.handlePagination.bind(this, newIndex)}>{nextText}</span>);
            }

            paginatedButtons = <div className='paginated-button-row'>{buttons}</div>;
        }

        let containerClasses = "blog-table-container blog-table-container__full";
        return (
            <div className={containerClasses}>
                <div className='table-container'>
                    <div className='blog-filter-grid'>
                        <div className='blog-main-container'>
                            {featuredBlog}

                            {customTable}

                            {paginatedButtons}
                        </div>

                        <div className='blog-filter-container'>
                            {searchBar}

                            {popularPostBox}

                            {filterOptsBox}

                            {currentFiltersBox}

                            {archivesFilterBox}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async getComponentData() {
        const response = await fetch('components/GetBlogTable?componentId=' + this.state.componentId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

        const data = await response.json();

        this.setState({
            blogTable: data.blogTable,
            blogs: data.blogs,
            filteredBlogs: data.blogs,
            blogConfiguration: data.blogConfiguration
        });
    }
}
